import { createSlice } from '@reduxjs/toolkit'
import { UserItem } from '../../models/user.model'
import { DboardApi } from '../../services'

export const UserEmptyState : UserItem = {
  id: 0,
  email: '',
  name: '',
  id_company_area: 0,
  id_role: 0,
  active: 0,
  created_at: new Date(),
  updated_at: new Date(),
  permisos: []
}

export const userSlice = createSlice({
  name: 'user',
  initialState: UserEmptyState,
  reducers: {
    createUser: (state, action) => {
      return action.payload
    },
    modifyUser: (state, action) => {
      return { ...state, ...action.payload }
    },
    resetUser: () => {
      return UserEmptyState
    }
  }
})
// Actions
export const { createUser, modifyUser, resetUser } = userSlice.actions

export const getProfile = (_email: string, _poolId : string) => async (dispatch : any) => {
  await DboardApi.getUserProfile(_email, _poolId).then((response) => {
    dispatch(createUser(response.data))
    // setData({ ...data, loading: false })
  }).catch((error) => {
    console.log('getProfile error:', error)
    // setData({ ...data, loading: false })
  })
}
