import AverageQuarter from './components/AverageQuarter'
import Sales from './components/Sales'
import Units from './components/Units'

function QuarterCharts () {
  return (
    <>
      <Sales />
      <Units />
      <AverageQuarter />
    </>
  )
}

export default QuarterCharts
