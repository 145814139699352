
import { DBOARD_FORMATSMALL } from '@constants/dashboard.constants'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { DboardApi } from '@services/index'
import { CurrencyFormat } from '@utils/Currency.utility'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

interface IOwnProps {
    open: boolean
    onClose: () => void
    init: string
    end: string
}

const Transition = React.forwardRef(function Transition (
  props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

const PartiallyPaidModal: React.FC<IOwnProps> = ({ open, onClose, init, end }) => {
  const [data, setData] = useState<{ loading: boolean, listPF: any[] }>({ loading: false, listPF: [] })

  const loadData = () => {
    setData({ ...data, loading: true })
    DboardApi.getPartiallyRefunded(init, end).then((response) => {
      setData({ loading: false, listPF: response.data })
    }).catch((error) => {
      console.log('TotalVentas loadData error:', error)
      setData({ ...data, loading: false })
    })
  }

  useEffect(() => {
    if (open) {
      loadData()
    }

    return () => setData({ loading: false, listPF: [] })
  }, [open])

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle>Detalle devoluciones parciales</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-slide-description'>

          <TableContainer
            sx={{
              width: '100%',
              overflowX: 'auto',
              position: 'relative',
              display: 'block',
              maxWidth: '100%',
              '& td, & th': { whiteSpace: 'nowrap' }
            }}
          >
            <Table size='small' aria-labelledby='tableTitle' border={1}>
              <TableHead>
                <TableRow sx={{ background: 'linear-gradient(to left top, #4676b9, #7c3c91)' }}>
                  <TableCell
                    style={{
                      color: '#ffff',
                      textAlign: 'left'
                    }}
                  >
                    Orden
                  </TableCell>
                  <TableCell
                    style={{
                      color: '#ffff',
                      textAlign: 'right'
                    }}
                  >
                    Monto
                  </TableCell>
                  <TableCell
                    style={{
                      color: '#ffff',
                      textAlign: 'center'
                    }}
                  >
                    Nota
                  </TableCell>
                  <TableCell
                    style={{
                      color: '#ffff',
                      textAlign: 'center'
                    }}
                  >
                    Fecha
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.loading
                  ? (<TableRow><TableCell style={{ textAlign: 'center' }} colSpan={4}><strong>Cargando...</strong></TableCell></TableRow>)
                  : data.listPF.map((e: any, idx: number) => {
                    return (
                      <TableRow key={idx}>
                        <TableCell style={{ textAlign: 'left' }}>
                          {e.id_order_platform}
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                          {CurrencyFormat(e.amount_reversso)}
                        </TableCell>
                        <TableCell style={{ textAlign: 'left' }}>
                          {e.refunded_note}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          {moment(e.created_date).format(DBOARD_FORMATSMALL)}
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContentText>

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default PartiallyPaidModal
