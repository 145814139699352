import moment from 'moment'
import { createContext, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DboardApi } from '../../../services'
import { AppStore } from '../../../store/store'
import 'moment/locale/es' // without this line it didn't work
import { useSnackbar } from 'notistack'
import { VISTA_SELLERS } from '@constants/permission.constants'
moment.locale('cl')
export const DashboardContext = createContext<any | null>(null)

export const DashboardProvider = ({ children } : any) => {
  const userState = useSelector((appStore: AppStore) => appStore.user)
  const { ctx, setCtx } = useProviderStore()
  const { enqueueSnackbar } = useSnackbar()
  console.log("ctx => ", ctx);
  

  const getSellers = () => {
    if (!userState.permisos.some((el:any) => el.function === VISTA_SELLERS)) {
      enqueueSnackbar('No tiene permiso VISTA_SELLERS', { variant: 'error' })
      return
    }
    DboardApi.getSellers(0).then((response) => {
      if (response.items.length > 0) {
        // validacion sellers visibles
        const permiso = userState.permisos.find((p:any) => p.function === VISTA_SELLERS)
        if (permiso !== undefined && permiso.value.toUpperCase() === 'ALL') {
          const ListSeller : any[] = [{ idSeller: 0, nameSeller: 'Todos', idEnviame: 0, idPlan: 0, status: 0 }, ...response.items]
          setCtx({ ...ctx, idSeller: ListSeller[0].idSeller, dataListSeller: ListSeller })
        } else {
          const permisos = (permiso) ? permiso.value.split(',').map((entry: any) => parseInt(entry)) : []
          const ListSeller = response.items.filter((item: any) => permisos.indexOf(item.idSeller) >= 0)
          setCtx({ ...ctx, idSeller: ListSeller[0].idSeller, dataListSeller: ListSeller })
        }
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {
    getSellers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (ctx.idSeller === -1) return <>Cargando...</>

  return <DashboardContext.Provider value={{ ctx, setCtx }}>{children}</DashboardContext.Provider>
}

const useProviderStore = () => {
  const [ctx, setCtx] = useState<any>({ dateStart: new Date().toISOString().split('T')[0] + ' 00:00:00', dateEnd: new Date().toISOString().split('T')[0] + ' 23:59:59', idSeller: -1, dataListSeller: [] })

  return {
    ctx,
    setCtx
  }
}

export const useDashboardContext = () => {
  const context = useContext(DashboardContext)
  if (context === undefined) {
    throw new Error('DashboardContext must be used within a DashboardProvider')
  }
  return context
}
