import { Alert, Card, CardContent, CardHeader, Switch, Typography } from '@mui/material'
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Rectangle,
  ComposedChart,
  ReferenceLine,
  Label,
  LabelList
} from 'recharts'
import { DashboardBsaleContextType, useDashboardBsaleContext } from '../../../context/dashboardBsale.context'
import { CurrencyFormat } from '@utils/Currency.utility'
import { useState } from 'react'
import { average, formatNumber } from '@utils/dashboardBsale'

function TotalOfReturns () {
  const { ctx } = useDashboardBsaleContext() as DashboardBsaleContextType
  const [hideREUSE, setHideREUSE] = useState(false)

  function getMaxSales () {
    const data = hideREUSE
      ? ctx.typeOfReturns.filter(item => !(ctx.excludeNC.includes(item.id)))
      : ctx.typeOfReturns
    return Math.max(...data.map(item => parseInt(item.total)))
  }

  function handleChange () {
    setHideREUSE(!hideREUSE)
  }

  const filteredData = hideREUSE
    ? ctx.typeOfReturns.filter(item => !(ctx.excludeNC.includes(item.id)))
    : ctx.typeOfReturns

  const salesAverage = average(filteredData, 'total')

  return (
    <Card sx={ctx.typeOfReturns.length > 0 ? { height: 400 } : { minHeight: 50 }}>
      <CardHeader title={
        <Typography variant='h6'>Total de devolución
          <Switch checked={hideREUSE} onChange={handleChange} disabled={ctx.typeOfReturns.length === 0} />
        </Typography>
}
      />
      {ctx.typeOfReturns.length > 0
        ? (
          <ResponsiveContainer width='100%' height='80%'>
            <ComposedChart
              width={500}
              height={400}
              data={filteredData}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 50
              }}
            >
              <CartesianGrid stroke='#f5f5f5' />
              <XAxis dataKey='name' tick={false} />
              <YAxis
                domain={[0, getMaxSales()]}
                tickFormatter={formatNumber}
              />
              <Tooltip
                formatter={(value: any, name: any, props: any) => CurrencyFormat(value)}
              />
              <Legend />
              {salesAverage !== null && (
                <ReferenceLine y={salesAverage} stroke='#cccccc' strokeDasharray='3 3'>
                  <Label value={`Avg: ${formatNumber(salesAverage)}`} position='insideLeft' />
                </ReferenceLine>
              )}
              <Bar dataKey='total' name='Total' fill='#413ea0' activeBar={<Rectangle fill='#7b76f3' stroke='blue' />} barSize={30} legendType='circle'>
                <LabelList dataKey='total' position='top' formatter={(value: any) => formatNumber(value)} fontSize={12} />
              </Bar>
            </ComposedChart>
          </ResponsiveContainer>
          )
        : (
          <CardContent sx={{ paddingTop: 0 }}>
            <Alert severity='info'>No hay devoluciones en la fecha solicitada</Alert>
          </CardContent>
          )}
    </Card>
  )
}

export default TotalOfReturns
