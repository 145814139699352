import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip, TooltipProps, Typography, tooltipClasses } from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import { CurrencyFormat } from '@utils/Currency.utility'
import { useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { formatMonth } from '@utils/dashboardBsale'
import { GoalsTableProps } from '@models/goalsBsale'
import NorthIcon from '@mui/icons-material/North'
import SouthIcon from '@mui/icons-material/South'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#1976D2',
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[10],
    fontSize: 15
  }
}))

function SalesTable ({ state, setState }: GoalsTableProps) {
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleAccordionChange = (event: any, isExpanded: any) => {
    // Only expand/collapse when the ArrowDropDownIcon is clicked
    if (event.target.closest('#panel2-header .MuiSvgIcon-root')) {
      setExpanded(isExpanded ? 'panel2' : false)
    }
  }

  return (
    <>
      <Accordion expanded={expanded === 'panel2'} onChange={handleAccordionChange} sx={{ mb: 3 }}>
        <AccordionSummary
          expandIcon={
            <IconButton>
              <ArrowDropDownIcon />
            </IconButton>
          }
          aria-controls='panel2-content'
          id='panel2-header'
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {/* <Box sx={{ display: 'flex' }}>
              <Typography sx={{ backgroundColor: '#1976D2', color: 'white', padding: 1, borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}>Meta Anual:</Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '8px',
                  border: '1px solid',
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                  borderTopRightRadius: '4px',
                  borderBottomRightRadius: '4px',
                  textAlign: 'center',
                  backgroundColor: '#ffffff',
                  '&:hover': {
                    backgroundColor: '#fafafa'
                  },
                  width: '200px',
                  height: '40px'
                }}
              >
                <Typography sx={{ flex: 1, textAlign: 'start', paddingLeft: '6px' }}>
                  {CurrencyFormat(state.yearGoal)}
                </Typography>
              </Box>
            </Box> */}

            <Box sx={{ display: 'flex' }}>
              <Typography sx={{ backgroundColor: '#1976D2', color: 'white', padding: 1, borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}>Meta Estimada Anual:</Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '8px',
                  border: '1px solid',
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                  borderTopRightRadius: '4px',
                  borderBottomRightRadius: '4px',
                  textAlign: 'center',
                  backgroundColor: '#ffffff',
                  '&:hover': {
                    backgroundColor: '#fafafa'
                  },
                  width: '200px',
                  height: '40px',
                  color: state.estimatedYear && state.estimatedYear?.sales > state.estimatedYear?.goal ? 'green' : '#b40707'
                }}
              >
                <LightTooltip
                  title={
                    <>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <Typography>
                          Meta: {state.estimatedYear?.goal && CurrencyFormat(state.estimatedYear?.goal)}
                        </Typography>

                        <Typography sx={{ display: 'flex', alignItems: 'center', color: state.estimatedYear?.sales && state.estimatedYear?.sales > state.estimatedYear?.goal ? 'green' : '#b40707' }}>
                          {state.estimatedYear?.sales && state.estimatedYear?.sales > state.estimatedYear?.goal ? <NorthIcon sx={{ width: 20 }} /> : <SouthIcon sx={{ width: 20 }} />}
                          {state.estimatedYear?.percentage}%
                        </Typography>
                      </Box>
                    </>
                        }
                  placement='top'
                >
                  <Typography sx={{ flex: 1, textAlign: 'start', paddingLeft: '6px' }}>
                    {state.estimatedYear?.sales && CurrencyFormat(state.estimatedYear?.sales)}
                  </Typography>
                </LightTooltip>
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingBottom: 1 }}>
          <Divider />
          <Grid container columnSpacing={4} rowSpacing={2} sx={{ py: 3 }}>
            {state.monthsGoal.map((month, i) => (
              <Grid item xs={12} md={4} lg={2} key={i} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography sx={{ width: '100%', backgroundColor: '#1976D2', color: 'white', padding: 0.5, textAlign: 'center', borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>{formatMonth(month.month)}</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '8px',
                    border: '1px solid',
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                    borderRadius: '0 0 4px 4px',
                    textAlign: 'center',
                    backgroundColor: '#ffffff',
                    '&:hover': {
                      backgroundColor: '#fafafa'
                    },
                    width: '100%',
                    height: '42px',
                    color: month.goal ? (month.sales && month.sales > month.goal ? 'green' : '#b40707') : 'inherit'
                  }}
                >
                  {month.goal
                    ? (
                      <LightTooltip
                        title={
                          <>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                              <Typography>
                                Meta: {CurrencyFormat(month.goal)}
                              </Typography>

                              <Typography sx={{ display: 'flex', alignItems: 'center', color: month.sales && month.sales > month.goal ? 'green' : '#b40707' }}>
                                {month.sales && month.sales > month.goal ? <NorthIcon sx={{ width: 20 }} /> : <SouthIcon sx={{ width: 20 }} />}
                                {month.percentage}%
                              </Typography>
                            </Box>
                          </>
                          }
                        placement='top'
                      >
                        <Typography sx={{ flex: 1, textAlign: 'center' }}>
                          {month.sales && CurrencyFormat(month.sales)}
                        </Typography>
                      </LightTooltip>
                      )
                    : (
                      <Typography sx={{ flex: 1, textAlign: 'center' }}>
                        {month.sales && CurrencyFormat(month.sales)}
                      </Typography>
                      )}
                  {/* {month.goal !== 0 && month.sales !== undefined && (
                    <LightTooltip title={`Meta: ${CurrencyFormat(month.goal)}`} placement='top'>
                      <Typography sx={{ display: 'flex', alignItems: 'center', color: month.sales > month.goal ? 'green' : '#b40707' }}>
                        {month.sales > month.goal ? <NorthIcon sx={{ width: 20 }} /> : <SouthIcon sx={{ width: 20 }} />}
                        {month.percentage}%
                      </Typography>
                    </LightTooltip>
                  )} */}
                </Box>
              </Grid>

            ))}

          </Grid>
        </AccordionDetails>
      </Accordion>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label='customized table' id='idTableTradeinSales'>
          <TableHead>
            <TableRow>
              <StyledTableCell align='center' sx={{ width: 75 }}>Semana</StyledTableCell>
              <StyledTableCell align='center'>Lunes</StyledTableCell>
              <StyledTableCell align='center'>Martes</StyledTableCell>
              <StyledTableCell align='center'>Miércoles</StyledTableCell>
              <StyledTableCell align='center'>Jueves</StyledTableCell>
              <StyledTableCell align='center'>Viernes</StyledTableCell>
              <StyledTableCell align='center'>Sábado</StyledTableCell>
              <StyledTableCell align='center'>Domingo</StyledTableCell>
              <StyledTableCell align='center' sx={{ width: 250 }}>Venta semanal</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.weeks.map((week, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell align='center'>{week.week}</StyledTableCell>
                {week.days.map((day, j) => (
                  <StyledTableCell align='center' key={j}>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '8px',
                      textAlign: 'center',
                      color: day.sales && day.sales > day.goal ? 'green' : '#b40707',
                      gap: 1
                    }}
                    >
                      <LightTooltip
                        title={
                          <>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                              <Typography>
                                Meta: {CurrencyFormat(day.goal)}
                              </Typography>

                              <Typography sx={{ display: 'flex', alignItems: 'center', color: day.sales && day.sales > day.goal ? 'green' : '#b40707' }}>
                                {day.sales && day.sales > day.goal ? <NorthIcon sx={{ width: 20 }} /> : <SouthIcon sx={{ width: 20 }} />}
                                {day.percentage}%
                              </Typography>
                            </Box>
                          </>
                        }
                        placement='top'
                      >
                        <Typography>
                          {day.sales && CurrencyFormat(day.sales)}
                        </Typography>
                      </LightTooltip>
                      {/* {day.newGoal && day.sales
                        ? (
                          <LightTooltip title={`Meta: ${CurrencyFormat(day.newGoal)}`} placement='top'>
                            <Typography sx={{ display: 'flex', alignItems: 'center', color: day.sales > day.newGoal ? 'green' : '#b40707' }}>
                              {day.sales > day.newGoal ? <NorthIcon sx={{ width: 20 }} /> : <SouthIcon sx={{ width: 20 }} />}
                              {day.percentage}%
                            </Typography>
                          </LightTooltip>
                          )
                        : null} */}
                    </Box>
                  </StyledTableCell>
                ))}
                <StyledTableCell align='center'>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '8px',
                      border: '1px solid',
                      borderColor: 'rgba(0, 0, 0, 0.23)',
                      borderRadius: '4px',
                      textAlign: 'center',
                      backgroundColor: '#ffffff',
                      '&:hover': {
                        backgroundColor: '#fafafa'
                      },
                      width: '100%',
                      height: '42px',
                      color: week.weeklyGoal ? (week.sales && week.sales > week.weeklyGoal ? 'green' : '#b40707') : 'inherit'
                    }}
                  >
                    {
                      week.weeklyGoal
                        ? (
                          <LightTooltip
                            title={
                              <>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                  <Typography>
                                    Meta: {CurrencyFormat(week.weeklyGoal)}
                                  </Typography>

                                  <Typography sx={{ display: 'flex', alignItems: 'center', color: week.sales && week.sales > week.weeklyGoal ? 'green' : '#b40707' }}>
                                    {week.sales && week.sales > week.weeklyGoal ? <NorthIcon sx={{ width: 20 }} /> : <SouthIcon sx={{ width: 20 }} />}
                                    {week.percentage}%
                                  </Typography>
                                </Box>
                              </>
                                  }
                            placement='top'
                          >
                            <Typography sx={{ flex: 1, textAlign: 'center' }}>
                              {CurrencyFormat(week.sales || 0)}
                            </Typography>
                          </LightTooltip>
                          )
                        : (
                          <Typography sx={{ flex: 1, textAlign: 'center' }}>
                            {CurrencyFormat(week.sales || 0)}
                          </Typography>
                          )
                    }
                    {/* {week.weeklyGoal && week.sales
                      ? (
                        <LightTooltip title={`Meta: ${CurrencyFormat(week.weeklyGoal)}`} placement='top'>
                          <Typography
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              color: week.sales > week.weeklyGoal ? 'green' : '#b40707'
                            }}
                          >
                            {week.sales > week.weeklyGoal
                              ? (
                                <NorthIcon sx={{ width: 20 }} />
                                )
                              : (
                                <SouthIcon sx={{ width: 20 }} />
                                )}
                            {week.percentage}%

                          </Typography>
                        </LightTooltip>
                        )
                      : null} */}
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default SalesTable
