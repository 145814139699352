import AveragePerWeek from './components/AveragePerWeek'
import SalesPerWeek from './components/SalesPerWeek'
import UnitsPerWeek from './components/UnitsPerWeek'

function SalesPerWeekCharts () {
  return (
    <>
      <SalesPerWeek />
      <UnitsPerWeek />
      <AveragePerWeek />
    </>
  )
}

export default SalesPerWeekCharts
