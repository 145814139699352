import { Box, Card, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Bar, CartesianGrid, ComposedChart, Label, LabelList, Legend, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { DashboardBsaleContextType, useDashboardBsaleContext } from '../../../context/dashboardBsale.context'
import { average, colors } from '@utils/dashboardBsale'
import moment from 'moment'

function UnitsPerWeek () {
  const { ctx } = useDashboardBsaleContext() as DashboardBsaleContextType
  const [hide, setHide] = useState<any>([])
  const years = ['2023', '2024']
  const currentYear = moment(new Date()).format('YYYY')
  const unitsAverage = average(ctx.unitsPerWeek, 'total_2024')

  const maxUnits = () => {
    const isEveryYear = !years?.every(year => hide.includes(`total_${year}`))
    const maxUnits =
    Math.max(...ctx?.unitsPerWeek.map((unit: any) =>
      Math.max(parseFloat(isEveryYear ? unit[`ventas_${Number(currentYear) - 1}`] : unit[`devoluciones_${Number(currentYear) - 1}`]),
        parseFloat(isEveryYear ? unit[`ventas_${currentYear}`] : unit[`devoluciones_${currentYear}`]))
    ))
    return maxUnits
  }

  const handleLenged = (e: any) => {
    if (hide.includes(e.id)) {
      const data = hide.filter((ele: any) => ele !== e.id)
      setHide(data)
    } else {
      setHide([...hide, e.id])
    }
  }

  return (
    <Card sx={{ height: 400, marginTop: 3 }}>
      <Box sx={{ padding: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h6'>Unidades por semana</Typography>
      </Box>
      <ResponsiveContainer width='100%' height='80%'>
        <ComposedChart
          width={500}
          height={400}
          data={ctx.unitsPerWeek}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20
          }}
        >
          <CartesianGrid stroke='#f5f5f5' />
          <XAxis dataKey='week' />
          <YAxis
            domain={[0, maxUnits()]}
          />
          <Tooltip />
          <Legend
            wrapperStyle={{ cursor: 'pointer' }}
            onClick={handleLenged}
            payload={years?.flatMap(year => ([
              { value: `Total ${year}`, type: 'circle', id: `total_${year}`, color: hide.includes(`total_${year}`) ? 'grey' : colors[year] },
              { value: `Devolución ${year}`, type: 'line', id: `devoluciones_${year}`, color: hide.includes(`devoluciones_${year}`) ? 'grey' : colors[year] }
            ]))}
          />
          {unitsAverage !== null && (
            <ReferenceLine y={unitsAverage} stroke='#cccccc' strokeDasharray='3 3'>
              <Label value={`Avg: ${unitsAverage.toFixed(0)}`} position='insideLeft' fontSize={10} />
            </ReferenceLine>
          )}
          {years.map(year => (
            <React.Fragment key={year}>
              <Bar dataKey={`ventas_${year}`} name={`Ventas ${year}`} fill={colors[year]} barSize={-4} legendType='none' display='none' hide={hide.includes(`total_${year}`)} />
              <Line type='monotone' dataKey={`devoluciones_${year}`} name={`Devolución ${year}`} stroke={colors[year]} hide={hide.includes(`devoluciones_${year}`)} />
              <Bar dataKey={`total_${year}`} name={`Total ${year}`} fill={colors[year]} barSize={15} legendType='circle' hide={hide.includes(`total_${year}`)}>
                <LabelList dataKey={`total_${year}`} position='top' fontSize={12} />
              </Bar>
            </React.Fragment>
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </Card>
  )
}

export default UnitsPerWeek
