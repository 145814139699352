import { CurrencyFormat } from './Currency.utility'
import * as XLSX from 'xlsx'
import { state, originalState } from '@models/goalsBsale'

export function month (number: number) {
  let month = ''
  switch (number) {
    case 1:
      month = 'Ene'
      break
    case 2:
      month = 'Feb'
      break
    case 3:
      month = 'Mar'
      break
    case 4:
      month = 'Abr'
      break
    case 5:
      month = 'May'
      break
    case 6:
      month = 'Jun'
      break
    case 7:
      month = 'Jul'
      break
    case 8:
      month = 'Ago'
      break
    case 9:
      month = 'Sep'
      break
    case 10:
      month = 'Oct'
      break
    case 11:
      month = 'Nov'
      break
    case 12:
      month = 'Dic'
      break
  }
  return month
}

export function formatMonth (number: number) {
  let month = ''
  switch (number) {
    case 1:
      month = 'Enero'
      break
    case 2:
      month = 'Febrero'
      break
    case 3:
      month = 'Marzo'
      break
    case 4:
      month = 'Abril'
      break
    case 5:
      month = 'Mayo'
      break
    case 6:
      month = 'Junio'
      break
    case 7:
      month = 'Julio'
      break
    case 8:
      month = 'Agosto'
      break
    case 9:
      month = 'Septiembre'
      break
    case 10:
      month = 'Octubre'
      break
    case 11:
      month = 'Noviembre'
      break
    case 12:
      month = 'Diciembre'
      break
  }
  return month
}

export function average (sales: any, element: string) {
  const total = sales.reduce((acc: number, curr: any) => acc + parseFloat(curr[element]), 0)
  const average = total / sales.filter((item: any) => parseFloat(item[element]) !== 0).length
  return average
}

export function formatNumber (value:any) {
  if (value >= 1000000) {
    return (value / 1000000).toFixed(0) + 'M'
  } else {
    return (value / 1000).toFixed(0) + 'K'
  }
}

// Utilidad para recortar nombres largos
export const recortar = (link: string, longitud: number, acortar = '...') => {
  return link.length > longitud ? link.slice(0, longitud) + acortar : link
}

// Funcion especifica del grafico de torta de la libreria recharts
export const COLORS = ['#0088FE', '#00C49F', '#f8aa00', '#FF8042', '#ff4242', '#27838a', '#707923', '#d42a00', '#413EA0']
export const COLORS2 = ['#41a6ff', '#01e4ba', '#ffbc2d', '#ffa77b', '#ff6969', '#37aab3', '#b1be35', '#ff4314', '#413EA0']

export const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, value, customProp }: any) => {
  if (value === null) return null
  const RADIAN = Math.PI / 180
  const radius = innerRadius + (outerRadius - innerRadius) * 0.7
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill='white'
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline='central'
    >
      {
      customProp === undefined
        ? `${(percent * 100).toFixed(1)}%`
        : `${value.toFixed(2)}%`
    }
    </text>
  )
}

export const downloadExcelPie = (sales: any, tipe: string) => {
  let formattedSales
  let name
  if (tipe === 'salesSbu') {
    formattedSales = sales.map((ele: any) => ({
      Sbu: ele.sbu_description,
      Cantidad: ele.total_ventas,
      Total: CurrencyFormat(ele.total_venta)
    }))
    name = 'Venta por SBU'
  }

  if (tipe === 'salesUnits') {
    formattedSales = sales.map((ele: any) => ({
      Sbu: ele.sbu,
      Producto: ele.name,
      Cantidad: ele.quantity_sold
    }))
    name = 'Venta por Unidades'
  }

  if (tipe === 'returnSbu') {
    formattedSales = sales.map((ele: any) => ({
      Sbu: ele.sbu_description,
      Cantidad: ele.total_retornos,
      Total: CurrencyFormat(ele.total_venta)
    }))
    name = 'Devoluciones por SBU'
  }

  if (tipe === 'returnUnits') {
    formattedSales = sales.map((ele: any) => ({
      Sbu: ele.sbu,
      Producto: ele.name,
      Cantidad: ele.quantity_returned
    }))
    name = 'Devoluciones por Unidades'
  }

  const ws = XLSX.utils.json_to_sheet(formattedSales)
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, name)
  XLSX.writeFile(wb, `${name}.xlsx`)
}

export const colors: any = {
  2022: '#0473c8',
  2023: '#ff7300',
  2024: '#413ea0'
}

export const handleCheckData = (state: state, originalState: originalState | undefined) => {
  // Checkea si hay diferencia en META ANUAL
  if (originalState?.yearGoal !== state.yearGoal && state.yearGoal !== 0) {
    return true
  }
  // Checkea si hay diferencia en META MENSUAL
  for (const [i, month] of Object.entries(state.monthsGoal)) {
    const originalMonth = originalState?.monthsGoal[Number(i)]
    if (originalMonth && month.goal !== originalMonth.goal) {
      return true
    }
  }

  // Checkea si hay diferencia en META SEMANAL
  for (const [i, week] of Object.entries(state.weeks)) {
    const originalWeek = originalState?.weeks[Number(i)]
    if (originalWeek && week.weeklyGoal !== originalWeek.weeklyGoal) {
      return true
    }

    // Checkea si hay diferencia en META DIARIA
    for (const [j, day] of Object.entries(week.days)) {
      const originalDay = originalWeek?.days[Number(j)]
      if (originalDay && day.goal !== originalDay.goal) {
        return true
      }
    }
  }

  return false
}

// export const HandleCheckData = (state: state, originalState: originalState | undefined) => {
//   const dispatch = useDispatch()

//   let result = false
//   // Checkea si hay diferencia en META ANUAL
//   if (originalState?.yearGoal !== state.yearGoal && state.yearGoal !== 0) {
//     result = true
//   }
//   // Checkea si hay diferencia en META MENSUAL
//   if (!result) {
//     for (const [i, month] of Object.entries(state.monthsGoal)) {
//       const originalMonth = originalState?.monthsGoal[Number(i)]
//       if (originalMonth && month.goal !== originalMonth.goal) {
//         result = true
//         break
//       }
//     }
//   }

//   // Checkea si hay diferencia en META SEMANAL
//   if (!result) {
//     for (const [i, week] of Object.entries(state.weeks)) {
//       const originalWeek = originalState?.weeks[Number(i)]
//       if (originalWeek && week.weeklyGoal !== originalWeek.weeklyGoal) {
//         result = true
//         break
//       }

//       // Checkea si hay diferencia en META DIARIA
//       for (const [j, day] of Object.entries(week.days)) {
//         const originalDay = originalWeek?.days[Number(j)]
//         if (originalDay && day.goal !== originalDay.goal) {
//           result = true
//           break
//         }
//       }
//     }
//   }

//   if (result) {
//     dispatch(clickedHeaderButton({ booleanValue: true, state, originalState }))
//   }

//   return false
// }
