import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Divider, Grid, IconButton, Input, Paper, Switch, Table, TableBody, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { clickedHeaderButton, resetLastClickedButton } from 'src/store/states/header'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { formatMonth, handleCheckData } from '@utils/dashboardBsale'
import { GoalsTableProps, month, weeks } from '@models/goalsBsale'
import { CurrencyFormatUSD } from '@utils/Currency.utility'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import { DboardApi } from '@services/index'
import { AppStore } from 'src/store/store'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
// import PopperDayOfTheWeek from './PopperDayOfTheWeek'
// import PopperDay from './PopperDay'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#1976D2',
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

function GoalsTable ({ state, setState, originalState, setOriginalState }: GoalsTableProps) {
  const userState = useSelector((appStore: AppStore) => appStore.user)
  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState<string | false>(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleYearGoalChange = (event: any) => {
    const inputValue = event.target.value.replace(/\D/g, '')
    const newYearGoal = inputValue === '' ? 0 : Number(inputValue)

    // console.log('dailyGoal => ', dailyGoal)
    const updatedWeeks = [...state.weeks]
    const stateMonthsGoal = state.monthsGoal.reduce((acc: {monthWithGoals: number[], goal: number, days: number}, ele) => {
      if (ele.goal !== 0) {
        acc.monthWithGoals.push(ele.month)
        acc.goal += ele.goal
        acc.days += ele.days
      }
      return acc
    }, { monthWithGoals: [], goal: 0, days: 0 })
    // console.log('stateMonthsGoal => ', stateMonthsGoal)

    const sumGoals = () => {
      const filteredWeeks = updatedWeeks.map(week => ({
        ...week,
        days: week.days.filter(day => !stateMonthsGoal.monthWithGoals.includes(day.month))
      })).filter(week => week.days.length > 0)

      const sumGoals = filteredWeeks.reduce((acc: {weekWithGoal: number[], goal:number, days: number, daysWithNewGoal: number[]}, week) => {
        if (week.weeklyGoal !== 0 && week.weeklyGoal != null) {
          acc.weekWithGoal.push(week.week)
          acc.goal += (week.days.length < 7 ? ((week.weeklyGoal / 7) * week.days.length) : week.weeklyGoal)
          acc.days += week.days.length
        } else {
          // Busco los dias seteados aisladamente (Que no tenga meta semanal ni mensual)
          week.days.forEach(day => {
            if (day.newGoal !== 0 && day.newGoal != null) {
              acc.goal += day.newGoal
              acc.days += 1
              acc.daysWithNewGoal.push(day.id)
            }
          })
        }
        return acc
      }, { weekWithGoal: [], goal: 0, days: 0, daysWithNewGoal: [] })

      return sumGoals
    }
    const sumWeeklyGoalAndDays = sumGoals()
    // console.log('sumWeeklyGoalAndDays => ', sumWeeklyGoalAndDays)

    updatedWeeks.forEach(week => {
      week.days.forEach(day => {
        if (!stateMonthsGoal.monthWithGoals.includes(day.month) && !sumWeeklyGoalAndDays.weekWithGoal.includes(day.week) && !sumWeeklyGoalAndDays.daysWithNewGoal.includes(day.id)) {
          day.goal = Math.round((newYearGoal - stateMonthsGoal.goal - sumWeeklyGoalAndDays.goal) / (state.days - stateMonthsGoal.days - sumWeeklyGoalAndDays.days))
        }
      })
    })
    // console.log('updatedWeeks => ', updatedWeeks)

    setState(prevState => ({
      ...prevState,
      weeks: updatedWeeks,
      yearGoal: newYearGoal
    }))
  }

  const handleMonthGoalChange = (event: any, month: month, index: number) => {
    const inputValue = event.target.value.replace(/\D/g, '')
    const newGoal = inputValue === '' ? 0 : Number(inputValue)

    const updatedMonthsGoal = [...state.monthsGoal]

    updatedMonthsGoal[index] = { ...month, goal: newGoal }
    const monthSelected = updatedMonthsGoal[index]

    const weeksOfMonth = state.weeks.map(week => ({
      ...week,
      days: week.days.filter(day => day.month === monthSelected.month)
    })).filter(ele => ele.days.length > 0)
    // console.log('weeksOfMonth => ', weeksOfMonth)

    const MonthGoal = weeksOfMonth.reduce((acc: {goal: number, days: number, dayWithNewGoal: number[]}, week) => {
      if (week.weeklyGoal !== 0 && week.weeklyGoal != null) {
        week.days.forEach(day => {
          acc.goal += day.goal
          acc.days += 1
        })
      } else {
        week.days.forEach(day => {
          if (day.newGoal !== 0 && day.newGoal != null) {
            acc.goal += day.newGoal
            acc.days += 1
            acc.dayWithNewGoal.push(day.id)
          }
        })
      }
      return acc
    }, { goal: 0, days: 0, dayWithNewGoal: [] })
    // console.log('MonthGoal => ', MonthGoal)

    const stateMonthsGoal = state.monthsGoal.reduce((acc: {prevMonth: month[], goal: number, days: number}, ele) => {
      if (ele.goal !== 0 && ele.month !== monthSelected.month) {
        acc.prevMonth.push(ele)
        acc.goal += ele.goal
        acc.days += ele.days
      }
      return acc
    }, { prevMonth: [], goal: 0, days: 0 })
    // console.log('stateMonthsGoal => ', stateMonthsGoal)

    const monthWithGoals = state.monthsGoal.filter(ele => ele.goal !== 0).map(ele => ele.month)
    const freeWeeks = state.weeks.reduce((acc: {week: number[], goal: number, days: number, dayWithGoal: number[]}, week) => {
      const weekFiltered: weeks = {
        ...week,
        days: (stateMonthsGoal.goal === 0 && newGoal === 0)
          ? week.days.filter(day => week.weeklyGoal)
          : week.days.filter(day => !monthWithGoals.includes(day.month) && week.weeklyGoal && day.month !== monthSelected.month)
      }
      if (weekFiltered.days.length > 0) {
        weekFiltered.days.forEach(day => {
          acc.goal += day.goal
          acc.days += 1
        })
        acc.week.push(week.week)
      }
      if ((week.weeklyGoal === 0 || week.weeklyGoal == null) && (!(stateMonthsGoal.goal === 0 && newGoal === 0) ? !weeksOfMonth.some(el => el.week === week.week) : true)) {
        week.days.forEach(day => {
          if (day.newGoal && (!(stateMonthsGoal.goal === 0 && newGoal === 0) ? !monthWithGoals.includes(day.month) : true)) {
            acc.goal += day.newGoal
            acc.days += 1
            acc.dayWithGoal.push(day.id)
          }
        })
      }
      return acc
    }, { week: [], goal: 0, days: 0, dayWithGoal: [] })
    // console.log('freeWeeks => ', freeWeeks)

    const updatedWeeks = state.weeks.map((week) => {
      week.days.forEach((day) => {
        if (day.month === monthSelected.month) {
          if (week.weeklyGoal === 0 || week.weeklyGoal == null) {
            if (!MonthGoal.dayWithNewGoal.includes(day.id)) {
              day.goal = Math.round(newGoal !== 0
                ? ((newGoal - MonthGoal.goal) / (monthSelected.days - MonthGoal.days))
                : (state.yearGoal - stateMonthsGoal.goal - ((stateMonthsGoal.goal === 0 && newGoal === 0) ? freeWeeks.goal : MonthGoal.goal)) / (state.days - stateMonthsGoal.days - ((stateMonthsGoal.goal === 0 && newGoal === 0) ? freeWeeks.days : MonthGoal.days)))
            }
          }
        } else if (!stateMonthsGoal.prevMonth.some(ele => ele.month === day.month) && !freeWeeks.week.includes(week.week) && !freeWeeks.dayWithGoal.includes(day.id)) {
          day.goal = Math.round(newGoal !== 0
            ? (state.yearGoal - newGoal - stateMonthsGoal.goal - freeWeeks.goal) / (state.days - monthSelected.days - stateMonthsGoal.days - freeWeeks.days)
            : (state.yearGoal - stateMonthsGoal.goal - ((stateMonthsGoal.goal === 0 && newGoal === 0) ? freeWeeks.goal : MonthGoal.goal)) / (state.days - stateMonthsGoal.days - ((stateMonthsGoal.goal === 0 && newGoal === 0) ? freeWeeks.days : MonthGoal.days)))
        }
      })
      return week
    })

    setState(prevState => ({
      ...prevState,
      weeks: updatedWeeks,
      monthsGoal: updatedMonthsGoal
    }))
  }

  const handleWeeklyGoalChange = (event: any, weekIndex: number) => {
    const inputValue = event.target.value.replace(/\D/g, '')
    const newWeeklyGoal = inputValue === '' ? 0 : Number(inputValue)

    const monthWithGoals = state.monthsGoal.filter(ele => ele.goal !== 0).map(ele => ele.month)

    const updatedWeeks = [...state.weeks]
    const weekSelected = updatedWeeks[weekIndex]

    const monthNumbers = weekSelected.days.map(ele => ele.month).filter((month, index, self) => self.indexOf(month) === index)

    const monthsArray = state.monthsGoal.filter(ele => monthNumbers.includes(ele.month))

    const dailyGoal = newWeeklyGoal / 7

    let checkError
    for (const month of monthsArray) {
      if (month.goal !== 0) {
        const filteredWeeks = updatedWeeks.map(week => ({
          ...week,
          days: week.days.filter(day => day.month === month.month)
        })).filter(week => week.days.length > 0)
        // console.log('filteredWeeks => ', filteredWeeks)

        checkError = filteredWeeks.reduce((acc, week) => {
          if (week.week !== weekSelected.week) {
            acc += (week.weeklyGoal !== 0 && week.weeklyGoal != null) ? week.weeklyGoal : 0
          }
          return acc
        }, newWeeklyGoal)

        checkError = checkError ? checkError > month.goal : false

        const sumWeeklyGoalAndDays = filteredWeeks.reduce((acc: {prevWeek: weeks[], goal:number, days: number, dayInTheWeek: number[], sumDayInTheWeek: number, dayInTheMonth: number[]}, week) => {
          if (week.weeklyGoal && week.week !== (weekIndex + 1)) {
            acc.prevWeek.push(week)
            acc.goal += (week.days.length < 7 ? ((week.weeklyGoal / 7) * week.days.length) : week.weeklyGoal)
            acc.days += week.days.length
          }
          week.days.forEach(day => {
            if (week.week === (weekIndex + 1) && day.newGoal) {
              acc.sumDayInTheWeek += day.newGoal
              acc.dayInTheWeek.push(day.id)
              acc.dayInTheMonth.push(day.id)
              if (dailyGoal === 0) {
                acc.goal += day.newGoal
                acc.days += 1
                acc.dayInTheWeek = []
                acc.sumDayInTheWeek = 0
              }
            } else if (!week.weeklyGoal && day.newGoal) {
              acc.dayInTheMonth.push(day.id)
              acc.days += 1
              acc.goal += day.newGoal
            }
          })
          return acc
        }, { prevWeek: [], goal: 0, days: 0, dayInTheWeek: [], sumDayInTheWeek: 0, dayInTheMonth: [] })
        // console.log('sumWeeklyGoalAndDays => ', sumWeeklyGoalAndDays)

        updatedWeeks.forEach((week) => {
          week.days.forEach((day) => {
            if (day.week === (weekIndex + 1) && day.month === month.month) {
              if (!sumWeeklyGoalAndDays.dayInTheWeek.includes(day.id) && !sumWeeklyGoalAndDays.dayInTheMonth.includes(day.id)) {
                day.goal = Math.round(dailyGoal !== 0
                  ? sumWeeklyGoalAndDays.dayInTheWeek.length === 0 ? dailyGoal : ((newWeeklyGoal - sumWeeklyGoalAndDays.sumDayInTheWeek) / (7 - sumWeeklyGoalAndDays.dayInTheWeek.length))
                  : (month.goal - newWeeklyGoal - sumWeeklyGoalAndDays.goal) / (month.days - sumWeeklyGoalAndDays.days))
              }
            } else if (day.month === month.month && !sumWeeklyGoalAndDays.prevWeek.some((prevDay: any) => prevDay.week === day.week) && !sumWeeklyGoalAndDays.dayInTheMonth.includes(day.id)) {
              const discount = !weekSelected.days.every(ele => ele.month === month.month)
              let daysForDiscount = null
              if (discount) {
                daysForDiscount = weekSelected.days.filter(ele => ele.month === month.month).length
              }

              day.goal = Math.round((month.goal - (daysForDiscount !== null ? (daysForDiscount * (newWeeklyGoal / 7)) : newWeeklyGoal) - sumWeeklyGoalAndDays.goal) / (dailyGoal !== 0 ? (month.days - (daysForDiscount !== null ? daysForDiscount : 7) - sumWeeklyGoalAndDays.days) : (month.days - sumWeeklyGoalAndDays.days)))
            }
          })
        })
      } else {
        const filteredWeeks = updatedWeeks.map(week => ({
          ...week,
          days: week.days.filter(day => !monthWithGoals.includes(day.month))
        })).filter(week => week.days.length > 0)
        // console.log('filteredWeeks => ', filteredWeeks)

        // Suma de las metas semanales definidas y suma de sus dias
        const sumWeeklyGoalAndDays = filteredWeeks.reduce((acc: {prevWeek: weeks[], goal:number, days: number, daysWithNewGoal: number[], dayInTheWeek: number[], sumDayInTheWeek:number}, week) => {
          if (week.weeklyGoal && week.week !== (weekIndex + 1)) {
            acc.prevWeek.push(week)
            acc.goal += (week.days.length < 7 ? ((week.weeklyGoal / 7) * week.days.length) : week.weeklyGoal)
            acc.days += week.days.length
          } else {
            // Busco los dias seteados aisladamente (Que no tenga meta semanal ni mensual)
            week.days.forEach(day => {
              if (day.newGoal && week.week !== (weekIndex + 1)) {
                acc.goal += day.newGoal
                acc.days += 1
                acc.daysWithNewGoal.push(day.id)
              }
              if (week.week === (weekIndex + 1) && day.newGoal) {
                if (dailyGoal !== 0) {
                  acc.sumDayInTheWeek += day.newGoal
                  acc.dayInTheWeek.push(day.id)
                } else {
                  acc.goal += day.newGoal
                  acc.days += 1
                  acc.daysWithNewGoal.push(day.id)
                  acc.sumDayInTheWeek = 0
                  acc.dayInTheWeek = []
                }
              }
            })
          }
          return acc
        }, { prevWeek: [], goal: 0, days: 0, daysWithNewGoal: [], dayInTheWeek: [], sumDayInTheWeek: 0 })
        // console.log('sumWeeklyGoalAndDays => ', sumWeeklyGoalAndDays)

        // Suma de las metas definidas en los MESES y suma de sus dias
        const sumMonthsGoalAndDays = state.monthsGoal.reduce((acc, month) => {
          if (month.goal !== 0) {
            acc.goal += month.goal
            acc.days += month.days
          }
          return acc
        }, { goal: 0, days: 0 })

        filteredWeeks.forEach((week) => {
          week.days.forEach((day) => {
            if (day.week === (weekIndex + 1) && day.month === month.month) {
              if (!sumWeeklyGoalAndDays.dayInTheWeek.includes(day.id) && !sumWeeklyGoalAndDays.daysWithNewGoal.includes(day.id)) {
                day.goal = Math.round(dailyGoal !== 0
                  ? sumWeeklyGoalAndDays.dayInTheWeek.length === 0 ? dailyGoal : ((newWeeklyGoal - sumWeeklyGoalAndDays.sumDayInTheWeek) / (7 - sumWeeklyGoalAndDays.dayInTheWeek.length))
                  : (state.yearGoal - sumMonthsGoalAndDays.goal - newWeeklyGoal - sumWeeklyGoalAndDays.goal) / (state.days - sumMonthsGoalAndDays.days - sumWeeklyGoalAndDays.days))
              }
            } else if (day.week === (weekIndex + 1) && monthNumbers.includes(day.month)) {
              day.goal = Math.round(dailyGoal)
            } else if (!sumWeeklyGoalAndDays.prevWeek.some(prevDay => prevDay.week === day.week) && !sumWeeklyGoalAndDays.daysWithNewGoal.includes(day.id)) {
              const discount = newWeeklyGoal !== 0 && !weekSelected.days.every(ele => ele.month === month.month) && monthsArray.some(ele => ele.goal !== 0 && ele.goal != null)
              let daysForDiscount = null
              if (discount) {
                daysForDiscount = weekSelected.days.filter(ele => ele.month === month.month).length
              }
              day.goal = Math.round((state.yearGoal - sumMonthsGoalAndDays.goal - sumWeeklyGoalAndDays.goal - (daysForDiscount !== null ? (daysForDiscount * (newWeeklyGoal / (dailyGoal === 0 ? dailyGoal : 7))) : newWeeklyGoal)) / (state.days - (daysForDiscount !== null ? daysForDiscount : (dailyGoal === 0 ? dailyGoal : 7)) - sumMonthsGoalAndDays.days - sumWeeklyGoalAndDays.days))
            }
          })
        })
      }
    }

    updatedWeeks[weekIndex] = { ...weekSelected, weeklyGoal: newWeeklyGoal === 0 ? undefined : newWeeklyGoal, checkError }

    setState(prevState => ({
      ...prevState,
      weeks: updatedWeeks
    }))
  }

  const handleDailyGoalChange = (event: any, weekIndex: number, dayIndex: number) => {
    const inputValue = event.target.value.replace(/\D/g, '')
    const newDailyGoal = inputValue === '' ? 0 : Number(inputValue)

    const copyWeeks = [...state.weeks]
    const weekSelected = copyWeeks[weekIndex]
    // console.log('weekSelected => ', weekSelected)

    const daySelected = weekSelected.days[dayIndex]
    if (daySelected.weight) {
      enqueueSnackbar('Este dia tiene peso asignado.', { variant: 'warning' })
      return
    }
    // Mes asociado al dia, verificamos que el mes tenga GOAL
    const checkMonth = state.monthsGoal.filter(month => month.month === daySelected.month)[0]

    /// /////Si la semana tiene weeklyGoal y el mes tiene goal
    // Suma de METAS diarias ya seteadas en la semana
    const daysWithGoalInWeekWithGoal = weekSelected.days.reduce((acc : {sum: number, ids: number[]}, ele) => {
      if (ele.newGoal && ele.day !== daySelected.day) {
        acc.sum += ele.newGoal
        acc.ids.push(ele.id)
      }
      return acc
    }, { sum: 0, ids: [] })
    // console.log('daysWithGoalInWeekWithGoal => ', daysWithGoalInWeekWithGoal)

    /// /////Si la semana NO tiene weeklyGoal y el mes tiene goal
    // Suma de lo que resta repartir, entre los dias restantes del mes que no fueron seteados, y conteo de los dias ya seteados
    const restMonthGoalAndWeeklyGoal = copyWeeks.reduce((acc: {sum: number, remainingDays: number, ids: number[]}, week) => {
      const filteredDays = week.days.filter(day => day.month === daySelected.month)
      if (filteredDays.length > 0 && week.weeklyGoal !== 0 && week.weeklyGoal != null) {
        const weeklyContribution = filteredDays.length < 7 ? ((week.weeklyGoal / 7) * filteredDays.length) : week.weeklyGoal
        acc.sum -= weeklyContribution
        acc.remainingDays -= filteredDays.length
      } else {
        filteredDays.forEach(day => {
          if (day.day === daySelected.day) {
            acc.sum -= newDailyGoal
            if (newDailyGoal !== 0) {
              acc.remainingDays -= 1
            }
          }
          if (day.newGoal !== 0 && day.newGoal != null && day.day !== daySelected.day) {
            acc.sum -= day.newGoal
            acc.remainingDays -= 1
            acc.ids.push(day.id)
          }
        })
      }
      return acc
    }, { sum: checkMonth.goal, remainingDays: checkMonth.days, ids: [] })

    /// ///// Si la semana NO tiene weeklyGoal y el mes NO tiene goal
    // Suma del total de los meses con Meta, dias del mes y arreglo con sus ids para utilizarlo como validación
    const resumeMonths = state.monthsGoal.reduce((acc: {goal: number, days: number, months: number[]}, month) => {
      if (month.goal !== 0) {
        acc.goal += month.goal
        acc.days += month.days
        acc.months.push(month.month)
      }
      return acc
    }, { goal: 0, days: 0, months: [] })
    // Suma de las metas establecidas entre weeklyGoal y newGoal (metas semanales y diarias) que NO tengan una meta en su mes correspondiente
    const daysWithNewGoals = copyWeeks.reduce((acc: {goal: number, days: number[], weeks: number[], daysOfWeeks: number}, week) => {
      if (week.weeklyGoal !== 0 && week.weeklyGoal != null) {
        if (week.days.some(day => resumeMonths.months.includes(day.month))) {
          const filteredDays = week.days.filter(day => !resumeMonths.months.includes(day.month))
          acc.goal += ((week.weeklyGoal / 7) * filteredDays.length)
          acc.weeks.push(week.week)
          acc.daysOfWeeks += filteredDays.length
        } else {
          acc.goal += week.weeklyGoal
          acc.weeks.push(week.week)
          acc.daysOfWeeks += week.days.length
        }
      }
      week.days.forEach(day => {
        if (!resumeMonths.months.includes(day.month) && (day.newGoal !== 0 && day.newGoal != null) && day.id !== daySelected.id && (week.weeklyGoal === 0 || week.weeklyGoal == null)) {
          acc.goal += day.newGoal
          acc.days.push(day.id)
        }
      })
      return acc
    }, { goal: 0, days: [], weeks: [], daysOfWeeks: 0 })

    const updatedWeeks = copyWeeks.map((week) => {
      week.days.forEach((day) => {
        if ((weekSelected.weeklyGoal !== 0 && weekSelected.weeklyGoal != null) && checkMonth.goal !== 0) { // Si la semana TIENE weeklyGoal y el mes TIENE goal
          if (day.week === weekSelected.week) {
            if (day.day === daySelected.day) {
              day.goal = Math.round(newDailyGoal !== 0 ? newDailyGoal : (weekSelected.weeklyGoal - daysWithGoalInWeekWithGoal.sum) / (7 - daysWithGoalInWeekWithGoal.ids.length))
              day.newGoal = newDailyGoal
            } else if (!daysWithGoalInWeekWithGoal.ids.includes(day.id)) {
              const restDay = newDailyGoal !== 0 ? 1 : 0
              day.goal = Math.round((weekSelected.weeklyGoal - newDailyGoal - daysWithGoalInWeekWithGoal.sum) / (7 - restDay - daysWithGoalInWeekWithGoal.ids.length))
            }
          }
        } else if ((weekSelected.weeklyGoal === 0 || weekSelected.weeklyGoal == null) && checkMonth.goal !== 0) { // Si la semana NO tiene weeklyGoal y el mes TIENE goal
          if (day.month === checkMonth.month) {
            if (day.day === daySelected.day) {
              day.goal = Math.round(newDailyGoal !== 0 ? newDailyGoal : restMonthGoalAndWeeklyGoal.sum / restMonthGoalAndWeeklyGoal.remainingDays)
              day.newGoal = newDailyGoal
            } else if ((week.weeklyGoal === 0 || week.weeklyGoal == null) && !restMonthGoalAndWeeklyGoal.ids.includes(day.id)) {
              day.goal = Math.round((restMonthGoalAndWeeklyGoal.sum) / (restMonthGoalAndWeeklyGoal.remainingDays))
            }
          }
        } else if ((weekSelected.weeklyGoal !== 0 && weekSelected.weeklyGoal != null) && checkMonth.goal === 0) { // Si la semana TIENE weeklyGoal y el mes NO tiene goal
          if (day.week === weekSelected.week) {
            if (day.day === daySelected.day) {
              day.goal = Math.round(newDailyGoal !== 0 ? newDailyGoal : (weekSelected.weeklyGoal - daysWithGoalInWeekWithGoal.sum) / (7 - daysWithGoalInWeekWithGoal.ids.length))
              day.newGoal = newDailyGoal
            } else if (!daysWithGoalInWeekWithGoal.ids.includes(day.id)) {
              const restDay = newDailyGoal !== 0 ? 1 : 0
              day.goal = Math.round((weekSelected.weeklyGoal - newDailyGoal - daysWithGoalInWeekWithGoal.sum) / (7 - restDay - daysWithGoalInWeekWithGoal.ids.length))
            }
          }
        } else if ((weekSelected.weeklyGoal === 0 || weekSelected.weeklyGoal == null) && checkMonth.goal === 0) { // Si la semana NO tiene weeklyGoal y el mes NO tiene goal
          if (day.id === daySelected.id) {
            day.goal = Math.round(newDailyGoal !== 0 ? newDailyGoal : (state.yearGoal - resumeMonths.goal - daysWithNewGoals.goal) / (state.days - resumeMonths.days - daysWithNewGoals.days.length - daysWithNewGoals.daysOfWeeks))
            day.newGoal = newDailyGoal
          } else if (!resumeMonths.months.includes(day.month) && !daysWithNewGoals.days.includes(day.id) && !daysWithNewGoals.weeks.includes(day.week)) {
            const restDay = newDailyGoal !== 0 ? 1 : 0
            day.goal = Math.round((state.yearGoal - resumeMonths.goal - daysWithNewGoals.goal - newDailyGoal) / (state.days - resumeMonths.days - daysWithNewGoals.days.length - daysWithNewGoals.daysOfWeeks - restDay))
          }
        }
      })
      return week
    })

    setState(prevState => ({
      ...prevState,
      weeks: updatedWeeks
    }))
  }

  const handleAccordionChange = (event: any, isExpanded: any) => {
    // Only expand/collapse when the ArrowDropDownIcon is clicked
    if (event.target.closest('#panel2-header .MuiSvgIcon-root')) {
      setExpanded(isExpanded ? 'panel2' : false)
    }
  }

  const handleChangeCurrency = () => {
    const copyState = { ...state }

    copyState.currency = copyState.currency === 'USD' ? 'CLP' : 'USD'
    const conversionRate = state.totalUsd

    copyState.yearGoal = copyState.currency === 'USD' ? copyState.yearGoal / conversionRate : copyState.yearGoal * conversionRate
    copyState.monthsGoal = copyState.monthsGoal.map(month => ({
      ...month,
      goal: copyState.currency === 'USD' ? month.goal / conversionRate : month.goal * conversionRate
    }))
    copyState.weeks = copyState.weeks.map(week => {
      const updatedWeek = {
        ...week,
        weeklyGoal: week.weeklyGoal ? (copyState.currency === 'USD' ? week.weeklyGoal / conversionRate : week.weeklyGoal * conversionRate) : week.weeklyGoal,
        days: week.days.map(day => ({
          ...day,
          goal: copyState.currency === 'USD' ? day.goal / conversionRate : day.goal * conversionRate,
          newGoal: day.newGoal ? (copyState.currency === 'USD' ? day.newGoal / conversionRate : day.newGoal * conversionRate) : day.newGoal
        }))
      }
      return updatedWeek
    })

    console.log('copyState => ', copyState)

    setState(copyState)
  }

  const handleTotalUsd = (e: any) => {
    const value = Number(e.target.value)
    setState({ ...state, totalUsd: value })
  }

  const handleSave = async () => {
    setState(prevState => ({
      ...prevState,
      saving: true
    }))
    try {
      const res = await DboardApi.updateGoals({ ...state, userId: userState.id })
      console.log('res => ', res)
      setState(prevState => ({
        ...prevState,
        saving: false
      }))
      const originalStateCopy = JSON.parse(JSON.stringify({
        yearGoal: state.yearGoal,
        monthsGoal: state.monthsGoal,
        weeks: state.weeks
      }))

      setOriginalState && setOriginalState(originalStateCopy)
      dispatch(resetLastClickedButton())
      enqueueSnackbar('Información guardada correctamente.', { variant: 'success' })
    } catch (error) {
      console.log(error)
      setState(prevState => ({
        ...prevState,
        saving: false
      }))
      enqueueSnackbar('Hubo un error al guarda, por favor, vuelva a intentar.', { variant: 'error' })
    }
  }

  const canSave = handleCheckData(state, originalState)
  if (canSave && state.currency === 'USD') {
    const stateCopy = JSON.parse(JSON.stringify({ state }))
    dispatch(clickedHeaderButton({ booleanValue: true, stateCopy, originalState }))
  } else {
    dispatch(resetLastClickedButton())
  }

  // Dias de la semana para encabezado de la tabla (TableHead)
  const dayOfTheWeek = [
    { day: 'Lunes' },
    { day: 'Martes' },
    { day: 'Miercoles' },
    { day: 'Jueves' },
    { day: 'Viernes' },
    { day: 'Sábado' },
    { day: 'Domingo' }
  ]

  const [popper, setPopper] = useState<{anchorEl: null | HTMLElement, open: boolean, week: number, dayOfTheWeek: number, day: number}>({
    anchorEl: null,
    open: false,
    week: 0,
    dayOfTheWeek: 0,
    day: 0
  })
  // console.log('popper => ', popper)

  const handlePopperDayOfWeek = (e: any, i: number) => {
    const anchorEl: any = popper.anchorEl ? null : e.currentTarget
    const open = Boolean(anchorEl)
    setPopper({ open, anchorEl, week: 0, dayOfTheWeek: i, day: 0 })
  }

  const handlePopperDay = (e: any, i: number, j: number) => {
    const anchorEl: any = popper.anchorEl ? null : e.currentTarget
    const open = Boolean(anchorEl)
    setPopper({ open, anchorEl, week: i, dayOfTheWeek: 0, day: j })
  }

  return (
    <>
      <Accordion expanded={expanded === 'panel2'} onChange={handleAccordionChange} sx={{ mb: 3 }}>
        <AccordionSummary
          expandIcon={
            <IconButton>
              <ArrowDropDownIcon />
            </IconButton>
          }
          aria-controls='panel2-content'
          id='panel2-header'
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', paddingRight: 1 }}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <Typography sx={{ backgroundColor: '#1976D2', color: 'white', padding: 1, borderRadius: 1, width: 150 }}>Meta Anual {state.currency === 'USD' ? 'USD' : 'CLP'}:</Typography>
              <TextField
                sx={{ '& .Mui-disabled': { WebkitTextFillColor: '#1976D2 !important' } }}
                size='small' type='text' value={CurrencyFormatUSD(state.yearGoal, state.currency)} onChange={handleYearGoalChange}
                disabled={state.currency === 'CLP' || !state.canSave}
              />
              <Typography sx={{ backgroundColor: '#1976D2', color: 'white', padding: 1, borderRadius: 1 }}>USD:</Typography>
              <TextField size='small' type='number' sx={{ width: 100 }} onChange={handleTotalUsd} disabled={state.currency === 'CLP' || !state.canSave} />

              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Chip label='USD' color={state.currency === 'USD' ? 'primary' : 'default'} />
                <Switch onClick={handleChangeCurrency} disabled={state.totalUsd === 0} />
                <Chip label='CLP' color={state.currency === 'CLP' ? 'primary' : 'default'} />
              </Box>

            </Box>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <IconButton onClick={(event) => { event.stopPropagation(); setState({ ...state, canSave: !state.canSave }) }} disabled={state.currency === 'CLP'}>
                <EditIcon color={state.currency === 'CLP' ? 'disabled' : 'primary'} />
              </IconButton>
              <IconButton
                disabled={state.monthsGoal.length === 0 || state.weeks.length === 0 || state.currency === 'CLP' || !state.canSave || !canSave}
                onClick={handleSave}
              >
                <SaveIcon color={(state.canSave && state.currency !== 'CLP' && canSave) ? 'primary' : 'disabled'} />
              </IconButton>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingBottom: 1 }}>
          <Divider />
          <Grid container columnSpacing={4} rowSpacing={2} sx={{ py: 3 }}>
            {state.monthsGoal.map((month, i) => (
              <Grid item xs={12} md={4} lg={2} key={i} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography sx={{ width: '100%', backgroundColor: '#1976D2', color: 'white', padding: 0.5, textAlign: 'center', borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>{formatMonth(month.month)}</Typography>
                <TextField
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      '& .Mui-disabled': { WebkitTextFillColor: '#1976D2 !important' }
                    }
                  }}
                  inputProps={{
                    style: { textAlign: 'center' }
                  }}
                  size='small' type='text' value={CurrencyFormatUSD(month.goal, state.currency)} onChange={(event) => handleMonthGoalChange(event, month, i)} disabled={state.yearGoal <= 0 || state.currency === 'CLP' || !state.canSave}
                />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label='customized table' id='idTableTradeinSales'>
          <TableHead>
            <TableRow>
              <StyledTableCell align='center' sx={{ width: 75 }}>Semana</StyledTableCell>
              {dayOfTheWeek.map((day, i) => (
                <StyledTableCell key={i}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
                    {day.day}
                    <IconButton
                      sx={{ width: 15, height: 15 }} onClick={(e) => handlePopperDayOfWeek(e, i)}
                    >
                      {/* <ArrowDropDownIcon sx={{ color: 'white' }} /> */}
                      {/* {popper.open && popper.dayOfTheWeek === i && <PopperDayOfTheWeek popper={popper} setPopper={setPopper} state={state} setState={setState} />} */}
                    </IconButton>
                  </Box>
                </StyledTableCell>
              ))}
              <StyledTableCell align='center'>Meta semanal</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.weeks.map((week, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell align='center'>{week.week}</StyledTableCell>
                {week.days.map((day, j) => (
                  <StyledTableCell align='center' key={j}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
                      <Input
                        sx={{ color: day.weight ? '#ED6C02' : (day.newGoal !== 0 && day.newGoal != null) ? '#1565C0' : 'inherit', '& .Mui-disabled': { WebkitTextFillColor: '#1976D2 !important' } }}
                        disabled={day.goal <= 0 || state.yearGoal <= 0 || state.currency === 'CLP' || !state.canSave}
                        error={state.yearGoal === 0 ? false : day.goal <= 0}
                        value={CurrencyFormatUSD(day.goal, state.currency)}
                        onChange={(event) => handleDailyGoalChange(event, i, j)}
                      />
                      <IconButton
                        color={day.weight ? 'warning' : 'default'}
                        sx={{ width: 30, height: 30 }} onClick={(e) => handlePopperDay(e, i, j)}
                      >
                        {/* <ArrowDropDownIcon sx={{ fontSize: 30 }} /> */}
                        {/* {popper.open && popper.week === i && popper.day === j && <PopperDay daySelected={day} popper={popper} setPopper={setPopper} state={state} setState={setState} />} */}
                      </IconButton>

                    </Box>
                  </StyledTableCell>
                ))}
                <StyledTableCell align='center'>
                  <TextField
                    disabled={state.yearGoal <= 0 || state.currency === 'CLP' || !state.canSave}
                    error={week.checkError}
                    size='small'
                    type='text'
                    value={CurrencyFormatUSD(week.weeklyGoal || 0, state.currency)}
                    onChange={(event) => handleWeeklyGoalChange(event, i)}
                    sx={{ '& .Mui-disabled': { WebkitTextFillColor: '#1976D2 !important' } }}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default GoalsTable
