import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer, TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { DBOARD_FORMATEND, DBOARD_FORMATSTART, DBOARD_MINDATE } from '../../../../constants/dashboard.constants'
import { DboardApi } from '../../../../services'
import { sharingInformationService } from '../../../../utils'
import { exportTableToExcel } from '../../../../utils/Excel.utility'
import { useDashboardContext } from '../../context/dashboard.context'
import EnhancedTableHead from './EnhancedTableHead'
import EnhancedTableHeadExcel from './EnhancedTableHeadExcel'
import { ReactComponent as Excel } from '@assets/svg/excel.svg'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import WhereToVoteIcon from '@mui/icons-material/WhereToVote'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CancelIcon from '@mui/icons-material/Cancel'
import StoreIcon from '@mui/icons-material/Store'
import UnarchiveIcon from '@mui/icons-material/Unarchive'
import DelayChart from './components/DelayChart'
import { useSnackbar } from 'notistack'
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket'
import PlaceIcon from '@mui/icons-material/Place'

const TableFulfillment = () => {
  const { ctx } = useDashboardContext()
  const [data, setData] = useState <{ dataFulfillment: any[], nameSeller: any, page: number, rowsPerPage: number, order: string, orderBy: string, idSeller: number, dateStart: string, dateEnd: string, loading: boolean }>({ dataFulfillment: [], nameSeller: '', page: 0, rowsPerPage: 10, order: 'asc', orderBy: '', idSeller: ctx.idSeller, dateStart: ctx.dateStart, dateEnd: ctx.dateEnd, loading: false })
  const subscription$ = sharingInformationService.getSubject()
  const { enqueueSnackbar } = useSnackbar()

  const handleChangePage = (event: any, newPage : number) => {
    setData({ ...data, page: newPage })
  }

  const handleChangeRowsPerPage = (event: any) => {
    setData({ ...data, rowsPerPage: parseInt(event.target.value, 10), page: 0 })
  }

  const handleRequestSort = (event: any, property : any) => {
    const isAsc = data.orderBy === property && data.order === 'asc'
    setData({ ...data, order: isAsc ? 'desc' : 'asc', orderBy: property })
  }

  const stableSort = (array: any[], comparator: any) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  const getComparator = (order: string, orderBy : string) => {
    return order === 'desc'
      ? (a: any, b: any) => descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy)
  }

  const descendingComparator = (a: any, b: any, orderBy : string) => {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const loadData = (_dateStart: string, _dateEnd: string) => {
    setData({ ...data, loading: true })
    const datos = {
      date_init: _dateStart,
      date_end: _dateEnd
    }
    DboardApi.getFulfillments(datos).then((response) => {
      console.log('response =>', response)
      setData({ ...data, dataFulfillment: response.data, dateStart: _dateStart, dateEnd: _dateEnd, loading: false })
      console.log('dataFulfillment => ', data.dataFulfillment)
    }).catch((error) => {
      console.log('TotalVentas loadData error:', error)
      setData({ ...data, loading: false })
    })
  }

  const handleExportExcel = (IdTable: string) => {
    exportTableToExcel(IdTable, 'Tabla despachos')
  }

  useMemo(() => {
    loadData(moment(data.dateStart).format(DBOARD_FORMATSTART), moment(data.dateEnd).format(DBOARD_FORMATEND))
    subscription$.subscribe((info: any) => {
      loadData(moment(info.dateStart).format(DBOARD_FORMATSTART), moment(info.dateEnd).format(DBOARD_FORMATEND))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Card>
      <CardContent>
        <Grid container direction='row' spacing={2}>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Box sx={{ p: 1, pb: 0 }}>
              <Typography
                variant='h5'
                color='textSecondary'
                sx={{ color: '#000', fontWeight: '500px', fontSize: '1rem' }}
              >
                Estado de los <b>Despachos</b>
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'right',
                  justifyContent: 'right'
                }}
              >
                <Button
                  variant='contained'
                  color='success'
                  size='small'
                  onClick={() => handleExportExcel('tabla_despachos')}
                >
                  <Excel className='me-2' /> Exportar
                </Button>
              </Box>
              <Grid container direction='row' spacing={2} my={1}>
                <Grid item xs={12} md={4} lg={3} xl={3}>
                  <DatePicker
                    label='Fecha Desde'
                    disableFuture
                    minDate={DBOARD_MINDATE}
                    format='dd-MM-yyyy'
                    value={moment(data.dateStart).toDate()}
                    onChange={(newValue) => {
                      setData({ ...data, dateStart: moment(newValue).format(DBOARD_FORMATSTART) })
                    }}
                    slotProps={{
                      textField: {size: "small", fullWidth: true}
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={3}>
                  <DatePicker
                    label='Fecha Hasta'
                    disableFuture
                    minDate={moment(data.dateStart).toDate()}
                    format='dd-MM-yyyy'
                    value={moment(data.dateEnd).toDate()}
                    onChange={(newValue) => {
                      setData({ ...data, dateEnd: moment(newValue).format(DBOARD_FORMATEND) })
                    }}
                    slotProps={{
                      textField: {size: "small", fullWidth: true}
                    }}
                  />
                </Grid>
                <Grid item xs={2} md={2} lg={2} xl={2}>
                  <Box>
                    <LoadingButton
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        loadData(data.dateStart, data.dateEnd)
                        enqueueSnackbar('Cargando información, esto podria tardar unos segundos.', { variant: 'info' })
                      }}
                      disabled={!moment(data.dateEnd).isAfter(data.dateStart)}
                      loading={data.loading}
                    >
                      Filtrar
                    </LoadingButton>

                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Box>
              <TableContainer sx={{ maxHeight: '450px' }}>
                <Table id='tableFulfillment' size='small' border={1} stickyHeader>
                  <EnhancedTableHead
                    numSelected={0}
                    order={data.order}
                    orderBy={data.orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={data.dataFulfillment.length}
                  />
                  {data.dataFulfillment &&
                data.dataFulfillment.length !== 0
                    ? (
                      <TableBody>
                        {stableSort(
                          data.dataFulfillment,
                          getComparator(data.order, data.orderBy)
                        )
                          .slice(
                            data.page * data.rowsPerPage,
                            data.page * data.rowsPerPage + data.rowsPerPage
                          )
                          .filter(e => e.status !== 'voided')
                          .map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`
                            return (
                              <TableRow
                                hover
                                role='checkbox'
                                tabIndex={-1}
                                key={index + 1}
                                selected={false}
                              >
                                <TableCell
                                  component='td'
                                  id={labelId}
                                  scope='row'
                                  align='left'
                                  width='30px'
                                >
                                  <Box className='d-flex align-items-center'>
                                    <span>{row.id_order_platform}</span>
                                    {
                                      row.type === 1
                                        ? <Tooltip title='Next day'><PlaceIcon className='ms-1' style={{ color: 'var(--reuse-azul)' }} /></Tooltip>
                                        : row.type === 2
                                          ? <Tooltip title='Same day'><PlaceIcon className='ms-1' style={{ color: 'orange' }} /></Tooltip>
                                          : row.type === 3
                                            ? <Tooltip title='Regiones'><PlaceIcon className='ms-1' style={{ color: 'green' }} /></Tooltip>
                                            : row.type === 4
                                              ? <Tooltip title='Venta internacional'><AirplaneTicketIcon className='ms-1' color='secondary' /></Tooltip>
                                              : null
                                    }
                                  </Box>
                                </TableCell>
                                <TableCell align='center' width='50px'>
                                  {moment.utc(row.fecha_venta).format('DD-MM-YYYY HH:mm:ss')}
                                </TableCell>
                                <TableCell align='center' width='50px'>
                                  {row.ciudad_despacho.toUpperCase()}
                                </TableCell>
                                <TableCell align='center' width='50px'>
                                  {row.fecha_estimada_despacho.toUpperCase()}
                                </TableCell>
                                <TableCell align='center' width='50px'>
                                  {row.fecha_efectiva_despacho.toUpperCase()}
                                </TableCell>
                                <TableCell align='center' width='50px'>
                                  <Typography style={{ color: row.first_delay === 0 ? 'green' : 'red' }}>{row.first_delay}</Typography>
                                </TableCell>
                                <TableCell align='center' width='50px'>
                                  {row.fecha_estimada_recepcion.toUpperCase()}
                                </TableCell>
                                <TableCell align='center' width='50px'>
                                  <Typography style={{
                                    color: row.fecha_recepcion === 'Sin tracking ID'
                                      ? 'red'
                                      : row.fecha_recepcion === 'Cancelado'
                                        ? 'red'
                                        : row.fecha_recepcion === 'Esperando envío...'
                                          ? 'var(--reuse-azul)'
                                          : 'rgba(0,0,0,0.87)',
                                    fontSize: '0.875rem'
                                  }}
                                  >
                                    {row.fecha_recepcion.toUpperCase()}
                                  </Typography>

                                </TableCell>
                                <TableCell align='center' width='50px'>
                                  <Typography style={{ color: row.second_delay === 0 ? 'green' : 'red' }}>{row.second_delay}</Typography>
                                </TableCell>
                                <TableCell align='center' width='50px'>
                                  <Grid container direction='row' spacing={2} my={1}>
                                    <Grid item xs={12} md={9} lg={9} xl={9}>
                                      <Typography style={{
                                        color: row.status === 'Despachado'
                                          ? 'var(--reuse-azul)'
                                          : row.status === 'Local pickup'
                                            ? '#000'
                                            : row.status === 'Sin despachar'
                                              ? 'orange'
                                              : row.status === 'Entregado'
                                                ? 'green'
                                                : row.status === 'Listo para despacho'
                                                  ? 'var(--reuse-azul)'
                                                  : row.status === 'POS'
                                                    ? '#000'
                                                    : 'red',
                                        fontSize: '0.875rem',
                                        padding: '0'
                                      }}
                                      >
                                        {row.status.toUpperCase()}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={3} xl={3}>
                                      {row.status === 'Despachado'
                                        ? <LocalShippingIcon style={{ color: 'var(--reuse-azul)' }} />
                                        : row.status === 'Local pickup'
                                          ? <StoreIcon style={{ color: '#000' }} />
                                          : row.status === 'Sin despachar'
                                            ? <AccessTimeIcon style={{ color: 'orange' }} />
                                            : row.status === 'Entregado'
                                              ? <WhereToVoteIcon style={{ color: 'green' }} />
                                              : row.status === 'Listo para despacho'
                                                ? <UnarchiveIcon style={{ color: 'var(--reuse-azul)' }} />
                                                : row.status === 'POS'
                                                  ? <StoreIcon style={{ color: '#000' }} />
                                                  : <CancelIcon style={{ color: 'red' }} />}
                                    </Grid>
                                  </Grid>
                                </TableCell>
                                <TableCell align='center' width='50px'>
                                  <Typography style={{ color: row.total_delay === 0 ? 'green' : 'red' }}>{row.total_delay}</Typography>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                      </TableBody>
                      )
                    : (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={6}>
                            <Typography
                              variant='h5'
                              color='textSecondary'
                              sx={{ color: '#000', fontWeight: '500px' }}
                            >
                              Sin registro en la fecha seleccionada{' '}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                      )}
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[10, 20, 50, 100]}
                component='div'
                count={data.dataFulfillment.length}
                rowsPerPage={data.rowsPerPage}
                page={data.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
            {/**  table excel **/
              <Box id='tabla_despachos' hidden>
                <p style={{ fontSize: '20px' }}>Tabla <strong>Despachos</strong></p>
                <Table size='small'>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{
                        border: 0,
                        padding: '5px 0',
                        background: 'orange',
                        width: '30px',
                        height: '20px'
                      }}
                      />
                      <TableCell style={{
                        border: 0,
                        padding: '0px 10px'
                      }}
                      >
                        Same day
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{
                        border: 0,
                        padding: '5px 0',
                        background: '#0473c8',
                        width: '30px',
                        height: '20px'
                      }}
                      />
                      <TableCell style={{
                        border: 0,
                        padding: '0px 10px'
                      }}
                      >
                        Next day
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{
                        border: 0,
                        padding: '5px 0',
                        background: 'green',
                        width: '30px',
                        height: '20px'
                      }}
                      />
                      <TableCell style={{
                        border: 0,
                        padding: '0px 10px'
                      }}
                      >
                        Regiones
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          border: 0,
                          padding: '5px 0',
                          width: '30px',
                          background: '#9c27b0',
                          height: '20px'
                        }}
                      />
                      <TableCell style={{
                        border: 0,
                        padding: '0px 10px'
                      }}
                      >
                        Venta internacional
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{
                        width: '30px',
                        padding: '10px'
                      }}
                      />
                      <TableCell />
                    </TableRow>
                  </TableBody>
                </Table>
                <Table size='small' border={1}>
                  <EnhancedTableHeadExcel
                    numSelected={0}
                    order={data.order}
                    orderBy={data.orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={data.dataFulfillment.length}
                  />
                  {data.dataFulfillment && data.dataFulfillment.length > 0
                    ? (
                      <TableBody>
                        {data.dataFulfillment.map((row, index) => {
                          const evenStyle = { background: '' }
                          if (index % 2 === 0) {
                            evenStyle.background = '#f7f7ff'
                          }
                          return (
                            <TableRow
                              hover
                              role='checkbox'
                              tabIndex={-1}
                              key={index + 1}
                              selected={false}
                            >
                              <TableCell
                                component='td'
                                scope='row'
                                align='left'
                                width='30px'
                                style={
                                  row.type === 1
                                    ? { background: '#0473c8', color: '#fff' }
                                    : row.type === 2
                                      ? { background: 'orange', color: '#fff' }
                                      : row.type === 3
                                        ? { background: 'green', color: '#fff' }
                                        : row.type === 4
                                          ? { background: '#9c27b0', color: '#fff' }
                                          : { background: 'white', color: 'black' }
                                }
                              >
                                {row.id_order_platform}
                              </TableCell>
                              <TableCell align='center' width='50px'>
                                {moment.utc(row.fecha_venta).format('DD-MM-YYYY HH:mm:ss')}
                              </TableCell>
                              <TableCell align='center' width='50px'>
                                {row.ciudad_despacho.toUpperCase()}
                              </TableCell>
                              <TableCell align='center' width='50px'>
                                {row.fecha_estimada_despacho.toUpperCase()}
                              </TableCell>
                              <TableCell align='center' width='50px'>
                                {row.fecha_efectiva_despacho.toUpperCase()}
                              </TableCell>
                              <TableCell align='center' width='50px'>
                                <Typography style={{ color: row.first_delay === 0 ? 'green' : 'red' }}>{row.first_delay}</Typography>
                              </TableCell>
                              <TableCell align='center' width='50px'>
                                {row.fecha_estimada_recepcion.toUpperCase()}
                              </TableCell>
                              <TableCell align='center' width='50px'>
                                <Typography style={{
                                  color: row.fecha_recepcion === 'Sin tracking ID'
                                    ? 'red'
                                    : row.fecha_recepcion === 'Cancelado'
                                      ? 'red'
                                      : 'rgba(0,0,0,0.87)',
                                  fontSize: '0.875rem'
                                }}
                                >
                                  {row.fecha_recepcion.toUpperCase()}
                                </Typography>

                              </TableCell>
                              <TableCell align='center' width='50px'>
                                <Typography style={{ color: row.second_delay === 0 ? 'green' : 'red' }}>{row.second_delay}</Typography>
                              </TableCell>
                              <TableCell align='center' width='50px'>
                                <Typography style={{
                                  color: row.status === 'Despachado'
                                    ? 'var(--reuse-azul)'
                                    : row.status === 'Entregado'
                                      ? 'green'
                                      : row.status === 'Sin despachar'
                                        ? 'orange'
                                        : row.status === 'Listo para despacho'
                                          ? 'var(--reuse-azul)'
                                          : row.status === 'Local pickup'
                                            ? '#000'
                                            : row.status === 'POS'
                                              ? '#000'
                                              : 'red',
                                  fontSize: '0.875rem'
                                }}
                                >
                                  {row.status.toUpperCase()}
                                </Typography>
                              </TableCell>
                              <TableCell align='center' width='50px'>
                                <Typography style={{ color: row.total_delay === 0 ? 'green' : 'red' }}>{row.total_delay}</Typography>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                      )
                    : (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={6}>
                            <Typography
                              variant='h5'
                              color='textSecondary'
                              sx={{ color: '#000', fontWeight: '500px' }}
                            >
                              Sin registro en la fecha seleccionada
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                      )}
                </Table>
              </Box>
        }
          </Grid>
        </Grid>
        <DelayChart dataFullfilment={data.dataFulfillment} />
      </CardContent>
    </Card>

  )
}

export default TableFulfillment
