import {
  Box,
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { useMemo, useState } from 'react'
import { DboardApi } from '../../../services'
import { CurrencyFormat, sharingInformationService } from '../../../utils'
import { exportTableToExcel } from '../../../utils/Excel.utility'
import { useDashboardContext } from '../context/dashboard.context'
import { ReactComponent as Excel } from '@assets/svg/excel.svg'

const TablePlanSeller = () => {
  const { ctx } = useDashboardContext()
  const [data, setData] = useState <{ dataPlanSeller: any[], idSeller: number, loading: boolean }>({ dataPlanSeller: [], idSeller: ctx.idSeller, loading: false })
  const subscription$ = sharingInformationService.getSubject()

  const loadData = (_idSeller: number) => {
    setData({ ...data, loading: true })
    DboardApi.getPlanSeller(_idSeller).then((response) => {
      setData({ ...data, dataPlanSeller: response.items, idSeller: _idSeller, loading: false })
    }).catch((error) => {
      console.log('TotalVentas loadData error:', error)
      setData({ ...data, loading: false })
    })
  }

  useMemo(() => {
    loadData(data.idSeller)
    subscription$.subscribe((info: any) => {
      loadData(info.idSeller)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card className='h-100'>
      <CardContent>
        <Box sx={{ p: 0, pb: 0 }}>
          <Typography
            variant='h5'
            color='textSecondary'
            sx={{ color: '#000', fontWeight: '500px', fontSize: '1rem' }}
          >
            Planes{' '}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'right',
              justifyContent: 'right'
            }}
          >
            <Button
              variant='contained'
              color='success'
              size='small'
              onClick={() =>
                exportTableToExcel('idTablePlan', 'Plan Asociado')}
            >
              <Excel className='me-2' /> Exportar
            </Button>
          </Box>
          <Box my={2}>
            <TableContainer
              sx={{
                width: '100%',
                overflowX: 'auto',
                position: 'relative',
                display: 'block',
                maxWidth: '100%',
                '& td, & th': { whiteSpace: 'nowrap' }
              }}
            >
              <Table size='small' aria-labelledby='tableTitle' border={1}>
                <TableHead>
                  <TableRow sx={{ background: 'linear-gradient(to left top, #4676b9, #7c3c91)' }}>
                    <TableCell
                      style={{
                        color: '#ffff',
                        width: 150,
                        textAlign: 'left'
                      }}
                    >
                      Plan Asociado
                    </TableCell>
                    <TableCell
                      style={{
                        color: '#ffff',
                        width: 80,
                        textAlign: 'right'
                      }}
                    >
                      Precio
                    </TableCell>
                    <TableCell
                      style={{
                        color: '#ffff',
                        width: 100,
                        textAlign: 'center'
                      }}
                    >
                      Comisión por Venta
                    </TableCell>
                    <TableCell
                      style={{
                        color: '#ffff',
                        width: 100,
                        textAlign: 'center'
                      }}
                    >
                      Comisión por Bodegaje
                    </TableCell>
                    <TableCell
                      style={{
                        background: 'linear-gradient(to left top, #4676b9, #7c3c91)',
                        color: '#ffff',
                        width: 100,
                        textAlign: 'center'
                      }}
                    >
                      Periodicidad
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data.dataPlanSeller &&
                        data.dataPlanSeller.map((row : any, index: number) => (
                          <TableRow key={index + 1}>
                            <TableCell style={{ width: 150, textAlign: 'left' }}>
                              {row.nameSeller}
                            </TableCell>
                            <TableCell style={{ width: 80, textAlign: 'right' }}>
                              {CurrencyFormat(row.price)}
                            </TableCell>
                            <TableCell style={{ width: 100, textAlign: 'right' }}>
                              {(row.commisionPlan * 100).toFixed(0)}%
                            </TableCell>
                            <TableCell style={{ width: 100, textAlign: 'right' }}>
                              {row.storageCommisionPlan}%
                            </TableCell>
                            <TableCell
                              style={{ width: 100, textAlign: 'center' }}
                            >
                              {row.periodicityPlan}
                            </TableCell>
                          </TableRow>
                        ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/** * excel export */}

            <Table
              size='small'
              aria-labelledby='tableTitle'
              id='idTablePlan'
              border={1}
              hidden
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      width: 150,
                      textAlign: 'left'
                    }}
                  >
                    Plan Asociado
                  </TableCell>
                  <TableCell
                    style={{
                      width: 80,
                      textAlign: 'right'
                    }}
                  >
                    Precio
                  </TableCell>
                  <TableCell
                    style={{
                      width: 100,
                      textAlign: 'center'
                    }}
                  >
                    Comisión por Venta
                  </TableCell>
                  <TableCell
                    style={{
                      width: 100,
                      textAlign: 'center'
                    }}
                  >
                    Comisión por Bodegaje
                  </TableCell>
                  <TableCell
                    style={{
                      width: 100,
                      textAlign: 'center'
                    }}
                  >
                    Periodicidad
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.dataPlanSeller &&
                      data.dataPlanSeller.map((row : any, index: number) => (
                        <TableRow key={index + 1}>
                          <TableCell style={{ width: 150, textAlign: 'left' }}>
                            {row.nameSeller}
                          </TableCell>
                          <TableCell style={{ width: 80, textAlign: 'right' }}>
                            {CurrencyFormat(row.price)}
                          </TableCell>
                          <TableCell style={{ width: 100, textAlign: 'right' }}>
                            {(row.commisionPlan * 100).toFixed(0)}%
                          </TableCell>
                          <TableCell style={{ width: 100, textAlign: 'right' }}>
                            {row.storageCommisionPlan}%
                          </TableCell>
                          <TableCell style={{ width: 100, textAlign: 'center' }}>
                            <Button variant='text' color='primary'>
                              {row.periodicityPlan}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default TablePlanSeller
