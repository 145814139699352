import { DBOARD_FORMATEND, DBOARD_FORMATSTART } from '@constants/index'
import { DboardApi } from '@services/index'
import moment from 'moment'
import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react'

interface IOwnProps {
  listTradein: any[]
  listHistoryLog: any[]
  dateStart: string
  dateEnd: string
  loading: boolean
  setListTradein: Dispatch<SetStateAction<any[]>>
}

export type TIContextType = {
    tiCtx: IOwnProps;
    saveTICtx: (tiCtx: IOwnProps) => void;
    setListTradein: Dispatch<SetStateAction<any[]>>;
    loadData: (dateStart: string, dateEnd: string) => void;
};

export const TIContext = createContext<TIContextType | null>(null)

export const TIProvider = ({ children } : any) => {
  const [tiCtx, settiCtx] = useState<IOwnProps>({
    listTradein: [],
    listHistoryLog: [],
    loading: false,
    dateStart: moment().format(DBOARD_FORMATSTART),
    dateEnd: moment().format(DBOARD_FORMATEND),
    setListTradein: (listTradein) => {}
  })

  const loadData = (_createdSince: string, _createdUntil: string) => {
    settiCtx({ ...tiCtx, loading: true })
    DboardApi.getTradeinHistoryLog(_createdSince, _createdUntil).then((response) => {
      if (response.success) {
        settiCtx({ ...tiCtx, loading: false, listHistoryLog: response.data })
      } else {
        settiCtx({ ...tiCtx, loading: false })
      }
    }).catch((error) => {
      console.log('loadData error:', error)
      settiCtx({ ...tiCtx, loading: false })
    })
  }

  const saveTICtx = (_tiCtx: IOwnProps) => {
    settiCtx(_tiCtx)
  }

  const setListTradein: Dispatch<SetStateAction<any[]>> = (listTradein: SetStateAction<any[]>) => {
    settiCtx((prevTICtx) => ({
      ...prevTICtx,
      listTradein: typeof listTradein === 'function' ? listTradein(prevTICtx.listTradein) : listTradein
    }))
  }

  useEffect(() => {
    if (tiCtx.dateStart !== '' && tiCtx.dateEnd !== '') {
      loadData(moment(tiCtx.dateStart).format(DBOARD_FORMATSTART), moment(tiCtx.dateEnd).format(DBOARD_FORMATEND))
    }

    return () => settiCtx({
      listTradein: [],
      listHistoryLog: [],
      dateStart: moment().format(DBOARD_FORMATSTART),
      dateEnd: moment().format(DBOARD_FORMATEND),
      loading: false,
      setListTradein: (listTradein) => {}
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <TIContext.Provider value={{ tiCtx, saveTICtx, setListTradein, loadData }}>{children}</TIContext.Provider>
}

export const useTIContext = () => {
  const context = useContext(TIContext)
  if (context === undefined) {
    throw new Error('useTIContext must be used within a DashboardProvider')
  }
  return context
}
