import { DBOARD_FORMATSTART } from '@constants/dashboard.constants'
import { Box, Card, CardContent, FormControl, Grid, MenuItem, Select, Switch, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { ReactComponent as Excel } from '@assets/svg/excel.svg'
import moment from 'moment'
import { DboardApi } from '@services/index'
import { DashboardBsaleContextType, useDashboardBsaleContext } from '../context/dashboardBsale.context'
import { LoadingButton } from '@mui/lab'
import * as XLSX from 'xlsx'

function Filter () {
  const { ctx, saveCtx } = useDashboardBsaleContext() as DashboardBsaleContextType
  // console.log('data => ', data)
  const lastUpdate = moment(ctx.lastUpdate).format('DD-MM-YYYY')
  const today = moment().format('DD-MM-YYYY')

  const handleFilter = async (e: any) => {
    // console.log('e => ', e.target.checked)
    saveCtx({ ...ctx, filter: { ...ctx.filter, switch: e.target.checked != null ? !ctx.filter.switch : false, loading: true } })
    const parameters = {
      from: ctx.filter.from,
      to: ctx.filter.to,
      channelId: ctx.filter.selectedChannel?.id === 0 ? null : ctx.filter.selectedChannel?.id,
      sbuId: ctx.filter.selectedSbu?.id === 0 ? null : ctx.filter.selectedSbu?.id,
      switch: e.target.checked != null ? e.target.checked : false,
      weekInitial: ctx.filter.weekInitial,
      weekEnd: ctx.filter.weekEnd
    }
    console.log('parameters => ', parameters)
    const res = await DboardApi.getTotalSales(parameters).then(res => res.data)
    saveCtx({ ...res, filter: { ...ctx.filter, switch: e.target.checked != null ? !ctx.filter.switch : false, loading: false } })
  }

  const downloadExcel = async (table: string) => {
    saveCtx({
      ...ctx,
      filter: {
        ...ctx.filter,
        download: table === 'bsale_notes',
        download2: table !== 'bsale_notes'
      }
    })
    const parameters = {
      table,
      from: ctx.filter.from,
      to: ctx.filter.to,
      channelId: ctx.filter.selectedChannel?.id === 0 ? null : ctx.filter.selectedChannel?.id,
      sbuId: ctx.filter.selectedSbu?.id === 0 ? null : ctx.filter.selectedSbu?.id
    }
    const res = await DboardApi.downloadExcel(parameters)
    console.log(res)

    const sales = res.data
    const formattedSales = sales.map((ele: any) => ({
      'Tipo de movimiento': ele.movement_type,
      Motivo: ele.motive,
      Bodega: ele.office,
      Canal: ele.sales_channel,
      'Creado por': ele.created_by,
      'Email de cliente': ele.client_email,
      'Tipo de documento': ele.document_type,
      'Numero de documento': ele.document_number,
      'Fecha de documento': ele.document_date,
      'Fecha de venta': ele.sale_date,
      'Lista de precio': ele.price_list,
      SBU: ele.sbu,
      'ID de SBU': ele.id_sbu,
      Producto: ele.product,
      Variante: ele.variant,
      SKU: ele.sku,
      SERIAL: ele.serial,
      'Precio neto unitario': ele.net_unit_price,
      Cantidad: ele.quantity,
      Impuestos: ele.subtotal_taxes,
      'Subtotal Neto': ele.net_subtotal,
      'Subtotal Bruto': ele.gross_subtotal,
      'Costo Unitario Neto': ele.net_unit_cost,
      Margen: ele.margin,
      'Costo Total Neto': ele.total_net_cost,
      '% Margen': ele.margin_percentage
    }))

    const ws = XLSX.utils.json_to_sheet(formattedSales)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, 'Ventas de Bsale.xlsx')

    saveCtx({
      ...ctx,
      filter: {
        ...ctx.filter,
        download: false,
        download2: false
      }
    })
  }

  return (
    <Card>
      <CardContent className='filters'>
        <Grid container direction='row' spacing={2} alignItems='center'>
          <Grid item xs={12} md={12} lg={3.5} xl={3.5}>
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
              Dashboard Bsale
            </Typography>
            <Typography variant='subtitle2' sx={today === lastUpdate ? { color: 'green', fontWeight: 700 } : { color: 'red', fontWeight: 700 }}>
              Fecha ultima actualización: {lastUpdate !== null ? lastUpdate : '-'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={1} xl={1} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography textAlign='center'>Excluir NC</Typography>
            <Switch checked={ctx.filter.switch} onChange={(e) => handleFilter(e)} />
          </Grid>
          <Grid item xs={12} md={12} lg={1.5} xl={1.5}>
            <Typography>
              Desde
            </Typography>
            <DatePicker
              disableFuture
              minDate={moment('2023-01-02').toDate()}
              maxDate={moment().toDate()}
              format='dd-MM-yyyy'
              value={moment(ctx.filter.from).toDate() ?? null}
              onChange={(newValue) => {
                saveCtx({ ...ctx, filter: { ...ctx.filter, from: moment(newValue).format(DBOARD_FORMATSTART) } })
              }}
              slotProps={{
                textField: {size: "small"}
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={1.5} xl={1.5}>
            <Typography>
              Hasta
            </Typography>
            <DatePicker
              disableFuture
              minDate={moment(ctx.filter.from).toDate()}
              maxDate={moment().toDate()}
              format='dd-MM-yyyy'
              value={moment(ctx.filter.to).toDate() ?? null}
              onChange={(newValue) => {
                saveCtx({ ...ctx, filter: { ...ctx.filter, to: moment(newValue).format(DBOARD_FORMATSTART) } })
              }}
              slotProps={{
                textField: {size: "small"}
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={1.5} xl={1.5}>
            <Typography>
              Canal
            </Typography>
            <FormControl fullWidth>
              <Select
                value={
                  ctx.filter.channel === null
                    ? ''
                    : ctx.filter.selectedChannel?.id ?? ''
                }
                onChange={(e: any) => {
                  const channel = ctx.filter.channel?.find(ele => ele.id === e.target.value)
                  channel && saveCtx({ ...ctx, filter: { ...ctx.filter, selectedChannel: channel } })
                }}
                size='small'
              >
                {ctx.filter.channel && ctx.filter.channel.map((ele, i) => (
                  <MenuItem value={ele.id} key={i}>
                    {ele.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12} lg={1.5} xl={1.5}>
            <Typography>
              SBU
            </Typography>
            <FormControl fullWidth>
              <Select
                value={
                  ctx.filter.channel === null
                    ? ''
                    : ctx.filter.selectedSbu?.id ?? ''
                }
                onChange={(e: any) => {
                  const sbu = ctx.filter.sbu?.find(ele => ele.id === e.target.value)
                  sbu && saveCtx({ ...ctx, filter: { ...ctx.filter, selectedSbu: sbu } })
                }}
                size='small'
              >
                {ctx.filter.sbu && ctx.filter.sbu.map((ele, i) => (
                  <MenuItem value={ele.id} key={i}>
                    {ele.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12} lg={0.5} xl={0.5}>
            <LoadingButton
              loading={ctx.filter.loading}
              variant='contained' sx={{ padding: 0.5, minWidth: 'auto', width: '100%' }}
              onClick={(e) => handleFilter(e)}
            >
              <FilterAltIcon />
            </LoadingButton>
          </Grid>
          <Grid item xs={12} md={12} lg={0.5} xl={0.5}>
            <LoadingButton
              loading={ctx.filter.download}
              disabled={ctx.filter.download2}
              variant='contained' sx={{ padding: 0.5, minWidth: 'auto', width: '100%' }}
              onClick={() => downloadExcel('bsale_notes')}
            >
              {ctx.filter.download
                ? <Box sx={{ height: 24, width: 24 }} />
                : <Excel />}
            </LoadingButton>
          </Grid>
          <Grid item xs={12} md={12} lg={0.5} xl={0.5}>
            <LoadingButton
              loading={ctx.filter.download2}
              disabled={ctx.filter.download}
              variant='contained' sx={{ padding: 0.5, minWidth: 'auto', width: '100%' }}
              onClick={() => downloadExcel('bsale_sales')}
            >
              {ctx.filter.download2
                ? <Box sx={{ height: 24, width: 24 }} />
                : <Excel />}
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Filter
