import React, { useMemo, useState } from 'react'
import {
  Card,
  CardContent,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material'
import { exportTableToExcel } from '../../../../utils/Excel.utility'
import { useDashboardContext } from '../../context/dashboard.context'
import { DatePicker } from '@mui/x-date-pickers'
import { DBOARD_FORMATEND, DBOARD_FORMATSTART, DBOARD_MINDATE } from '../../../../constants/dashboard.constants'
import moment from 'moment'
import EnhancedTableHead from './EnhancedTableHead'
import { CurrencyFormat, sharingInformationService } from '../../../../utils'
import { DboardApi } from '../../../../services'
import { LoadingButton } from '@mui/lab'
import TableShippingSeller from './TableShippinSeller'
import EnhancedTableHeadExcel from './EnhancedTableHeadExcel'
import { ReactComponent as Excel } from '@assets/svg/excel.svg'
import { AppStore } from 'src/store/store'
import { useSelector } from 'react-redux'
import { VISTA_SELLERS } from '@constants/permission.constants'

const TableComissionFilter = () => {
  const { ctx } = useDashboardContext()
  const [data, setData] = useState <{ dataCommision: any[], order: string, orderBy: string, idSeller: number, dateStart: string, dateEnd: string, loading: boolean }>({ dataCommision: [], order: 'asc', orderBy: '', idSeller: ctx.idSeller, dateStart: ctx.dateStart, dateEnd: ctx.dateEnd, loading: false })
  const subscription$ = sharingInformationService.getSubject()

  const loadData = (_dateStart: string, _dateEnd: string, _idSeller: number) => {
    setData({ ...data, loading: true })
    DboardApi.getCommisionBySeller(_dateStart, _dateEnd, _idSeller).then((response) => {
      setData({ ...data, dataCommision: response.items, dateStart: _dateStart, dateEnd: _dateEnd, idSeller: _idSeller, loading: false })
    }).catch((error) => {
      console.log('TableComissionFilter loadData error:', error)
      setData({ ...data, loading: false })
    })
  }

  const handleRequestSort = (event: any, property : any) => {
    const isAsc = data.orderBy === property && data.order === 'asc'
    setData({ ...data, order: isAsc ? 'desc' : 'asc', orderBy: property })
  }

  const descendingComparator = (a: any, b: any, orderBy : string) => {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const stableSort = (array: any[], comparator: any) => {
    if (array !== undefined) {
      const stabilizedThis = array.map((el, index) => [el, index])
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) {
          return order
        }
        return a[1] - b[1]
      })
      return stabilizedThis.map((el) => el[0])
    } else {
      return []
    }
  }

  const getComparator = (order: string, orderBy : string) => {
    return order === 'desc'
      ? (a: any, b: any) => descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy)
  }

  useMemo(() => {
    loadData(moment(data.dateStart).format(DBOARD_FORMATSTART), moment(data.dateEnd).format(DBOARD_FORMATEND), data.idSeller)
    subscription$.subscribe((info: any) => {
      loadData(moment(info.dateStart).format(DBOARD_FORMATSTART), moment(info.dateEnd).format(DBOARD_FORMATEND), info.idSeller)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  console.log('data.dataCommision => ', data.dataCommision)
  const userProfile = useSelector((appStore: AppStore) => appStore.user)
  return (
    <Card>
      <CardContent>
        <Box sx={{ p: 1, pb: 0 }}>
          <Typography
            variant='h5'
            color='textSecondary'
            sx={{ color: '#000', fontWeight: '500px', fontSize: '1rem' }}
          >
            Comisión por <b>Seller</b>
          </Typography>
          {data.dataCommision &&
            data.dataCommision.length !== 0
            ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'right',
                  justifyContent: 'right'
                }}
              >
                <Button
                  variant='contained'
                  color='success'
                  size='small'
                  onClick={() =>
                    exportTableToExcel(
                      'idTableCommision',
                      'Comisiones Por Seller'
                    )}
                >
                  <Excel className='me-2' /> Exportar
                </Button>
              </Box>
              )
            : (
                ''
              )}
          <Grid container direction='row' spacing={2} my={1}>
            <Grid item xs={12} md={4} lg={4} xl={4}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>Seller</InputLabel>
                <Select
                  value={data.idSeller}
                  label='Seller'
                  onChange={(e) => setData({ ...data, idSeller: parseInt(e.target.value.toString()) })}
                  size='small'
                >

                  {ctx.dataListSeller &&
                        ctx.dataListSeller.map((row : any, i : number) => (
                          <MenuItem value={row.idSeller} key={i + 1}>
                            {row.nameSeller}
                          </MenuItem>
                        ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4} lg={3} xl={3}>
              <DatePicker
                label='Fecha Desde'
                disableFuture
                minDate={DBOARD_MINDATE}
                format='dd-MM-yyyy'
                value={moment(data.dateStart).toDate()}
                onChange={(newValue) => {
                  setData({ ...data, dateStart: moment(newValue).format(DBOARD_FORMATSTART) })
                }}
                slotProps={{
                  textField: {size: "small"}
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3} xl={3}>
              <DatePicker
                label='Fecha Hasta'
                disableFuture
                minDate={moment(data.dateStart).toDate()}
                format='dd-MM-yyyy'
                value={moment(data.dateEnd).toDate()}
                onChange={(newValue) => {
                  setData({ ...data, dateEnd: moment(newValue).format(DBOARD_FORMATEND) })
                }}
                slotProps={{
                  textField: {size: "small"}
                }}
              />
            </Grid>
            <Grid item xs={2} md={2} lg={2} xl={2}>
              <Box>
                <LoadingButton
                  variant='contained'
                  color='primary'
                  onClick={() => loadData(data.dateStart, data.dateEnd, data.idSeller)}
                  disabled={!moment(data.dateEnd).isAfter(data.dateStart)}
                  loading={data.loading}
                >
                  Filtrar
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <TableContainer
            sx={{
              width: '100%',
              overflowX: 'auto',
              position: 'relative',
              display: 'block',
              maxWidth: '100%'
            }}
          >
            <Table
              size='small'
              aria-labelledby='tableTitle'
              style={{
                border: '1'
              }}
            >
              <EnhancedTableHead
                numSelected={0}
                order={data.order}
                orderBy={data.orderBy}
                onRequestSort={handleRequestSort}
                rowCount={data.dataCommision.length}
              />
              {data.dataCommision &&
                data.dataCommision.length !== 0
                ? (
                  <TableBody>
                    {
                    stableSort(data.dataCommision, getComparator(data.order, data.orderBy)).map((row : any, index: number) => {
                      return (
                        <TableRow
                          hover
                          role='checkbox'
                          style={{
                            border: 2
                          }}
                          tabIndex={-1}
                          key={index + 1}
                          selected={false}
                        >
                          <TableCell align='left'>{row.seller}</TableCell>
                          <TableCell align='center'>
                            {row.costo_fijo > 0
                              ? CurrencyFormat(row.costo_fijo)
                              : 0}
                          </TableCell>
                          <TableCell align='center'>
                            {row.monto_venta_bruta > 0
                              ? CurrencyFormat(row.monto_venta_bruta)
                              : 0}
                          </TableCell>
                          {/* <TableCell align='center'>
                            {row.monto_additional_charge > 0
                              ? CurrencyFormat(row.monto_additional_charge)
                              : '$0'}
                          </TableCell>
                          <TableCell align='center'>
                            {row.monto_venta_bruta_menos_additional_charge > 0
                              ? CurrencyFormat(row.monto_venta_bruta_menos_additional_charge)
                              : '$0'}
                          </TableCell> */}
                          <TableCell align='center'>
                            {row.monto_venta_neta > 0
                              ? CurrencyFormat(row.monto_venta_neta)
                              : 0}
                          </TableCell>
                          <TableCell align='center'>
                            {row.commision_venta_neta > 0
                              ? CurrencyFormat(row.commision_venta_neta)
                              : 0}
                          </TableCell>
                          <TableCell align='center'>
                            {row.comision_bodegaje_neta > 0
                              ? CurrencyFormat(row.comision_bodegaje_neta)
                              : 0}
                          </TableCell>
                          <TableCell align='center'>
                            {row.total_comision > 0
                              ? CurrencyFormat(row.total_comision)
                              : 0}
                          </TableCell>
                        </TableRow>
                      )
                    })
}
                  </TableBody>
                  )
                : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Typography
                          variant='h5'
                          color='textSecondary'
                          sx={{ color: '#000', fontWeight: '500px' }}
                        >
                          Sin registro en la fecha seleccionada{' '}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  )}
            </Table>
          </TableContainer>
          {
          userProfile.permisos.some((el:any) => el.function.toUpperCase() === VISTA_SELLERS && el.value.toUpperCase() === 'ALL') &&
            <Box my={5}>
              <TableShippingSeller dateStart={data.dateStart} dateEnd={data.dateEnd} idSeller={data.idSeller} />
            </Box>
          }

          {/** excel commision **/

            <Table
              id='idTableCommision'
              size='small'
              aria-labelledby='tableTitle'
              hidden
              border={1}
            >
              <EnhancedTableHeadExcel
                numSelected={0}
                order={data.order}
                orderBy={data.orderBy}
                onRequestSort={handleRequestSort}
                rowCount={data.dataCommision.length}
                data={data}
              />
              {data.dataCommision &&
              data.dataCommision.length !== 0
                ? (
                  <TableBody>
                    {stableSort(
                      data.dataCommision,
                      getComparator(data.order, data.orderBy)
                    ).map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role='checkbox'
                          style={{
                            border: 1
                          }}
                          tabIndex={-1}
                          key={index + 1}
                          selected={false}
                        >
                          <TableCell align='left' width='150px'>
                            {row.seller}
                          </TableCell>
                          <TableCell align='center'>
                            {row.costo_fijo > 0
                              ? CurrencyFormat(row.costo_fijo)
                              : 0}
                          </TableCell>
                          <TableCell align='center'>
                            {row.monto_venta_bruta_menos_additional_charge > 0
                              ? CurrencyFormat(row.monto_venta_bruta_menos_additional_charge)
                              : '$0'}
                          </TableCell>
                          <TableCell align='center'>
                            {row.monto_venta_neta > 0
                              ? CurrencyFormat(row.monto_venta_neta)
                              : 0}
                          </TableCell>
                          <TableCell align='center'>
                            {row.commision_venta_neta > 0
                              ? CurrencyFormat(row.commision_venta_neta)
                              : 0}
                          </TableCell>
                          <TableCell align='center'>
                            {row.comision_bodegaje_neta > 0
                              ? CurrencyFormat(row.comision_bodegaje_neta)
                              : 0}
                          </TableCell>
                          <TableCell align='center'>
                            {row.total_comision > 0
                              ? CurrencyFormat(row.total_comision)
                              : 0}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                  )
                : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Typography
                          variant='h5'
                          color='textSecondary'
                          sx={{ color: '#000', fontWeight: '500px' }}
                        >
                          Sin registro en la fecha seleccionada{' '}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  )}
            </Table>
}
        </Box>
      </CardContent>
    </Card>
  )
}

export default TableComissionFilter
