import { DBOARD_FORMATSTART } from '@constants/dashboard.constants'
import { DboardApi } from '@services/index'
import moment from 'moment'
import { createContext, useContext, useEffect, useState } from 'react'

type cards = {
  cantidad_devoluciones: any
  cantidad_ventas: any
  lastYear?: any
  total_devoluciones: any
  total_neto: any
  total_unidades: any
  total_ventas: any
}

type totalsales = {
    month: any,
    total_ventas_2024: any,
    total_devoluciones_2024: any,
    total_ventas_2023: any,
    total_devoluciones_2023: any
}

type salesSbuUnit = {
  salesBySbu: any
  salesByUnit: any
}

type returnsSbuUnit = {
  returnsBySbu: any
  returnsByUnit: any
}

type unitSales = {
  month: any,
  unidades_vendidas_2024: any,
  unidades_devueltas_2024: any,
  unidades_vendidas_2023: any,
  unidades_devueltas_2023: any
}

export type salesQuarter = {
  fecha: any,
  total_vendido_current: any
  total_devoluciones_current: any,
  total_neto_2024: any,
  total_vendido_last_year: any,
  total_devoluciones_last_year: any,
  total_neto_2023: any
}

export type salesLastDays = {
  fecha_current: any,
  total_vendido_current: any,
  total_devoluciones_current: any,
  total_neto_2024: any,
  fecha_last_year: any,
  total_vendido_last_year: any,
  total_devoluciones_last_year: any,
  total_neto_2023: any,
}

export type unitQuarter = {
  mes: any,
  unidades_vendidas_current: any,
  unidades_retornadas_current: any,
  total_current: any,
  unidades_vendidas_last_year: any,
  unidades_retornadas_last_year: any,
  total_last_year: any
}

export type unitLastDays = {
  fecha: any,
  unidades_retornadas_current: any,
  unidades_retornadas_last_year: any,
  total_current: any,
  unidades_vendidas_current: any,
  unidades_vendidas_last_year: any,
  total_last_year: any
}

type salesByChannel = {
  name: any,
  ventas: any,
  devoluciones: any,
  total: any
}

type unitsByChannel = {
  name: any,
  ventas: any,
  devoluciones: any,
}

type typeOfReturns = {
  id: any
  name: any
  unidades: any
  total: any
}

type filter = {
  from: string | null
  to: string | null
  channel: {id: number, name: string}[] | null
  sbu: {id: number, description: string}[] | null
  selectedChannel: {id: number, name: string} | null
  selectedSbu: {id: number, description: string} | null
  loading: boolean
  download: boolean
  download2: boolean
  switch: boolean
  weekInitial: number
  weekEnd: number
}

export interface DashboardBsale {
  filter: filter
  lastUpdate: any
  excludeNC: Number[]
  channel: string,
  cards: cards
  totalSales: totalsales[]
  unitSales: unitSales[]
  typeOfReturns: typeOfReturns[]
  salesByChannel: salesByChannel[]
  unitsByChannel: unitsByChannel[]
  salesQuarter: salesQuarter[]
  salesLastDays: salesLastDays[]
  unitQuarter: unitQuarter[]
  unitLastDays: unitLastDays[]
  salesSbuUnit: salesSbuUnit
  returnsSbuUnit: returnsSbuUnit
  salesPerWeek: any
  unitsPerWeek: any
  loading: boolean
}

export type DashboardBsaleContextType = {
    ctx: DashboardBsale;
    saveCtx: (ctx: DashboardBsale) => void;
  };

export const DashboardBsaleContext = createContext<DashboardBsaleContextType | null>(null)

export const DashboardBsaleProvider = ({ children } : any) => {
  const { ctx, setCtx } = useDashboardBsaleProviderStore()
  const saveCtx = (_ctx: DashboardBsale) => {
    setCtx(_ctx)
  }

  useEffect(() => {
    const parameters = {
      from: moment().format(DBOARD_FORMATSTART),
      to: moment().format(DBOARD_FORMATSTART),
      channelId: null,
      sbuId: null,
      weekInitial: 1,
      weekEnd: 12
    }
    const getAllData = async () => {
      setCtx({ ...ctx, loading: true })

      const res = await Promise.all([
        DboardApi.getTotalSales(parameters).then(res => res.data),
        DboardApi.getAllChannels().then(res => res.items.map((ele: any) => { return { id: ele.id, name: ele.name } })),
        DboardApi.getAllSbus().then(res => res.items.map((ele: any) => { return { id: ele.id, description: ele.description } }))
      ])

      setCtx({
        ...res[0],
        filter: {
          ...ctx.filter,
          from: moment().format(DBOARD_FORMATSTART),
          to: moment().format(DBOARD_FORMATSTART),
          channel: [{ id: 0, name: 'Todos' }, ...res[1]],
          sbu: [{ id: 0, description: 'Todos' }, ...res[2]],
          weekInitial: 1,
          weekEnd: 12
        },
        loading: false
      })
    }
    getAllData()
  }, []) //eslint-disable-line

  return <DashboardBsaleContext.Provider value={{ ctx, saveCtx }}>{children}</DashboardBsaleContext.Provider>
}

const useDashboardBsaleProviderStore = () => {
  const [ctx, setCtx] = useState<DashboardBsale>({
    filter: {
      from: null,
      to: null,
      channel: null,
      sbu: null,
      selectedChannel: { id: 0, name: 'Todos' },
      selectedSbu: { id: 0, description: 'Todos' },
      loading: false,
      download: false,
      download2: false,
      switch: false,
      weekInitial: 0,
      weekEnd: 0
    },
    lastUpdate: '',
    excludeNC: [],
    channel: '',
    cards: {
      cantidad_devoluciones: 0,
      cantidad_ventas: 0,
      total_devoluciones: 0,
      total_neto: 0,
      total_unidades: 0,
      total_ventas: 0
    },
    totalSales: [],
    unitSales: [],
    typeOfReturns: [],
    salesByChannel: [],
    unitsByChannel: [],
    salesQuarter: [],
    salesLastDays: [],
    unitQuarter: [],
    unitLastDays: [],
    salesSbuUnit: {
      salesBySbu: 0,
      salesByUnit: 0
    },
    returnsSbuUnit: {
      returnsBySbu: 0,
      returnsByUnit: 0
    },
    salesPerWeek: [],
    unitsPerWeek: [],
    loading: false
  })

  return {
    ctx,
    setCtx
  }
}

export const useDashboardBsaleContext = () => {
  const context = useContext(DashboardBsaleContext)
  if (context === undefined) {
    throw new Error('DashboardBsaleContext must be used within a DashboardBsaleProvider')
  }
  return context
}
