export const VISTA_INDICADORES = 'VISTA_INDICADORES'
export const VISTA_SELLERS = 'VISTA_SELLERS'

export const MENU_DASHBOARD = 'MENU_DASHBOARD'
export const MENU_INDICADORES = 'MENU_INDICADORES'
export const MENU_TRADEIN = 'MENU_TRADEIN'
export const MENU_PHONECHECK = 'MENU_PHONECHECK'
export const MENU_DASHBOARDBSALE = 'MENU_DASHBOARDBSALE'
export const MENU_SEGUROS = 'MENU_SEGUROS'
export const MENU_PREVENTA = 'MENU_PREVENTA'


