import {
  Box,
  Button, Card,
  CardContent, Grid, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Typography
} from '@mui/material'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { DBOARD_FORMATEND, DBOARD_FORMATSMALL, DBOARD_FORMATSTART } from '../../../../constants/dashboard.constants'
import { DboardApi } from '../../../../services'
import { CurrencyFormat } from '../../../../utils'
import { exportTableToExcel } from '../../../../utils/Excel.utility'
import { ReactComponent as Excel } from '@assets/svg/excel.svg'

interface OwnProps {
    dateStart: string
    dateEnd: string
    idSeller: number
}

const TableShippingSeller : FC<OwnProps> = ({ dateStart, dateEnd, idSeller }) => {
  const [data, setData] = useState <{ dataShippingSeller: any[], loading: boolean }>({ dataShippingSeller: [], loading: false })

  const loadData = (_dateStart: string, _dateEnd: string, _idSeller: number) => {
    setData({ ...data, loading: true })
    DboardApi.getShippingSeller(_dateStart, _dateEnd, _idSeller).then((response) => {
      setData({ ...data, dataShippingSeller: response.items, loading: false })
    }).catch((error) => {
      console.log('TotalVentas loadData error:', error)
      setData({ ...data, loading: false })
    })
  }

  useEffect(() => {
    loadData(moment(dateStart).format(DBOARD_FORMATSTART), moment(dateEnd).format(DBOARD_FORMATEND), idSeller)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateStart, dateEnd, idSeller])

  return (
    <div>
      <Card>
        <CardContent>
          <Box sx={{ p: 1, pb: 0 }}>
            <Typography
              variant='h5'
              color='textSecondary'
              sx={{ color: '#000', fontWeight: '500px', fontSize: '1rem' }}
            >
              Total Shipping y Garantia Extendida
            </Typography>

            <Grid
              container
              rowSpacing={2.5}
              direction='row'
              columnSpacing={2.75}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'right',
                    justifyContent: 'right'
                  }}
                >
                  <Button
                    variant='contained'
                    color='success'
                    size='small'
                    onClick={() =>
                      exportTableToExcel(
                        'idShipping',
                        'Total Shipping y Garantia Extendida por seller'
                      )}
                  >
                    <Excel className='me-2' /> Exportar
                  </Button>
                </Box>
                <Box my={1}>
                  <TableContainer>
                    <Table border={1}>
                      <TableHead>
                        <TableRow sx={{ background: 'linear-gradient(to left top, #4676b9, #7c3c91)' }}>
                          <TableCell
                            style={{
                              color: '#ffff',
                              padding: 8,
                              width: 100,
                              textAlign: 'center'
                            }}
                          >
                            Seller
                          </TableCell>
                          <TableCell
                            style={{
                              color: '#ffff',
                              padding: 8,
                              width: 100,
                              textAlign: 'center'
                            }}
                          >
                            Shipping
                          </TableCell>
                          <TableCell
                            style={{
                              color: '#ffff',
                              padding: 8,
                              width: 100,
                              textAlign: 'center'
                            }}
                          >
                            Garantías Extendidas
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.dataShippingSeller &&
                            data.dataShippingSeller.map((r : any, i : number) => (
                              <TableRow key={i}>
                                <TableCell
                                  style={{
                                    padding: 8,
                                    width: 100,
                                    textAlign: 'center'
                                  }}
                                >
                                  {r.name}
                                </TableCell>
                                <TableCell
                                  style={{
                                    padding: 8,
                                    width: 100,
                                    textAlign: 'center'
                                  }}
                                >
                                  {CurrencyFormat(r.shipping)}
                                </TableCell>

                                <TableCell
                                  style={{
                                    padding: 8,
                                    width: 100,
                                    textAlign: 'center'
                                  }}
                                >
                                  {CurrencyFormat(r.warranty)}
                                </TableCell>
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </Grid>

            <Box my={2}>
              {/** excel export */}
              <Table id='idShipping' border={1} hidden>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        padding: 10,
                        textAlign: 'left'
                      }}
                    >
                      Fecha
                    </TableCell>
                    <TableCell colSpan={2}>
                      {moment(dateStart).format(DBOARD_FORMATSMALL)}-{' '}
                      {moment(dateEnd).format(DBOARD_FORMATSMALL)}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        padding: 8,
                        width: 100,
                        textAlign: 'center'
                      }}
                    >
                      Seller
                    </TableCell>
                    <TableCell
                      style={{
                        padding: 8,
                        width: 100,
                        textAlign: 'center'
                      }}
                    >
                      Shipping
                    </TableCell>
                    <TableCell
                      style={{
                        padding: 8,
                        width: 100,
                        textAlign: 'center'
                      }}
                    >
                      Garantías Extendidas
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data.dataShippingSeller &&
                      data.dataShippingSeller.map((r : any, i: number) => (
                        <TableRow key={i}>
                          <TableCell
                            style={{
                              padding: 8,
                              width: 100,
                              textAlign: 'center'
                            }}
                          >
                            {r.name}
                          </TableCell>
                          <TableCell
                            style={{
                              padding: 8,
                              width: 100,
                              textAlign: 'center'
                            }}
                          >
                            {CurrencyFormat(r.shipping)}
                          </TableCell>
                          <TableCell
                            style={{
                              padding: 8,
                              width: 100,
                              textAlign: 'center'
                            }}
                          >
                            {CurrencyFormat(r.warranty)}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  )
}

export default TableShippingSeller
