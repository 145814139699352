import { Card, CardHeader, Switch, Typography } from '@mui/material'
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Rectangle,
  Line,
  ComposedChart,
  ReferenceLine,
  Label,
  LabelList
} from 'recharts'
import { DashboardBsaleContextType, useDashboardBsaleContext } from '../../../context/dashboardBsale.context'
import { CurrencyFormat } from '@utils/Currency.utility'
import { useState } from 'react'
import { average, formatNumber } from '@utils/dashboardBsale'

function SalesByChannel () {
  const { ctx } = useDashboardBsaleContext() as DashboardBsaleContextType
  const [hideREUSE, setHideREUSE] = useState(false)

  function getMaxSales () {
    const data = hideREUSE
      ? ctx.salesByChannel.filter(item => item.name !== ctx.channel)
      : ctx.salesByChannel
    return Math.max(...data.map(item => parseInt(item.ventas)))
  }

  function handleChange () {
    setHideREUSE(!hideREUSE)
  }

  const filteredData = hideREUSE
    ? ctx.salesByChannel.filter(item => item.name !== ctx.channel)
    : ctx.salesByChannel

  const salesAverage = average(filteredData, 'ventas')

  return (
    <Card sx={{ height: 400 }}>
      <CardHeader title={
        <Typography variant='h6'>Ventas por canal
          <Switch checked={hideREUSE} onChange={handleChange} />
        </Typography>
}
      />
      <ResponsiveContainer width='100%' height='80%'>
        <ComposedChart
          width={500}
          height={400}
          data={filteredData}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 50
          }}
        >
          <CartesianGrid stroke='#f5f5f5' />
          <XAxis dataKey='name' />
          <YAxis
            domain={[0, getMaxSales()]}
            tickFormatter={formatNumber}
          />
          <Tooltip
            formatter={(value: any, name: any, props: any) => CurrencyFormat(value)}
          />
          <Legend
            payload={[
              { value: 'Total', type: 'circle', id: 'total', color: '#413ea0' },
              { value: 'Devoluciones', type: 'line', id: 'devoluciones', color: '#fca258' }
            ]}
          />
          {salesAverage !== null && (
            <ReferenceLine y={salesAverage} stroke='#cccccc' strokeDasharray='3 3'>
              <Label value={`Avg: ${formatNumber(salesAverage)}`} position='insideLeft' />
            </ReferenceLine>
          )}
          <Bar dataKey='ventas' name='ventas' fill='#0473c8' barSize={-4} legendType='none' display='none' />
          <Line type='monotone' dataKey='devoluciones' name='Devoluciones' stroke='#fca258' />
          <Bar dataKey='total' name='Total' fill='#413ea0' activeBar={<Rectangle fill='#7b76f3' stroke='blue' />} barSize={30} legendType='circle'>
            <LabelList dataKey='total' position='top' formatter={(value: any) => formatNumber(value)} fontSize={12} />
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </Card>
  )
}

export default SalesByChannel
