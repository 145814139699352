import Typography from '@mui/material/Typography'
import TableTradeInSales from './components/Tables/TableSales'
import { TIProvider } from './context/sales.context'

const TradeInSales = () => {
  return (

    <TIProvider>
      <Typography
        variant='h1'
        color='textSecondary'
        className='mb-4'
        sx={{ color: '#000', fontWeight: '500px', fontSize: '1rem' }}
      >
        <b>Trade-In Sales</b>
      </Typography>
      <TableTradeInSales />
    </TIProvider>

  )
}

export default TradeInSales
