import { Card, Modal } from '@mui/material'
import { COLORS, renderCustomizedLabel } from '@utils/dashboardBsale'
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts'

export default function PieModal ({ modal, setModal }: any) {
  console.log(modal)

  const total = modal.data && modal.data.reduce((acc: any, ele: any) => ele.value + acc, 0)
  const data = modal.data.slice(8)
  const prueba = data && data.map((ele: any, index: number) => {
    return {
      name: ele.name,
      value: (ele.value * 100) / total
    }
  })
  console.log(total)
  console.log(data)
  console.log(prueba)

  return (
    <div>
      <Modal
        open={modal.open}
        onClose={() => setModal({ open: false, data: '' })}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Card sx={{ height: 350, width: 800 }}>
          <ResponsiveContainer width='100%' height='100%'>
            <PieChart>
              <Pie
                data={prueba}
                cx='50%'
                cy='50%'
                labelLine={false}
                label={(props) => renderCustomizedLabel({ ...props, customProp: 'modal' })}
                outerRadius={150}
                fill='#8884d8'
                dataKey='value'
              >
                {data && data?.map((entry: any, index: any) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Legend align='right' verticalAlign='middle' layout='vertical' iconType='circle' />
            </PieChart>
          </ResponsiveContainer>
        </Card>
      </Modal>
    </div>
  )
}
