import { Grid } from '@mui/material'

import GeneralFilter from './components/GeneralFilter'
import GraficosTotalVentas from './components/GraficosTotalVentas'
import GraficoVentaSbuLinear from './components/GraficoVentaSbuLinear'
import { TableComissionFilter, TableComissionFilterNoSeller } from './components/TableComissionFilter'

import TablePedidos from './components/TablePedidos/TablePedidos'
import TablePedidosPorRegiones from './components/TablePedidosPorRegiones'
import TablePlanSeller from './components/TablePlanSeller'
// import TotalVentas from './components/TotalVentas'
import { DashboardProvider } from './context/dashboard.context'

import ComparacionVentasSeller from './components/ComparacionVentasSeller'
import { useSelector } from 'react-redux'
import { AppStore } from '../../store/store'
import { VISTA_INDICADORES, VISTA_SELLERS } from '@constants/permission.constants'
import TableDiscounts from './components/TableDiscounts/TableDiscounts'
import TableRefunds from './components/TableRefunds/TableRefunds'
import TableFulfillment from './components/TableFulfillment/TableFulfillment'

const DashboardPage = () => {
  const userProfile = useSelector((appStore: AppStore) => appStore.user)

  return (
    <DashboardProvider>
      <Grid container rowSpacing={2.5} columnSpacing={2.75}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <GeneralFilter />
        </Grid>
        {/* <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <TotalVentas />
        </Grid> */}

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <GraficosTotalVentas />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
          {
            userProfile.permisos.some((el:any) => el.function.toUpperCase() === VISTA_INDICADORES)
              ? <TableComissionFilterNoSeller />
              : <TableComissionFilter />
          }
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <TablePlanSeller />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TablePedidos />
        </Grid>
        {
          userProfile.permisos.some((el:any) => el.function.toUpperCase() === VISTA_SELLERS && el.value.toUpperCase() === 'ALL') &&
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TableFulfillment />
            </Grid>
        }
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          <TableDiscounts />
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
          <TableRefunds />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ComparacionVentasSeller />
        </Grid>
        {
          userProfile.permisos.some((el:any) => el.function.toUpperCase() === VISTA_SELLERS && el.value.toUpperCase() === 'ALL') &&
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <GraficoVentaSbuLinear />
            </Grid>
        }
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TablePedidosPorRegiones />
        </Grid>
      </Grid>
    </DashboardProvider>
  )
}

export default DashboardPage
