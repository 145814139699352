import axios from 'axios'

const headers = {
  'Content-Type': 'application/json; charset=utf-8',
  Accept: 'text/json'
}

export const DboardApi = {
  getUserProfile: async (_email: string, _poolId: string) => {
    const url = `${process.env.REACT_APP_SERVICE_USER_PROFILE}getByEmailAndPoolId/${_email}/${_poolId}`
    return axios(url, { method: 'GET', headers }).then((res: any) => res.data)
  },
  getSellers: async (_idSeller : number) => {
    const url = `${process.env.REACT_APP_CRUD_SELLER}getListSeller/${_idSeller}`
    return await axios(url, { method: 'GET', headers }).then((res: any) => res.data)
  },
  getTotalVentas: async (_dateInit: string, _dateEnd: string, _idSeller : number) => {
    const url = `${process.env.REACT_APP_SERVICE_TOTAL_SALES}getTotalSales/${_dateInit}/${_dateEnd}/${_idSeller}`
    return await axios(url, { method: 'GET', headers }).then((res: any) => res.data)
  },
  getAmountSaleReversso: async (_dateInit: string, _dateEnd: string, _idSeller : number) => {
    const url = `${process.env.REACT_APP_SERVICE_AMOUNT_REVERSSO}getAmountBySaleReversso/${_dateInit}/${_dateEnd}/${_idSeller}`
    return await axios(url, { method: 'GET', headers }).then((res: any) => res.data)
  },
  getReverssoBySeller: async (_dateInit: string, _dateEnd: string, _idSeller : number) => {
    const url = `${process.env.REACT_APP_CRUD_REVERSSO}getReverssoBySeller/${_dateInit}/${_dateEnd}/${_idSeller}`
    return await axios(url, { method: 'GET', headers }).then((res: any) => res.data)
  },
  getCommisionBySeller: async (_dateInit: string, _dateEnd: string, _idSeller : number) => {
    const url = `${process.env.REACT_APP_SERVICE_COMISSION}getComissionsSeller/${_dateInit}/${_dateEnd}/${_idSeller}`
    return axios(url, { method: 'GET', headers }).then((res: any) => res.data)
  },
  getShippingSeller: async (_dateInit: string, _dateEnd: string, _idSeller : number) => {
    const url = `${process.env.REACT_APP_SERVICE_SHIPPING_SELLER}getShippingSeller/${_dateInit}/${_dateEnd}/${_idSeller}`
    return axios(url, { method: 'GET', headers }).then((res: any) => res.data)
  },
  getPlanSeller: async (_idSeller : number) => {
    const url = `${process.env.REACT_APP_CRUD_PLAN}getPlanSeller/${_idSeller}`
    return axios(url, { method: 'GET', headers }).then((res: any) => res.data)
  },
  getOrdersClients: async (data: any) => {
    const url = `${process.env.REACT_APP_SERVICE_ORDERS_CLIENTS}getListOrdersClient`
    return axios(url, { method: 'POST', data, headers }).then((res: any) => res.data)
  },
  getFulfillments: async (data: any) => {
    const url = `${process.env.REACT_APP_CRUD_SHIPPING}getFulfillments`
    return axios(url, { method: 'POST', data, headers }).then((res: any) => res.data)
  },
  getDiscountsBySeller: async (data: any) => {
    const url = `${process.env.REACT_APP_SERVICE_DISCOUNTS}getDiscountsBySeller`
    return axios(url, { method: 'POST', data, headers }).then((res: any) => res.data)
  },
  getDiscountsDetail: async (data: any) => {
    const url = `${process.env.REACT_APP_SERVICE_DISCOUNTS}getDiscountsDetail`
    return axios(url, { method: 'POST', data, headers }).then((res: any) => res.data)
  },
  getRefundNote: async (data: any) => {
    const url = `${process.env.REACT_APP_SERVICE_DISCOUNTS}getRefundNote`
    return axios(url, { method: 'POST', data, headers }).then((res: any) => res.data)
  },
  getComparisonSeller: async (_dateInit: string, _dateEnd: string, _dateInitCom: string, _dateEndCom: string, _idSeller : number) => {
    const url = `${process.env.REACT_APP_SERVICE_COMPARISON_SALE}getComparisonSeller/${_dateInit}/${_dateEnd}/${_dateInitCom}/${_dateEndCom}/${_idSeller}`
    return axios(url, { method: 'GET', headers }).then((res: any) => res.data)
  },
  getSalesBySBU: async (_dateInit: string, _dateEnd: string) => {
    const url = `${process.env.REACT_APP_SERVICE_SBU_QUANTITY}getSbuWithQuantity/${_dateInit}/${_dateEnd}`
    return axios(url, { method: 'GET', headers }).then((res: any) => res.data)
  },
  getOrdersZone: async (_dateInit: string, _dateEnd: string, _idSeller : number) => {
    const url = `${process.env.REACT_APP_SERVICE_UNIT_ZONES}getUnitZone/${_dateInit}/${_dateEnd}/${_idSeller}`
    return axios(url, { method: 'GET', headers }).then((res: any) => res.data)
  },
  getConversionIndicators: async (_createdSince: string, _createdUntil: string) => {
    const url = `${process.env.REACT_APP_SERVICE_CONVERSION_INDICATORS}getByRangeDate/${_createdSince}/${_createdUntil}`
    return await axios(url, { method: 'GET', headers }).then((res: any) => res.data)
  },
  getTradeinOrder: async () => {
    const url = `${process.env.REACT_APP_SERVICE_TRADEIN}getTradeinOrder`
    return await axios(url, { method: 'GET', headers }).then((res: any) => res.data)
  },
  getTradeinHistoryLog: async (_createdSince: string, _createdUntil: string) => {
    const url = `${process.env.REACT_APP_SERVICE_TRADEIN}getHistoryLog/${_createdSince}/${_createdUntil}`
    return await axios(url, { method: 'POST', headers }).then((res: any) => res.data)
  },
  getStationID: async () => {
    const url = `${process.env.REACT_APP_SERVICE_DASHBOARD_PHONECHECK}getStationID`
    return axios(url, { method: 'GET', headers }).then((res: any) => res.data.data)
  },
  getAllLote: async () => {
    const url = `${process.env.REACT_APP_SERVICE_DASHBOARD_PHONECHECK}getAllLote`
    return axios(url, { method: 'GET', headers }).then((res: any) => res.data.data)
  },
  getAllVendor: async () => {
    const url = `${process.env.REACT_APP_SERVICE_DASHBOARD_PHONECHECK}getAllVendor`
    return axios(url, { method: 'GET', headers }).then((res: any) => res.data.data)
  },
  getInvoiceNo: async () => {
    const url = `${process.env.REACT_APP_SERVICE_DASHBOARD_PHONECHECK}getInvoiceNo`
    return axios(url, { method: 'GET', headers }).then((res: any) => res.data.data)
  },
  getDataByDate: async (from: String, to: String, type: string) => {
    console.log(from, to, type)
    const url = `${process.env.REACT_APP_SERVICE_DASHBOARD_PHONECHECK}getDataByDate/${from}/${to}/${type}`
    console.log(url)

    return axios(url, { method: 'GET', headers }).then((res: any) => res.data)
  },
  getDataByUser: async (data: any) => {
    console.log(data)
    const url = `${process.env.REACT_APP_SERVICE_DASHBOARD_PHONECHECK}getDataByUser`
    // console.log(url)
    return axios(url, { method: 'POST', data, headers }).then((res: any) => res.data)
  },
  getDataByVendor: async (data: any) => {
    console.log(data)
    const url = `${process.env.REACT_APP_SERVICE_DASHBOARD_PHONECHECK}getDataByVendor`
    // console.log(url)
    return axios(url, { method: 'POST', data, headers }).then((res: any) => res.data)
  },
  getDataByInvoice: async (data: any) => {
    console.log(data)
    const url = `${process.env.REACT_APP_SERVICE_DASHBOARD_PHONECHECK}getDataByInvoice`
    // console.log(url)
    return axios(url, { method: 'POST', data, headers }).then((res: any) => res.data)
  },
  getDataByLote: async (data: any) => {
    console.log(data)
    const url = `${process.env.REACT_APP_SERVICE_DASHBOARD_PHONECHECK}getDataByLote`
    // console.log(url)
    return axios(url, { method: 'POST', data, headers }).then((res: any) => res.data)
  },
  getDataByLotePerDay: async (data: any) => {
    console.log(data)
    const url = `${process.env.REACT_APP_SERVICE_DASHBOARD_PHONECHECK}getDataByLotePerDay`
    // console.log(url)
    return axios(url, { method: 'POST', data, headers }).then((res: any) => res.data.data)
  },
  getDataByCountry: async (data: any) => {
    console.log(data)
    const url = `${process.env.REACT_APP_SERVICE_DASHBOARD_PHONECHECK}getDataByCountry`
    // console.log(url)
    return axios(url, { method: 'POST', data, headers }).then((res: any) => res.data)
  },
  getDataByImei: async (data: any) => {
    console.log(data)
    const url = `${process.env.REACT_APP_SERVICE_DASHBOARD_PHONECHECK}getDataByImei`
    // console.log(url)
    return axios(url, { method: 'POST', data, headers }).then((res: any) => res.data)
  },
  getDataByDateExcel: async (data: any) => {
    console.log(data)
    const url = `${process.env.REACT_APP_SERVICE_DASHBOARD_PHONECHECK}getDataByDateExcel`
    // console.log(url)
    return axios(url, { method: 'POST', data, headers }).then((res: any) => res.data)
  },
  getPartiallyRefunded: async (init: string, end: string) => {
    const url = `${process.env.REACT_APP_CRUD_REVERSSO}getPartiallyRefunded/${init}/${end}`
    return axios(url, { method: 'GET', headers }).then((res: any) => res.data)
  },
  getAllPreventa: async () => {
    const url = `${process.env.REACT_APP_CRUD_PREVENTA}getAll/`
    return axios(url, { method: 'GET', headers }).then((res: any) => res.data)
  },
  getAllChannels: async () => {
    const url = `${process.env.REACT_APP_CRUD_CHANNEL}GetAll/`
    return axios(url, { method: 'GET', headers }).then((res: any) => res.data)
  },
  getAllSbus: async () => {
    const url = `${process.env.REACT_APP_CRUD_SBUS}GetAll/`
    return axios(url, { method: 'GET', headers }).then((res: any) => res.data)
  },
  getTotalSales: async (data: any) => {
    const url = `${process.env.REACT_APP_SERVICE_SALES_BSALE}getTotalSales/`
    return axios(url, { method: 'POST', data, headers }).then((res: any) => res.data)
  },
  downloadExcel: async (data: any) => {
    const url = `${process.env.REACT_APP_SERVICE_SALES_BSALE}getDataForExcel/`
    return axios(url, { method: 'POST', data, headers }).then((res: any) => res.data)
  },
  getGoals: async () => {
    const url = `${process.env.REACT_APP_SERVICE_SALES_BSALE}getGoals/`
    return axios(url, { method: 'GET', headers }).then((res: any) => res.data)
  },
  updateGoals: async (data: any) => {
    const url = `${process.env.REACT_APP_SERVICE_SALES_BSALE}updateGoals/`
    return axios(url, { method: 'POST', data, headers }).then((res: any) => res.data)
  },
  getSales: async () => {
    const url = `${process.env.REACT_APP_SERVICE_SALES_BSALE}getSales/`
    return axios(url, { method: 'GET', headers }).then((res: any) => res.data)
  },
  getListPolicies: async () => {
    const url = `${process.env.REACT_APP_CRUD_INSURAMA}getPolicy`
    return axios(url, { method: 'POST', headers }).then((res: any) => res.data)
  },
  getListLog: async () => {
    const url = `${process.env.REACT_APP_CRUD_INSURAMA}getLog`
    return axios(url, { method: 'POST', headers }).then((res: any) => res.data)
  },
  getAllCoticed: async () => {
    const url = `${process.env.REACT_APP_CRUD_INSURAMA}getAllCoticed`
    return axios(url, { method: 'GET', headers }).then((res: any) => res.data)
  },
  getCoticedInsurances: async () => {
    const url = `${process.env.REACT_APP_CRUD_INSURAMA}getCoticedInsurances`
    return axios(url, { method: 'GET', headers }).then((res: any) => res.data)
  }
}
