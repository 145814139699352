import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer, TablePagination,
  TableRow,
  Typography
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { DBOARD_FORMATEND, DBOARD_FORMATSMALL, DBOARD_FORMATSTART, DBOARD_MINDATE } from '../../../../constants/dashboard.constants'
import { DboardApi } from '../../../../services'
import { CurrencyFormat, sharingInformationService } from '../../../../utils'
import { exportTableToExcel } from '../../../../utils/Excel.utility'
import { useDashboardContext } from '../../context/dashboard.context'
import EnhancedTableHead from './EnhancedTableHead'
import EnhancedTableHeadExcel from './EnhancedTableHeadExcel'
import { ReactComponent as Excel } from '@assets/svg/excel.svg'

const TablePedidos = () => {
  const { ctx } = useDashboardContext()
  const [data, setData] = useState <{ dataGetListOrders: any[], nameSeller: any, totalMonto: number, totalMontoFinal: number, totalDevoluciones: number, totalMontoDevoluciones: number, totalPedidos: number, page: number, rowsPerPage: number, order: string, orderBy: string, idSeller: number, dateStart: string, dateEnd: string, loading: boolean }>({ dataGetListOrders: [], nameSeller: '', totalMontoFinal: 0, totalMonto: 0, totalDevoluciones: 0, totalMontoDevoluciones: 0, totalPedidos: 0, page: 0, rowsPerPage: 10, order: 'asc', orderBy: '', idSeller: ctx.idSeller, dateStart: ctx.dateStart, dateEnd: ctx.dateEnd, loading: false })
  const subscription$ = sharingInformationService.getSubject()

  const handleChangePage = (event: any, newPage : number) => {
    setData({ ...data, page: newPage })
  }

  const handleChangeRowsPerPage = (event: any) => {
    setData({ ...data, rowsPerPage: parseInt(event.target.value, 10), page: 0 })
  }

  const handleRequestSort = (event: any, property : any) => {
    const isAsc = data.orderBy === property && data.order === 'asc'
    setData({ ...data, order: isAsc ? 'desc' : 'asc', orderBy: property })
  }

  const stableSort = (array: any[], comparator: any) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  const getComparator = (order: string, orderBy : string) => {
    return order === 'desc'
      ? (a: any, b: any) => descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy)
  }

  const descendingComparator = (a: any, b: any, orderBy : string) => {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const loadData = (_dateStart: string, _dateEnd: string, _idSeller: number) => {
    setData({ ...data, loading: true })
    const datos = {
      date_init: _dateStart,
      date_end: _dateEnd,
      id_seller: _idSeller
    }
    DboardApi.getOrdersClients(datos).then((response) => {
      console.log('response =>', response)
      const totalMonto = response.items && response.items.map((row: any) => row.total_amount).reduce((prev :any, curr: any) => prev + curr, 0)
      const totalPedidos = response.items && response.items.length
      const totalDevoluciones = response.items && response.items.filter((row: any) => row.status !== '').length
      const totalMontoDevoluciones = response.items && response.items.filter((row: any) => row.status !== '').map((row: any) => row.total_amount).reduce((prev :any, curr: any) => prev + curr, 0)
      const totalMontoFinal = totalMonto - totalMontoDevoluciones
      setData({ ...data, dataGetListOrders: response.items, totalMonto, totalPedidos, totalDevoluciones, totalMontoFinal, totalMontoDevoluciones, dateStart: _dateStart, dateEnd: _dateEnd, idSeller: _idSeller, loading: false })
    }).catch((error) => {
      console.log('TotalVentas loadData error:', error)
      setData({ ...data, loading: false })
    })
  }

  const handleExportExcel = (IdTable: string) => {
    if (data.idSeller === 0) {
      alert('Debes de seleccionar un seller')
      return
    }
    exportTableToExcel(IdTable, 'Unidades Vendidas por Seller')
  }

  useMemo(() => {
    loadData(moment(data.dateStart).format(DBOARD_FORMATSTART), moment(data.dateEnd).format(DBOARD_FORMATEND), data.idSeller)
    subscription$.subscribe((info: any) => {
      loadData(moment(info.dateStart).format(DBOARD_FORMATSTART), moment(info.dateEnd).format(DBOARD_FORMATEND), info.idSeller)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  console.log('data.dataGetListOrders', data.dataGetListOrders)
  return (
    <Card>
      <CardContent>
        <Box sx={{ p: 1, pb: 0 }}>
          <Typography
            variant='h5'
            color='textSecondary'
            sx={{ color: '#000', fontWeight: '500px', fontSize: '1rem' }}
          >
            Unidades vendidas por <b>Seller</b>
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'right',
              justifyContent: 'right'
            }}
          >
            <Button
              variant='contained'
              color='success'
              size='small'
              onClick={() => handleExportExcel('IdPedidos')}
            >
              <Excel className='me-2' /> Exportar
            </Button>
          </Box>
          <Grid container direction='row' spacing={2} my={1}>
            <Grid item xs={12} md={4} lg={4} xl={4}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>Seller</InputLabel>
                <Select
                  value={data.idSeller}
                  label='Seller'
                  onChange={(e) => setData({ ...data, idSeller: parseInt(e.target.value.toString()), nameSeller: e.target.name })}
                  size='small'
                >

                  {ctx.dataListSeller &&
                        ctx.dataListSeller.map((row : any, i : number) => (
                          <MenuItem value={row.idSeller} key={i + 1}>
                            {row.nameSeller}
                          </MenuItem>
                        ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4} lg={3} xl={3}>
              <DatePicker
                label='Fecha Desde'
                disableFuture
                minDate={DBOARD_MINDATE}
                format='dd-MM-yyyy'
                value={moment(data.dateStart).toDate()}
                onChange={(newValue) => {
                  setData({ ...data, dateStart: moment(newValue).format(DBOARD_FORMATSTART) })
                }}
                slotProps={{
                  textField: {size: "small", fullWidth: true}
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3} xl={3}>
              <DatePicker
                label='Fecha Hasta'
                disableFuture
                minDate={moment(data.dateStart).toDate()}
                format='dd-MM-yyyy'
                value={moment(data.dateEnd).toDate()}
                onChange={(newValue) => {
                  setData({ ...data, dateEnd: moment(newValue).format(DBOARD_FORMATEND) })
                }}
                slotProps={{
                  textField: {size: "small", fullWidth: true}
                }}
              />
            </Grid>
            <Grid item xs={2} md={2} lg={2} xl={2}>
              <Box>
                <LoadingButton
                  variant='contained'
                  color='primary'
                  onClick={() => loadData(data.dateStart, data.dateEnd, data.idSeller)}
                  disabled={!moment(data.dateEnd).isAfter(data.dateStart)}
                  loading={data.loading}
                >
                  Filtrar
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <TableContainer sx={{ maxHeight: '450px' }}>
            <Table size='small' border={1} stickyHeader>
              <EnhancedTableHead
                numSelected={0}
                order={data.order}
                orderBy={data.orderBy}
                onRequestSort={handleRequestSort}
                rowCount={data.dataGetListOrders.length}
              />
              {data.dataGetListOrders &&
                data.dataGetListOrders.length !== 0
                ? (
                  <TableBody>
                    {stableSort(
                      data.dataGetListOrders,
                      getComparator(data.order, data.orderBy)
                    )
                      .slice(
                        data.page * data.rowsPerPage,
                        data.page * data.rowsPerPage + data.rowsPerPage
                      )
                      .filter(e => e.status !== 'voided')
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`
                        return (
                          <TableRow
                            hover
                            role='checkbox'
                            tabIndex={-1}
                            key={index + 1}
                            selected={false}
                          >
                            <TableCell
                              component='td'
                              id={labelId}
                              scope='row'
                              align='left'
                              width='60px'
                            >
                              {row.id_order_platform}
                            </TableCell>
                            <TableCell align='center' width='50px'>
                              {moment(row.saleDate).format(
                                'YYYY-MM-DD hh:mm:ss'
                              )}
                            </TableCell>
                            <TableCell align='center' width='50px'>
                              {row.discount_val !== 0 ? CurrencyFormat(row.discount_val) : '$0'}
                            </TableCell>
                            <TableCell align='center' width='50px'>
                              {row.discount_code}
                            </TableCell>
                            <TableCell align='center' width='50px'>
                              {CurrencyFormat(row.total_amount)}
                            </TableCell>
                            <TableCell align='center' width='50px'>
                              {CurrencyFormat(row.additional_charge)}
                            </TableCell>
                            <TableCell align='center' width='50px'>
                              {CurrencyFormat(row.totalNeto)}
                            </TableCell>
                            <TableCell align='center' width='50px'>
                              {CurrencyFormat(row.commisionNeta)}
                            </TableCell>
                            <TableCell align='center' width='30px'>
                              {CurrencyFormat(row.invoiceNeto)}
                            </TableCell>
                            <TableCell align='center' width='50px'>
                              {CurrencyFormat(row.invoiceBruta)}
                            </TableCell>
                            {/* <TableCell align="center" width="50px">
                              {row.unitProducts}
                            </TableCell> */}
                            <TableCell align='left' width='50px'>
                              {row.descriptiosProducts}
                            </TableCell>
                            <TableCell align='left' width='50px'>
                              {row.sku}
                            </TableCell>
                            <TableCell align='center' width='150px'>
                              {row.nameSeller}
                            </TableCell>
                            <TableCell align='center' width='150px'>
                              {row.name}
                            </TableCell>
                            <TableCell align='center' width='150px'>
                              {row.email}
                            </TableCell>
                            <TableCell align='center' width='150px'>
                              {(row.refunded_note && row.refunded_note.trim() !== undefined) || (row.refunded_note !== null) ? row.refunded_note : ''}
                            </TableCell>
                            {/*                             <TableCell align='center' width='150px'>
                              {(row.status === 'pending') ? 'Pendiente' : (row.status === 'voided') ? 'Terminada' : ''}
                            </TableCell> */}
                          </TableRow>
                        )
                      })}
                  </TableBody>
                  )
                : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Typography
                          variant='h5'
                          color='textSecondary'
                          sx={{ color: '#000', fontWeight: '500px' }}
                        >
                          Sin registro en la fecha seleccionada{' '}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component='div'
            count={data.dataGetListOrders.length}
            rowsPerPage={data.rowsPerPage}
            page={data.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
        {/**  table excel **/
          <Box id='IdPedidos' hidden>
            <p style={{ fontSize: '20px' }}>Unidades vendidas por <strong>{data.dataGetListOrders && data.dataGetListOrders[0]?.nameSeller}</strong></p>

            <Table size='small' border={1} style={{ textAlign: 'right', width: '600px' }}>
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={3}
                    style={{
                      background: '#202123',
                      color: '#ffff',
                      padding: 10
                    }}
                  >
                    Modalidad
                  </TableCell>
                  <TableCell colSpan={3} style={{ textAlign: 'right' }}>
                    FULFILLMENT REUSE
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={3}
                    style={{
                      background: '#202123',
                      color: '#ffff',
                      padding: 10,
                      fontWeight: '450px'
                    }}
                  >
                    Marca
                  </TableCell>
                  <TableCell colSpan={3} style={{ textAlign: 'right' }}>
                    {data.dataGetListOrders && data.dataGetListOrders[0]?.nameSeller}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={3}
                    style={{
                      background: '#202123',
                      color: '#ffff',
                      padding: 10,
                      fontWeight: '450px'
                    }}
                  >
                    Fecha Reporte
                  </TableCell>
                  <TableCell colSpan={3} style={{ textAlign: 'right' }}>
                    {moment(data.dateStart).format(DBOARD_FORMATSMALL)} -{' '}
                    {moment(data.dateEnd).format(DBOARD_FORMATSMALL)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table size='small'>
              <TableBody>
                <TableRow>
                  <TableCell style={{
                    border: 0,
                    padding: '20px 0'
                  }}
                  />

                </TableRow>
              </TableBody>
            </Table>
            <Table size='small' border={1}>
              <EnhancedTableHeadExcel
                numSelected={0}
                order={data.order}
                orderBy={data.orderBy}
                onRequestSort={handleRequestSort}
                rowCount={data.dataGetListOrders.length}
              />
              {data.dataGetListOrders && data.dataGetListOrders.length > 0
                ? (
                  <TableBody>
                    {data.dataGetListOrders.map((row, index) => {
                      const evenStyle = { background: '' }
                      if (index % 2 === 0) {
                        evenStyle.background = '#f7f7ff'
                      }
                      return (
                        <TableRow hover role='checkbox' tabIndex={-1} key={index + 1} style={evenStyle}>
                          <TableCell
                            component='td'
                            scope='row'
                            align='left'
                            width='60px'
                          >
                            {row.id_order_platform}
                          </TableCell>
                          <TableCell align='center' width='50px'>
                            {row.tipo}
                          </TableCell>
                          <TableCell align='center' width='50px'>
                            {moment(row.saleDate).format('YYYY-MM-DD hh:mm:ss')}
                          </TableCell>
                          <TableCell align='center' width='50px'>
                            {row.discount_val !== 0 ? CurrencyFormat(row.discount_val) : '$0'}
                          </TableCell>
                          <TableCell align='center' width='50px'>
                            {row.discount_code}
                          </TableCell>
                          <TableCell align='center' width='50px'>
                            {CurrencyFormat(row.total_amount)}
                          </TableCell>
                          <TableCell align='center' width='50px'>
                            {CurrencyFormat(row.additional_charge)}
                          </TableCell>
                          <TableCell align='center' width='50px'>
                            {CurrencyFormat(row.totalNeto)}
                          </TableCell>
                          <TableCell align='center' width='50px'>
                            {data.dataGetListOrders && parseInt((data.dataGetListOrders[0].commision * 100).toString())} %
                          </TableCell>
                          <TableCell align='center' width='50px'>
                            {CurrencyFormat(row.commisionNeta)}
                          </TableCell>
                          <TableCell align='center' width='30px'>
                            {CurrencyFormat(row.invoiceNeto)}
                          </TableCell>
                          <TableCell align='center' width='50px'>
                            {CurrencyFormat(row.invoiceBruta)}
                          </TableCell>
                          <TableCell align='left' width='50px'>
                            {row.descriptiosProducts}
                          </TableCell>
                          <TableCell align='left' width='50px'>
                            {row.sku}
                          </TableCell>
                          <TableCell align='center' width='150px'>
                            {row.name}
                          </TableCell>
                          <TableCell align='center' width='150px'>
                            {row.email}
                          </TableCell>
                          <TableCell align='left' width='50px'>
                            {(row.refunded_note && row.refunded_note.trim() !== undefined) || (row.refunded_note !== null) ? row.refunded_note : ''}
                          </TableCell>
                          {/*                           <TableCell align='center' width='50px'>
                            {(row.status === 'pending') ? 'Pendiente' : (row.status === 'voided') ? 'Terminada' : ''}
                          </TableCell> */}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                  )
                : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Typography
                          variant='h5'
                          color='textSecondary'
                          sx={{ color: '#000', fontWeight: '500px' }}
                        >
                          Sin registro en la fecha seleccionada
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  )}
            </Table>
          </Box>
        }
      </CardContent>
    </Card>
  )
}

export default TablePedidos
