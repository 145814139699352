import { Box, Card, CardContent, Typography } from '@mui/material'
import { DboardApi } from '@services/index'
import { useEffect, useState } from 'react'
import { state, weeks } from '@models/goalsBsale'
import { goals } from '@models/salesBsale'
import SalesTable from './components/SalesTable'

const SalesBsale = () => {
  const [state, setState] = useState<state>(
    {
      weeks: [],
      yearGoal: 0,
      days: 364,
      monthsGoal: [],
      loading: false,
      saving: false,
      totalUsd: 0,
      currency: '',
      canSave: false
    })

  console.log('state => ', state)

  useEffect(() => {
    const getData = async () => {
      setState({ ...state, loading: true })
      try {
        const goals: goals = await DboardApi.getSales().then(res => res.data)
        console.log('goals => ', goals)

        const organizedWeeks = goals.days.reduce((acc: weeks[], ele) => {
          const obj = acc.find(ob => ob.week === ele.week)
          if (obj) {
            obj.days.push(ele)
          } else {
            const weekGoal = goals.weeks.find(el => el.week === ele.week)
            const newWeek: any = {
              week: ele.week,
              days: [ele],
              sales: weekGoal ? weekGoal.sales : 0,
              percentage: weekGoal?.percentage
            }
            if (weekGoal !== undefined) {
              newWeek.weeklyGoal = weekGoal.goal
            }
            acc.push(newWeek)
          }
          return acc
        }, [])

        setState(prevState => ({
          ...prevState,
          yearGoal: goals.year.length === 0 ? 0 : goals.year[0].total,
          estimatedYear: goals.estimatedYear,
          monthsGoal: goals.months,
          weeks: organizedWeeks,
          loading: false
        }))
      } catch (error) {
        console.log(error)
        setState(prevState => ({
          ...prevState,
          loading: false
        }))
      }
    }
    getData()
  }, []) // eslint-disable-line

  return (
    <>
      <Typography
        variant='h1'
        color='textSecondary'
        className='mb-4'
        sx={{ color: '#000', fontWeight: '500px', fontSize: '1rem' }}
      >
        <b>Ventas Bsale</b>
      </Typography>
      <Box my={2}>
        <Card>
          <CardContent>

            <SalesTable state={state} setState={setState} />

          </CardContent>
        </Card>
      </Box>

    </>
  )
}

export default SalesBsale
