import React, { useState } from 'react'
import { Grid, Card, Typography, Box, Button } from '@mui/material'
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts'
import { COLORS, downloadExcelPie, recortar, renderCustomizedLabel } from '@utils/dashboardBsale'
import { ReactComponent as Excel } from '@assets/svg/excel.svg'
import PieModal from './PieModal'
import { DashboardBsaleContextType, useDashboardBsaleContext } from '@pages/DashboardBsale/context/dashboardBsale.context'

function SalesSbuUnit () {
  const { ctx } = useDashboardBsaleContext() as DashboardBsaleContextType
  const [modal, setModal] = useState({ open: false, data: '' })
  // const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const dataSbu = ctx.salesSbuUnit.salesBySbu?.map((ele: any, index: number) => {
    return {
      name: `${ele.sbu_description} (${ele.total_ventas})`,
      value: Number(ele.total_ventas)
    }
  })

  const sbu = dataSbu && dataSbu.slice(0, 8)

  if (sbu && sbu.length > 7 && dataSbu.length > 0) {
    const value = dataSbu.slice(8).reduce((acc: any, ele: any) => ele.value + acc, 0)
    sbu.push({
      name: `Otros (${value})`,
      value,
      chart: 'salesSbu'
    })
  }

  const dataUnit = ctx.salesSbuUnit.salesByUnit?.map((ele: any, index: number) => {
    return {
      name: `${recortar(ele.name, 40)} (${ele.quantity_sold})`,
      value: Number(ele.quantity_sold)
    }
  })

  const units = dataUnit.slice(0, 8)

  if (units.length > 7 && dataUnit.length > 0) {
    const value = dataUnit.slice(8).reduce((acc: any, ele: any) => ele.value + acc, 0)
    units.push({
      name: `Otros (${value})`,
      value,
      chart: 'salesUnits'
    })
  }

  function handleLegendClick (data: any) {
    data.payload.chart === 'salesSbu' && setModal({ open: true, data: dataSbu })
    data.payload.chart === 'salesUnits' && setModal({ open: true, data: dataUnit })
  }

  // function handleLegendMouseEnter (data: any, index: number | null) {
  //   setActiveIndex(index)
  // }

  // function handleLegendMouseLeave () {
  //   setActiveIndex(null)
  // }

  return (
    <>
      {modal.open && <PieModal modal={modal} setModal={setModal} />}
      <Grid container spacing={2}>
        {ctx?.salesSbuUnit.salesBySbu &&
          <Grid item md={12} lg={6}>
            <Card sx={{ height: 400 }}>
              <Box sx={{ padding: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant='h6'>Venta por SBU</Typography>
                <Button
                  variant='contained'
                  color='success'
                  sx={{ padding: 0.5 }}
                  onClick={() => downloadExcelPie(ctx.salesSbuUnit.salesBySbu, 'salesSbu')}
                >
                  <Excel />
                </Button>
              </Box>
              <ResponsiveContainer width='100%' height='80%'>
                <PieChart>
                  <Pie
                    data={sbu}
                    cx='50%'
                    cy='50%'
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={150}
                    fill='#8884d8'
                    dataKey='value'
                    // isAnimationActive={false}
                  >
                    {sbu?.map((entry: any, index: any) => (
                      // <Cell key={`cell-${index}`} fill={activeIndex !== index ? COLORS[index % COLORS.length] : COLORS2[index % COLORS2.length]} />
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Legend
                    align='right' verticalAlign='middle' layout='vertical' iconType='circle'
                    onClick={handleLegendClick}
                    // onMouseEnter={handleLegendMouseEnter}
                    // onMouseLeave={handleLegendMouseLeave}
                  />
                </PieChart>
              </ResponsiveContainer>
            </Card>
          </Grid>}
        <Grid item md={12} lg={ctx?.salesSbuUnit.salesBySbu ? 6 : 12}>
          <Card sx={{ height: 400 }}>
            <Box sx={{ padding: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant='h6'>Venta por Unidades</Typography>
              <Button
                variant='contained'
                color='success'
                sx={{ padding: 0.5 }}
                onClick={() => downloadExcelPie(ctx.salesSbuUnit.salesByUnit, 'salesUnits')}
              >
                <Excel />
              </Button>
            </Box>
            <ResponsiveContainer width='100%' height='80%'>
              <PieChart>
                <Pie
                  data={units}
                  cx='50%'
                  cy='50%'
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={150}
                  fill='#8884d8'
                  dataKey='value'
                >
                  {units?.map((entry: any, index: any) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Legend
                  align='right' verticalAlign='middle' layout='vertical' iconType='circle'
                  onClick={handleLegendClick}
                />
              </PieChart>
            </ResponsiveContainer>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default SalesSbuUnit
