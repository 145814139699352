import ArrowUp from '@mui/icons-material/CallMade'
import ArrowDown from '@mui/icons-material/CallReceived'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl, Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { DBOARD_FORMATEND, DBOARD_FORMATSMALL, DBOARD_FORMATSTART, DBOARD_MINDATE } from '../../../constants/dashboard.constants'
import { DboardApi } from '../../../services'
import { CurrencyFormat, sharingInformationService } from '../../../utils'
import { exportTableToExcel } from '../../../utils/Excel.utility'
import { useDashboardContext } from '../context/dashboard.context'
import { ReactComponent as Excel } from '@assets/svg/excel.svg'

interface OwnStatus {
    dataComparisonSale: any[]
    idSeller: number
    dateStart: string
    dateEnd: string
    dateStartCom: string
    dateEndCom: string
    loading: boolean
}

const ComparacionVentasSeller = () => {
  const { ctx } = useDashboardContext()
  const [data, setData] = useState <OwnStatus>({ dataComparisonSale: [], idSeller: ctx.idSeller, dateStart: ctx.dateStart, dateEnd: ctx.dateEnd, dateStartCom: ctx.dateStart, dateEndCom: ctx.dateEnd, loading: false })
  const subscription$ = sharingInformationService.getSubject()

  const loadData = (_dateStart: string, _dateEnd: string, _dateStartCom: string, _dateEndCom: string, _idSeller: number) => {
    setData({ ...data, loading: true })
    DboardApi.getComparisonSeller(_dateStart, _dateEnd, _dateStartCom, _dateEndCom, _idSeller).then((response) => {
      setData({ ...data, dataComparisonSale: response.items, dateStart: _dateStart, dateEnd: _dateEnd, dateStartCom: _dateStartCom, dateEndCom: _dateEndCom, idSeller: _idSeller, loading: false })
    }).catch((error) => {
      console.log('ComparacionVentasSeller loadData error:', error)
      setData({ ...data, loading: false })
    })
  }

  useMemo(() => {
    loadData(moment(data.dateStart).format(DBOARD_FORMATSTART), moment(data.dateEnd).format(DBOARD_FORMATEND), moment(data.dateStartCom).format(DBOARD_FORMATSTART), moment(data.dateEndCom).format(DBOARD_FORMATEND), data.idSeller)
    subscription$.subscribe((info: any) => {
      loadData(moment(info.dateStart).format(DBOARD_FORMATSTART), moment(info.dateEnd).format(DBOARD_FORMATEND), moment(info.dateStart).format(DBOARD_FORMATSTART), moment(info.dateEnd).format(DBOARD_FORMATEND), info.idSeller)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card>
      <CardContent>
        <Box sx={{ p: 1, pb: 0 }}>
          <Typography
            variant='h5'
            color='textSecondary'
            sx={{ color: '#000', fontWeight: '500px', fontSize: '1rem' }}
          >
            Comparación Ventas por <b>Seller</b>
          </Typography>

          {data.dataComparisonSale && data.dataComparisonSale.length > 0
            ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'right',
                  justifyContent: 'right'
                }}
              >
                <Button
                  variant='contained'
                  color='success'
                  size='small'
                  onClick={() =>
                    exportTableToExcel(
                      'idTableComparison',
                      'Ventas por seller Comparación'
                    )}
                >
                  <Excel className='me-2' /> Exportar
                </Button>
              </Box>
              )
            : (
                ''
              )}
        </Box>
        <Grid container direction='row' spacing={4} my={1}>
          <Grid item xs={12} md={12} lg={3} xl={3}>
            <Grid item xs={12} md={12} lg={12} xl={12} my={2}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>Seller</InputLabel>
                <Select
                  value={data.idSeller}
                  label='Seller'
                  onChange={(e) => setData({ ...data, idSeller: parseInt(e.target.value.toString()) })}
                  size='small'
                >

                  {ctx.dataListSeller &&
                        ctx.dataListSeller.map((row : any, i : number) => (
                          <MenuItem value={row.idSeller} key={i + 1}>
                            {row.nameSeller}
                          </MenuItem>
                        ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} my={2}>
              <DatePicker
                label='Fecha Desde'
                disableFuture
                minDate={DBOARD_MINDATE}
                format='dd-MM-yyyy'
                value={moment(data.dateStart).toDate()}
                onChange={(newValue) => {
                  setData({ ...data, dateStart: moment(newValue).format(DBOARD_FORMATSTART) })
                }}
                slotProps={{
                  textField: {size: "small"}
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} my={2}>
              <DatePicker
                label='Fecha Hasta'
                disableFuture
                minDate={moment(data.dateStart).toDate()}
                format='dd-MM-yyyy'
                value={moment(data.dateEnd).toDate()}
                onChange={(newValue) => {
                  setData({ ...data, dateEnd: moment(newValue).format(DBOARD_FORMATEND) })
                }}
                slotProps={{
                  textField: {size: "small"}
                }}
              />
            </Grid>
            <Divider />
            <Grid item xs={12} md={12} lg={12} xl={12} my={2}>
              <DatePicker
                label='Fecha Desde Comparación'
                format='dd-MM-yyyy'
                minDate={DBOARD_MINDATE}
                disableFuture
                value={moment(data.dateStartCom).toDate()}
                onChange={(newValue) => {
                  setData({ ...data, dateStartCom: moment(newValue).format(DBOARD_FORMATSTART) })
                }}
                slotProps={{
                  textField: {size: "small"}
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} my={2}>
              <DatePicker
                label='Fecha Hasta Comparación'
                format='dd-MM-yyyy'
                minDate={moment(data.dateStartCom).toDate()}
                disableFuture
                value={moment(data.dateEndCom).toDate()}
                onChange={(newValue) => {
                  setData({ ...data, dateEndCom: moment(newValue).format(DBOARD_FORMATEND) })
                }}
                              slotProps={{
                textField: {size: "small"}
              }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} my={2}>
              <Box>
                <LoadingButton
                  variant='contained'
                  color='primary'
                  onClick={() => loadData(data.dateStart, data.dateEnd, data.dateStartCom, data.dateEndCom, data.idSeller)}
                  disabled={!moment(data.dateEnd).isAfter(data.dateStart) || !moment(data.dateEndCom).isAfter(data.dateStartCom)}
                  loading={data.loading}
                >
                  Filtrar
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12} lg={9} xl={9}>
            {data.dataComparisonSale && data.dataComparisonSale.length > 0
              ? (
                <Grid container direction='row' spacing={2} my={1}>
                  {data.dataComparisonSale &&
                    data.dataComparisonSale.map((row, index) => (
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={4}
                        xl={4}
                        key={index + 1}
                      >
                        <Card
                          sx={{
                            p: 1,
                            background: 'linear-gradient(to left top, #4676b9, #7c3c91)',
                            color: '#ffffff'
                          }}
                        >
                          <CardContent sx={{ p: 1.5, pb: '12px !important' }}>
                            <Grid
                              container
                              spacing={1}
                              sx={{ justifyContent: 'space-between' }}
                            >
                              <Grid item>
                                <Typography
                                  color='white'
                                  gutterBottom
                                  variant='h5'
                                  sx={{ fontWeight: 500 }}
                                >
                                  {row.seller}
                                </Typography>
                                <Typography color='white' variant='h4'>
                                  {CurrencyFormat(row.total_price)}{' '}
                                </Typography>
                              </Grid>
                              <Grid item />
                            </Grid>
                            <Box
                              sx={{
                                alignItems: 'center',
                                display: 'flex',
                                pt: 2
                              }}
                            >
                              <Stack
                                direction='row'
                                divider={
                                  <Divider orientation='vertical' flexItem />
                                }
                                spacing={4}
                              >
                                <Typography
                                  variant='body1'
                                  sx={{
                                    mr: 1
                                  }}
                                >
                                  {Math.sign(row.monto_diferencia) === 1
                                    ? (
                                      <ArrowUp sx={{ color: '#38c77b' }} />
                                      )
                                    : Math.sign(row.porcentaje_diferencia) ===
                                    -1
                                      ? (
                                        <ArrowDown sx={{ color: '#b51d2f' }} />
                                        )
                                      : (
                                          ''
                                        )}
                                  {row.porcentaje_diferencia} %
                                </Typography>
                                <Typography
                                  color='white'
                                  variant='body1'
                                >
                                  {Math.sign(row.monto_diferencia) === 1
                                    ? (
                                      <ArrowUp sx={{ color: '#38c77b' }} />
                                      )
                                    : Math.sign(row.porcentaje_diferencia) ===
                                    -1
                                      ? (
                                        <ArrowDown sx={{ color: '#b51d2f' }} />
                                        )
                                      : (
                                          ''
                                        )}
                                  {CurrencyFormat(row.monto_diferencia)}
                                </Typography>
                              </Stack>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                </Grid>
                )
              : (
                <Box
                  sx={{
                    p: 1,
                    pb: 0,
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Typography
                    variant='h5'
                    color='textSecondary'
                    sx={{ color: '#000', fontWeight: '500px' }}
                  >
                    Sin registro en la <b>fecha seleccionada</b>
                  </Typography>
                </Box>
                )}
          </Grid>

          {/** excel  */}

          <TableContainer
            sx={{
              width: '100%',
              overflowX: 'auto',
              position: 'relative',
              display: 'block',
              maxWidth: '100%'
            }}
          >
            {' '}
            <Table
              id='idTableComparison'
              className='table table-border table-hover '
              border={1}
              hidden
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      padding: 10,
                      textAlign: 'center'
                    }}
                    colSpan={2}
                  >
                    Fecha
                  </TableCell>
                  <TableCell>
                    Desde - Hasta{' '}
                    {moment(data.dateStart).format(DBOARD_FORMATSMALL)} - {' '}
                    {moment(data.dateEnd).format(DBOARD_FORMATSMALL)}
                  </TableCell>
                  <TableCell>
                    Desde - Hasta (Comparación){' '}
                    {moment(data.dateStartCom).format(DBOARD_FORMATSMALL)} - {' '}
                    {moment(data.dateEndCom).format(DBOARD_FORMATSMALL)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow
                  style={{
                    padding: 0,
                    textAlign: 'center'
                  }}
                >
                  <TableCell style={{ color: '#000', textAlign: 'center' }}>
                    Seller
                  </TableCell>
                  <TableCell style={{ color: '#000', textAlign: 'center' }}>
                    Monto Ventas
                  </TableCell>
                  <TableCell style={{ color: '#000', textAlign: 'center' }}>
                    % Ventas
                  </TableCell>
                  <TableCell style={{ color: '#000', textAlign: 'center' }}>
                    Indicar Venta
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.dataComparisonSale &&
                    data.dataComparisonSale.map((row : any, i : number) => (
                      <TableRow key={i + 1}>
                        <TableCell style={{ textAlign: 'center' }}>
                          {row.seller}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          {CurrencyFormat(row.total_price)}{' '}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          {Math.sign(row.monto_diferencia) === 1
                            ? (
                              <ArrowUp sx={{ color: '#38c77b' }} />
                              )
                            : Math.sign(row.monto_diferencia) === -1
                              ? (
                                <ArrowDown sx={{ color: '#b51d2f' }} />
                                )
                              : (
                                  ''
                                )}
                          {row.porcentaje_diferencia} %
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          {Math.sign(row.porcentaje_diferencia) === 1
                            ? (
                              <ArrowUp sx={{ color: '#38c77b' }} />
                              )
                            : Math.sign(row.porcentaje_diferencia) === -1
                              ? (
                                <ArrowDown sx={{ color: '#b51d2f' }} />
                                )
                              : (
                                  ''
                                )}
                          {CurrencyFormat(row.monto_diferencia)}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ComparacionVentasSeller
