import Typography from '@mui/material/Typography'
import TableTradeInTraffic from './components/Tables/TableTraffic'
import { TIProvider } from './context/traffic.context'
import RangeDate from './components/RangeDate/RangeDate'

const TradeInTraffic = () => {
  return (

    <TIProvider>
      <Typography
        variant='h1'
        color='textSecondary'
        className='mb-4'
        sx={{ color: '#000', fontWeight: '500px', fontSize: '1rem' }}
      >
        <b>Trade-In Traffic</b>
      </Typography>
      <RangeDate />
      <TableTradeInTraffic />
    </TIProvider>

  )
}

export default TradeInTraffic
