
import { DBOARD_FORMATEND, DBOARD_FORMATSTART } from '@constants/dashboard.constants'
import { TRADEIN_MINDATE } from '@constants/tradein.constants'
import { LoadingButton } from '@mui/lab'
import { Button, Grid } from '@mui/material'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { DatePicker } from '@mui/x-date-pickers'
import { TIContextType, useTIContext } from '@pages/TradeinTraffic/context/traffic.context'
import moment from 'moment'
import { exportTableToExcel } from '@utils/Excel.utility'
import { ReactComponent as Excel } from '@assets/svg/excel.svg'

const RangeDate = () => {
  const { tiCtx, saveTICtx, loadData } = useTIContext() as TIContextType

  return (
    <Box my={2}>
      <Card>
        <CardContent>
          <Grid container direction='row' spacing={2}>
            <Grid item xs={12} md={12} lg={3} xl={3}>
              <DatePicker
                label='Fecha Desde'
                defaultValue={TRADEIN_MINDATE}
                disableFuture
                minDate={TRADEIN_MINDATE}
                format='dd-MM-yyyy'
                value={moment(tiCtx.dateStart).toDate()}
                onChange={(newValue) => {
                  saveTICtx({ ...tiCtx, dateStart: moment(newValue).format(DBOARD_FORMATSTART) })
                }}
                slotProps={{
                  textField: {size: "small", fullWidth: true}
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={3} xl={3}>
              <DatePicker
                label='Fecha Hasta'
                disableFuture
                minDate={moment(tiCtx.dateStart).toDate()}
                maxDate={moment(moment(tiCtx.dateStart).add(process.env.REACT_APP_RANGE_DAYS_FILTER, 'days').format('YYYY-MM-DD')).toDate()}
                format='dd-MM-yyyy'
                value={moment(tiCtx.dateEnd).toDate()}
                onChange={(newValue) => {
                  saveTICtx({ ...tiCtx, dateEnd: moment(newValue).format(DBOARD_FORMATEND) })
                }}
                slotProps={{
                  textField: {size: "small", fullWidth: true}
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={3} xl={3}>
              <Box>
                <LoadingButton
                  variant='contained'
                  color='primary'
                  onClick={() => loadData(tiCtx.dateStart, tiCtx.dateEnd)}
                  disabled={!moment(tiCtx.dateEnd).isAfter(tiCtx.dateStart) || moment(tiCtx.dateEnd).diff(moment(tiCtx.dateStart), 'days') > parseInt(process.env.REACT_APP_RANGE_DAYS_FILTER !== undefined ? (process.env.REACT_APP_RANGE_DAYS_FILTER).toString() : '61')}
                  loading={tiCtx.loading}
                >
                  Filtrar
                </LoadingButton>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={3} xl={3}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'right',
                  justifyContent: 'right'
                }}
              >
                <Button
                  variant='contained'
                  color='success'
                  size='small'
                  onClick={() =>
                    exportTableToExcel(
                      'idTableTradeinTrafficAll',
                      'Tráfico Trade-in'
                    )}
                  disabled={!(tiCtx.listHistoryLog && tiCtx.listHistoryLog.length > 0)}
                >
                  <Excel className='me-2' /> Exportar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}

export default RangeDate
