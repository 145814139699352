import { DBOARD_FORMATEND, DBOARD_FORMATSTART } from '@constants/index'
import { DboardApi } from '@services/index'
import moment from 'moment'
import { createContext, useContext, useEffect, useState, Dispatch, SetStateAction } from 'react'

interface IOwnProps {
  listTradein: any[];
  listHistoryLog: any[];
  dateStart: string;
  dateEnd: string;
  loading: boolean;
  setListTradein: Dispatch<SetStateAction<any[]>>;
}

export type TIContextType = {
  tiCtx: IOwnProps;
  saveTICtx: (tiCtx: IOwnProps) => void;
  setListTradein: Dispatch<SetStateAction<any[]>>;
  loadData: () => void;
};

export const TIContext = createContext<TIContextType | null>(null)

export const TIProvider = ({ children }: any) => {
  const [tiCtx, setTICtx] = useState<IOwnProps>({
    listTradein: [],
    listHistoryLog: [],
    loading: false,
    dateStart: moment().format(DBOARD_FORMATSTART),
    dateEnd: moment().format(DBOARD_FORMATEND),
    setListTradein: (listTradein) => {}
  })

  const saveTICtx = (_tiCtx: IOwnProps) => {
    setTICtx(_tiCtx)
  }

  const setListTradein: Dispatch<SetStateAction<any[]>> = (listTradein: SetStateAction<any[]>) => {
    setTICtx((prevTICtx) => ({
      ...prevTICtx,
      listTradein: typeof listTradein === 'function' ? listTradein(prevTICtx.listTradein) : listTradein
    }))
  }

  const loadData = () => {
    setTICtx({ ...tiCtx, loading: true })
    DboardApi.getTradeinOrder().then((response) => {
      if (response.success) {
        setTICtx({ ...tiCtx, loading: false, listTradein: response.data })
      } else {
        setTICtx({ ...tiCtx, loading: false })
      }
    }).catch((error) => {
      console.log('loadData error:', error)
      setTICtx({ ...tiCtx, loading: false })
    })
  }

  useEffect(() => {
    loadData()
    return () => setTICtx({
      listTradein: [],
      listHistoryLog: [],
      dateStart: moment().format(DBOARD_FORMATSTART),
      dateEnd: moment().format(DBOARD_FORMATEND),
      loading: false,
      setListTradein: (listTradein) => {}
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TIContext.Provider value={{ tiCtx, saveTICtx, setListTradein, loadData }}>
      {children}
    </TIContext.Provider>
  )
}

export const useTIContext = () => {
  const context = useContext(TIContext)
  if (context === undefined) {
    throw new Error('useTIContext must be used within a DashboardProvider')
  }
  return context
}
