import {
  Box, TableCell, TableHead, TableRow,
  TableSortLabel
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import PropTypes from 'prop-types'
import { TextAlign } from '../TableComissionFilter/EnhancedTableHead'

const headCells = [
  {
    id: 'seller',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Seller',
    width: '20%'
  },
  {
    id: 'id_order',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Veces usado',
    width: '15%'
  },
  {
    id: 'note',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Causa de reembolso'
  }
]

const EnhancedTableHead = (props : any) => {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    onRequestSort
  } = props
  const createSortHandler = (property : any) => (event : any) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow
        sx={{
          background: 'linear-gradient(to left top, #4676b9, #7c3c91)',
          color: '#ffff',
          padding: 3,
          textAlign: 'center'
        }}
      >
        {headCells.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            width={headCell.width}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ color: '#fff', background: 'transparent' }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id
                ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                  )
                : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

export default EnhancedTableHead
