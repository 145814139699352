import {
  Box,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  styled,
  tableCellClasses
} from '@mui/material'
import ApexChart from 'react-apexcharts'
import React from 'react'
import TableDetailed from './TableDetailed'
import TableDelayDetailed from './TableDelayDetailed'
interface TableDetailedProps {
    dataFullfilment: any[]
  }
const DelayChart: React.FC<TableDetailedProps> = ({ dataFullfilment }) => {
  const dataOptions1 = {
    donut: {
      options: {
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 400
              },
              legend: {
                show: 'true',
                position: 'bottom',
                horizontalAlign: 'center'
              }
            }
          }
        ],
        labels: dataFullfilment && dataFullfilment.length > 0
          ? ['Despachos a tiempo ', 'Despachos con atraso', 'Sin despachar']
          : [],
        stroke: {
          show: false,
          width: 0
        },
        colors: ['#064b90', '#2d96ff', '#ffa500']
      }
    }

  }
  const dataOptions2 = {
    donut: {
      options: {
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 400
              },
              legend: {
                show: 'true',
                position: 'bottom',
                horizontalAlign: 'center'
              }
            }
          }
        ],
        labels: dataFullfilment && dataFullfilment.length > 0
          ? ['Recibido a tiempo ', 'Recibido atrasado']
          : [],
        stroke: {
          show: false,
          width: 0
        },
        colors: ['#064b90', '#ffa500']
      }
    }

  }
  const dataOptions3 = {
    donut: {
      options: {
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 400
              },
              legend: {
                show: 'true',
                position: 'bottom',
                horizontalAlign: 'center'
              }
            }
          }
        ],
        labels: dataFullfilment && dataFullfilment.length > 0
          ? ['Sin entregas', 'Sin entregas']
          : [],
        stroke: {
          show: false,
          width: 0
        },
        colors: ['#ccc', '#ccc']
      }
    }

  }
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    }
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }))
  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <Box sx={{ pb: 3 }}>
          <Divider variant='middle' />
        </Box>
      </Grid>

      {dataFullfilment && dataFullfilment.length > 0
        ? (
          <>
            <Grid item xs={12} md={4} lg={4} xl={4}>
              <Box
                sx={{
                  display: 'flex',
                  align: 'center',
                  justify: 'center',
                  justifyContent: 'center',
                  alignContent: 'center'
                }}
              >
                <TableContainer component={Paper}>
                  <Table border={1}>
                    <TableBody>
                      <StyledTableRow
                        sx={{
                          background: 'linear-gradient(to left top, #4676b9, #7c3c91) !important',
                          color: '#ffff'
                        }}
                      >
                        <StyledTableCell
                          sx={{
                            color: '#ffff'
                          }}
                          component='td'
                          scope='row'
                        >
                          <Typography variant='body1'>
                            Entregados
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            color: '#ffff'
                          }}
                          component='td'
                          scope='row'
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              align: 'center',
                              justify: 'center',
                              justifyContent: 'center',
                              alignContent: 'center'
                            }}
                          >
                            <Typography variant='body1'>
                              Despachados con atraso
                            </Typography>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            color: '#ffff'
                          }}
                          component='td'
                          scope='row'
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              align: 'center',
                              justify: 'center',
                              justifyContent: 'center',
                              alignContent: 'center'
                            }}
                          >
                            <Typography variant='body1'>
                              Sin despachar
                            </Typography>
                          </Box>
                        </StyledTableCell>

                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell align='right'>
                          <Box
                            sx={{
                              display: 'flex',
                              align: 'center',
                              justify: 'center',
                              justifyContent: 'center',
                              alignContent: 'center'
                            }}
                          >
                            <Typography
                              variant='h5'
                              sx={{
                                color: dataFullfilment.filter((registro) => registro.tracking_status_id === 10).length > 0 ? 'green' : null
                              }}
                            >
                              {dataFullfilment.filter((registro) => registro.tracking_status_id === 10 && (registro.status_reversso !== 'voided' || registro.status_reversso === null) && registro.reversso !== 1 && registro.local_pickup !== 1 && (registro.point_of_sale !== 1 && registro.point_of_sale !== null)).length}
                            </Typography>

                          </Box>
                        </StyledTableCell>
                        <StyledTableCell align='right'>
                          <Box
                            sx={{
                              display: 'flex',
                              align: 'center',
                              justify: 'center',
                              justifyContent: 'center',
                              alignContent: 'center'
                            }}
                          >
                            <Typography
                              variant='h5'
                              sx={{
                                color: (() => {
                                  const filteredData = dataFullfilment.filter((registro) => (registro.status_reversso !== 'voided' || registro.status_reversso === null) && registro.reversso !== 1 && registro.local_pickup !== 1 && (registro.point_of_sale !== 1 && registro.point_of_sale !== null) && registro.total_delay > 0 && registro.status === 'Despachado')
                                  const totalLength = filteredData.length
                                  const redThreshold = 10
                                  const yellowThreshold = 5

                                  if (totalLength > redThreshold) {
                                    return 'red'
                                  } else if (totalLength > yellowThreshold) {
                                    return 'orange'
                                  } else {
                                    return 'green'
                                  }
                                })(),
                                paddingRight: '20px'
                              }}
                            >
                              {dataFullfilment.filter((registro) => registro.tracking_status_id !== 10 && (registro.status_reversso !== 'voided' || registro.status_reversso === null) && registro.reversso !== 1 && registro.local_pickup !== 1 && (registro.point_of_sale !== 1 && registro.point_of_sale !== null) && registro.total_delay > 0 && registro.status === 'Despachado').length}
                            </Typography>
                            {
                              dataFullfilment.filter((registro) => (registro.status_reversso !== 'voided' || registro.status_reversso === null) && registro.reversso !== 1 && registro.local_pickup !== 1 && (registro.point_of_sale !== 1 && registro.point_of_sale !== null) && registro.total_delay > 0 && registro.status === 'Despachado').length > 0
                                ? (<TableDelayDetailed dataFullfilment={dataFullfilment} />)
                                : (null)
                            }
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell align='right'>
                          <Box
                            sx={{
                              display: 'flex',
                              align: 'center',
                              justify: 'center',
                              justifyContent: 'center',
                              alignContent: 'center'
                            }}
                          >
                            <Typography
                              variant='h5'
                              sx={{
                                color: (() => {
                                  const filteredData = dataFullfilment.filter((registro) => (registro.status_reversso !== 'voided' || registro.status_reversso === null) && registro.reversso !== 1 && registro.local_pickup !== 1 && (registro.point_of_sale !== 1 && registro.point_of_sale !== null) && registro.status === 'Sin despachar')
                                  const totalLength = filteredData.length
                                  const redThreshold = 10
                                  const yellowThreshold = 5

                                  if (totalLength > redThreshold) {
                                    return 'red'
                                  } else if (totalLength > yellowThreshold) {
                                    return 'orange'
                                  } else {
                                    return 'green'
                                  }
                                })(),
                                paddingRight: '20px'
                              }}
                            >
                              {dataFullfilment.filter((registro) => (registro.status_reversso !== 'voided' || registro.status_reversso === null) && registro.reversso !== 1 && registro.local_pickup !== 1 && (registro.point_of_sale !== 1 && registro.point_of_sale !== null) && registro.status === 'Sin despachar').length}
                            </Typography>
                            {
                              dataFullfilment.filter((registro) => (registro.status_reversso !== 'voided' || registro.status_reversso === null) && registro.reversso !== 1 && registro.local_pickup !== 1 && (registro.point_of_sale !== 1 && registro.point_of_sale !== null) && registro.status === 'Sin despachar').length > 0
                                ? (<TableDetailed dataFullfilment={dataFullfilment} />)
                                : (null)
                            }
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={4} xl={4}>
              <ApexChart
                className='sparkline-chart'
                type='donut'
                height={300}
                mt={3}
                sx={{ marginTop: '40px' }}
                series={
                    dataFullfilment.filter((registro) => registro.first_delay === 0 && registro.fulfilled === 1 && (registro.status_reversso !== 'voided' || registro.status_reversso === null) && registro.reversso !== 1 && registro.local_pickup !== 1 && (registro.point_of_sale !== 1 && registro.point_of_sale !== null)).length > 0 ||
                    dataFullfilment.filter((registro) => registro.first_delay > 0 && (registro.status_reversso !== 'voided' || registro.status_reversso === null) && registro.reversso !== 1 && registro.local_pickup !== 1 && (registro.point_of_sale !== 1 && registro.point_of_sale !== null)).length > 0
                      ? [
                          dataFullfilment.filter((registro) => registro.first_delay === 0 && registro.fulfilled === 1 && (registro.status_reversso !== 'voided' || registro.status_reversso === null) && registro.reversso !== 1 && registro.local_pickup !== 1 && (registro.point_of_sale !== 1 && registro.point_of_sale !== null) && registro.tracking_status_id !== 10).length,
                          dataFullfilment.filter((registro) => (registro.status_reversso !== 'voided' || registro.status_reversso === null) && registro.reversso !== 1 && registro.local_pickup !== 1 && (registro.point_of_sale !== 1 && registro.point_of_sale !== null) && registro.total_delay > 0 && registro.status === 'Despachado').length,
                          dataFullfilment.filter((registro) => (registro.status_reversso !== 'voided' || registro.status_reversso === null) && registro.reversso !== 1 && registro.local_pickup !== 1 && (registro.point_of_sale !== 1 && registro.point_of_sale !== null) && registro.status === 'Sin despachar').length
                        ]
                      : [1, 0]
                }
                options={
                  dataFullfilment.filter((registro) => registro.first_delay === 0 && registro.fulfilled === 1).length > 0 ||
                  dataFullfilment.filter((registro) => (registro.fulfilled === 0 || registro.first_delay > 0) && (registro.status_reversso !== 'voided' || registro.status_reversso === null) && registro.reversso !== 1 && registro.local_pickup !== 1 && (registro.point_of_sale !== 1 && registro.point_of_sale !== null)).length > 0
                    ? dataOptions1.donut.options
                    : dataOptions3.donut.options
                }
              />

            </Grid>
            <Grid item xs={12} md={4} lg={4} xl={4}>
              <ApexChart
                className='sparkline-chart'
                type='donut'
                height={300}
                mt={3}
                sx={{ marginTop: '40px' }}
                series={
                    dataFullfilment.filter((registro) => registro.second_delay === 0 && registro.tracking_status_id === 10 && (registro.status_reversso !== 'voided' || registro.status_reversso === null) && registro.reversso !== 1 && registro.local_pickup !== 1 && (registro.point_of_sale !== 1 && registro.point_of_sale !== null)).length > 0 ||
                    dataFullfilment.filter((registro) => registro.second_delay > 0 && registro.tracking_status_id === 10 && (registro.status_reversso !== 'voided' || registro.status_reversso === null) && registro.reversso !== 1 && registro.local_pickup !== 1 && (registro.point_of_sale !== 1 && registro.point_of_sale !== null)).length > 0
                      ? [
                          dataFullfilment.filter((registro) => registro.second_delay === 0 && registro.tracking_status_id === 10 && (registro.status_reversso !== 'voided' || registro.status_reversso === null) && registro.reversso !== 1 && registro.local_pickup !== 1 && (registro.point_of_sale !== 1 && registro.point_of_sale !== null)).length,
                          dataFullfilment.filter((registro) => registro.second_delay > 0 && registro.tracking_status_id === 10 && (registro.status_reversso !== 'voided' || registro.status_reversso === null) && registro.reversso !== 1 && registro.local_pickup !== 1 && (registro.point_of_sale !== 1 && registro.point_of_sale !== null)).length
                        ]
                      : [1, 0]
                }
                options={
                    dataFullfilment.filter((registro) => registro.second_delay === 0 && registro.status_reversso !== 'voided' && registro.reversso !== 1 && registro.local_pickup !== 1 && registro.tracking_status_id === 10 && (registro.point_of_sale !== 1 && registro.point_of_sale !== null)).length > 0 ||
                    dataFullfilment.filter((registro) => registro.second_delay > 0 && registro.status_reversso !== 'voided' && registro.reversso !== 1 && registro.local_pickup !== 1 && registro.tracking_status_id === 10 && (registro.point_of_sale !== 1 && registro.point_of_sale !== null)).length > 0
                      ? dataOptions2.donut.options
                      : dataOptions3.donut.options
                }
              />
            </Grid>

          </>
          )
        : (
          <Box
            sx={{
              display: 'flex',
              align: 'center',
              justify: 'center',
              justifyContent: 'center',
              alignContent: 'center'
            }}
          >
            <Typography
              variant='h5'
              color='textSecondary'
              sx={{ color: '#000', fontWeight: '500px' }}
            >
              Sin <b>unidades vendidas</b> en la fecha seleccionada
            </Typography>
          </Box>
          )}

    </Grid>
  )
}

export default DelayChart
