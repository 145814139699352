import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  styled
} from '@mui/material'
import { Close } from '@mui/icons-material'
import React, { useState } from 'react'
import EnhancedTableHead from './EnhancedTableHead'
import { DboardApi } from '@services/index'
import { useDashboardContext } from '@pages/Dashboard/context/dashboard.context'
import { CurrencyFormat } from '@utils/Currency.utility'
interface TableDetailedProps {
    idCode: number,
    idSeller: number,
    seller: string,
    dateStart: string,
    dateEnd: string,
    discountCode: string
  }
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    maxWidth: '80%'
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))
export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
  }
function BootstrapDialogTitle (props: DialogTitleProps) {
  const { children, onClose, ...other } = props
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose
        ? (
          <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <Close />
          </IconButton>
          )
        : null}
    </DialogTitle>
  )
}
const TableDetailed: React.FC<TableDetailedProps> = (props) => {
  const { idSeller, seller, discountCode, dateStart, dateEnd } = props
  const [open, setOpen] = React.useState(false)
  const { ctx } = useDashboardContext()
  const [data, setData] = useState <{ dataDiscountsDetail: any[], nameSeller: any, totalMonto: number, totalMontoFinal: number, totalDevoluciones: number, totalMontoDevoluciones: number, totalPedidos: number, page: number, rowsPerPage: number, order: string, orderBy: string, idSeller: number, dateStart: string, dateEnd: string, loading: boolean }>({ dataDiscountsDetail: [], nameSeller: '', totalMontoFinal: 0, totalMonto: 0, totalDevoluciones: 0, totalMontoDevoluciones: 0, totalPedidos: 0, page: 0, rowsPerPage: 10, order: 'asc', orderBy: '', idSeller: ctx.idSeller, dateStart: ctx.dateStart, dateEnd: ctx.dateEnd, loading: false })

  const handleChangePage = (event: any, newPage : number) => {
    setData({ ...data, page: newPage })
  }

  const handleChangeRowsPerPage = (event: any) => {
    setData({ ...data, rowsPerPage: parseInt(event.target.value, 10), page: 0 })
  }

  const handleRequestSort = (event: any, property : any) => {
    const isAsc = data.orderBy === property && data.order === 'asc'
    setData({ ...data, order: isAsc ? 'desc' : 'asc', orderBy: property })
  }

  const stableSort = (array: any[], comparator: any) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  const getComparator = (order: string, orderBy : string) => {
    return order === 'desc'
      ? (a: any, b: any) => descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy)
  }

  const descendingComparator = (a: any, b: any, orderBy : string) => {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const handleClickOpen = () => {
    setData({ ...data, loading: true })
    const datos = {
      date_init: dateStart,
      date_end: dateEnd,
      id_seller: idSeller,
      the_discount_code: discountCode
    }
    DboardApi.getDiscountsDetail(datos).then((response) => {
      console.log('response =>', response)
      setData({ ...data, dataDiscountsDetail: response.items, loading: false })
    }).catch((error) => {
      console.log('TotalVentas loadData error:', error)
      setData({ ...data, loading: false })
    })
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  console.log('idSeller', idSeller)
  return (
    <div>
      <Button
        variant='contained'
        size='small'
        onClick={handleClickOpen}
      >
        Detalle
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
          {seller} - <span className='badge rounded-pill text-bg-primary ms-2 h3 m-0'>{discountCode}</span>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container rowSpacing={2.5} columnSpacing={2.75}>

            {
              data.loading === false
                ? (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TableContainer sx={{ maxHeight: '450px' }}>
                      <Table size='small' border={1} stickyHeader>
                        <EnhancedTableHead
                          numSelected={0}
                          order={data.order}
                          orderBy={data.orderBy}
                          onRequestSort={handleRequestSort}
                          rowCount={data.dataDiscountsDetail.length}
                        />
                        {data.dataDiscountsDetail &&
                  data.dataDiscountsDetail.length !== 0
                          ? (
                            <TableBody>
                              {stableSort(
                                data.dataDiscountsDetail,
                                getComparator(data.order, data.orderBy)
                              )
                                .slice(
                                  data.page * data.rowsPerPage,
                                  data.page * data.rowsPerPage + data.rowsPerPage
                                )
                                .filter(e => e.status !== 'voided')
                                .map((row, index) => {
                                  return (
                                    <TableRow
                                      hover
                                      role='checkbox'
                                      tabIndex={-1}
                                      key={index + 1}
                                      selected={false}
                                    >
                                      <TableCell align='center' width='50px'>
                                        {row.id_order}
                                      </TableCell>
                                      <TableCell align='center' width='50%' style={{ textAlign: 'left' }}>
                                        {row.title}
                                      </TableCell>
                                      <TableCell align='center' width='50px'>
                                        {CurrencyFormat(row.total_bruto)}
                                      </TableCell>
                                      <TableCell align='center' width='50px'>
                                        {CurrencyFormat(row.discount)}
                                      </TableCell>
                                    </TableRow>
                                  )
                                })}
                            </TableBody>
                            )
                          : (
                            <TableBody>
                              <TableRow>
                                <TableCell colSpan={6}>
                                  <Typography
                                    variant='h5'
                                    color='textSecondary'
                                    sx={{ color: '#000', fontWeight: '500px' }}
                                  >
                                    Sin registro en la fecha seleccionada{' '}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                            )}
                      </Table>
                    </TableContainer>

                    <TablePagination
                      rowsPerPageOptions={[10, 20, 50, 100]}
                      component='div'
                      count={data.dataDiscountsDetail.length}
                      rowsPerPage={data.rowsPerPage}
                      page={data.page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Grid>
                  )
                : (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '450px' }}>
                      <CircularProgress />
                    </div>
                  </Grid>
                  )
              }

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus variant='contained' onClick={handleClose}>
            Cerrar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  )
}

export default TableDetailed
