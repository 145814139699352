import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  styled
} from '@mui/material'
import { Close } from '@mui/icons-material'
import React, { useState } from 'react'
import EnhancedTableHead from './EnhancedTableHead'
import { useDashboardContext } from '@pages/Dashboard/context/dashboard.context'
import moment from 'moment'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import WhereToVoteIcon from '@mui/icons-material/WhereToVote'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CancelIcon from '@mui/icons-material/Cancel'
import StoreIcon from '@mui/icons-material/Store'
import UnarchiveIcon from '@mui/icons-material/Unarchive'

interface TableDetailedProps {
    dataFullfilment: any[]
  }
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    maxWidth: '80%'
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))
export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
  }
function BootstrapDialogTitle (props: DialogTitleProps) {
  const { children, onClose, ...other } = props
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose
        ? (
          <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <Close />
          </IconButton>
          )
        : null}
    </DialogTitle>
  )
}
const TableDelayDetailed: React.FC<TableDetailedProps> = ({ dataFullfilment }) => {
  const [open, setOpen] = React.useState(false)
  const { ctx } = useDashboardContext()
  const [data, setData] = useState <{ dataFulfillment: any[], nameSeller: any, totalMonto: number, totalMontoFinal: number, totalDevoluciones: number, totalMontoDevoluciones: number, totalPedidos: number, page: number, rowsPerPage: number, order: string, orderBy: string, idSeller: number, dateStart: string, dateEnd: string, loading: boolean }>({ dataFulfillment: [], nameSeller: '', totalMontoFinal: 0, totalMonto: 0, totalDevoluciones: 0, totalMontoDevoluciones: 0, totalPedidos: 0, page: 0, rowsPerPage: 10, order: 'asc', orderBy: '', idSeller: ctx.idSeller, dateStart: ctx.dateStart, dateEnd: ctx.dateEnd, loading: false })

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleChangePage = (event: any, newPage : number) => {
    setData({ ...data, page: newPage })
  }

  const handleChangeRowsPerPage = (event: any) => {
    setData({ ...data, rowsPerPage: parseInt(event.target.value, 10), page: 0 })
  }

  const handleRequestSort = (event: any, property : any) => {
    const isAsc = data.orderBy === property && data.order === 'asc'
    setData({ ...data, order: isAsc ? 'desc' : 'asc', orderBy: property })
  }

  const stableSort = (array: any[], comparator: any) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  const getComparator = (order: string, orderBy : string) => {
    return order === 'desc'
      ? (a: any, b: any) => descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy)
  }

  const descendingComparator = (a: any, b: any, orderBy : string) => {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }
  const handleClose = () => {
    setOpen(false)
  }
  const filteredData = dataFullfilment.filter(
    (registro) => (registro.status_reversso !== 'voided' || registro.status_reversso === null) && registro.reversso !== 1 && registro.local_pickup !== 1 && (registro.point_of_sale !== 1 && registro.point_of_sale !== null) && registro.total_delay > 0 && registro.status === 'Despachado')

  return (
    <div>
      <Button
        size='small'
        sx={{ fontSize: '12px' }}
        variant='contained'
        onClick={handleClickOpen}
      >
        Ver Detalle
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
          Detalle de ordenes <strong>despachadas con atraso</strong>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container rowSpacing={2.5} columnSpacing={2.75}>

            {
              data.loading === false
                ? (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TableContainer sx={{ maxHeight: '450px' }}>
                      <Table size='small' border={1} stickyHeader>
                        <EnhancedTableHead
                          numSelected={0}
                          order={data.order}
                          orderBy={data.orderBy}
                          onRequestSort={handleRequestSort}
                          rowCount={filteredData.length}
                        />
                        {filteredData.length !== 0
                          ? (
                            <TableBody>
                              {stableSort(
                                filteredData,
                                getComparator(data.order, data.orderBy)
                              )
                                .slice(
                                  data.page * data.rowsPerPage,
                                  data.page * data.rowsPerPage + data.rowsPerPage
                                )
                                .filter((registro) => (registro.status_reversso !== 'voided' || registro.status_reversso === null) && registro.reversso !== 1 && registro.local_pickup !== 1 && (registro.point_of_sale !== 1 && registro.point_of_sale !== null) && registro.total_delay > 0 && registro.status === 'Despachado')
                                .map((row, index) => {
                                  return (
                                    <TableRow
                                      hover
                                      role='checkbox'
                                      tabIndex={-1}
                                      key={index + 1}
                                      selected={false}
                                    >
                                      <TableCell
                                        component='td'
                                        scope='row'
                                        align='left'
                                        width='60px'
                                      >
                                        {row.id_order_platform}
                                      </TableCell>
                                      <TableCell align='center' width='50px'>
                                        {moment.utc(row.fecha_venta).format('DD-MM-YYYY HH:mm:ss')}
                                      </TableCell>
                                      <TableCell align='center' width='50px'>
                                        {row.ciudad_despacho.toUpperCase()}
                                      </TableCell>
                                      <TableCell align='center' width='50px'>
                                        {row.fecha_estimada_despacho.toUpperCase()}
                                      </TableCell>
                                      <TableCell align='center' width='50px'>
                                        {row.fecha_efectiva_despacho.toUpperCase()}
                                      </TableCell>
                                      <TableCell align='center' width='50px'>
                                        <Typography style={{ color: row.first_delay === 0 ? 'green' : 'red' }}>{row.first_delay}</Typography>
                                      </TableCell>
                                      <TableCell align='center' width='50px'>
                                        {row.fecha_estimada_recepcion.toUpperCase()}
                                      </TableCell>
                                      <TableCell align='center' width='50px'>
                                        <Typography style={{ color: row.second_delay === 0 ? 'green' : 'red' }}>{row.second_delay}</Typography>
                                      </TableCell>
                                      <TableCell align='center' width='50px'>
                                        <Typography style={{
                                          color: row.fecha_recepcion === 'Sin tracking ID'
                                            ? 'red'
                                            : row.fecha_recepcion === 'Cancelado'
                                              ? 'red'
                                              : 'rgba(0,0,0,0.87)',
                                          fontSize: '0.875rem'
                                        }}
                                        >
                                          {row.fecha_recepcion.toUpperCase()}
                                        </Typography>

                                      </TableCell>
                                      <TableCell align='center' width='50px'>
                                        <Grid container direction='row' spacing={2} my={1}>
                                          <Grid item xs={12} md={9} lg={9} xl={9}>
                                            <Typography style={{
                                              color: row.status === 'Despachado'
                                                ? 'var(--reuse-azul)'
                                                : row.status === 'Entregado'
                                                  ? 'green'
                                                  : row.status === 'Sin despachar'
                                                    ? 'orange'
                                                    : row.status === 'Listo para despacho'
                                                      ? 'var(--reuse-azul)'
                                                      : row.status === 'Local pickup'
                                                        ? 'var(--reuse-azul)'
                                                        : 'red',
                                              fontSize: '0.875rem'
                                            }}
                                            >
                                              {row.status.toUpperCase()}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} md={3} lg={3} xl={3}>
                                            {row.status === 'Despachado'
                                              ? <LocalShippingIcon style={{ color: 'var(--reuse-azul)' }} />
                                              : row.status === 'Local pickup'
                                                ? <StoreIcon style={{ color: 'var(--reuse-azul)' }} />
                                                : row.status === 'Sin despachar'
                                                  ? <AccessTimeIcon style={{ color: 'orange' }} />
                                                  : row.status === 'Entregado'
                                                    ? <WhereToVoteIcon style={{ color: 'green' }} />
                                                    : row.status === 'Listo para despacho'
                                                      ? <UnarchiveIcon style={{ color: 'var(--reuse-azul)' }} />
                                                      : <CancelIcon style={{ color: 'red' }} />}
                                          </Grid>
                                        </Grid>
                                      </TableCell>
                                      <TableCell align='center' width='50px'>
                                        <Typography style={{ color: row.total_delay === 0 ? 'green' : 'red' }}>{row.total_delay}</Typography>
                                      </TableCell>
                                    </TableRow>
                                  )
                                })}
                            </TableBody>
                            )
                          : (
                            <TableBody>
                              <TableRow>
                                <TableCell colSpan={6}>
                                  <Typography
                                    variant='h5'
                                    color='textSecondary'
                                    sx={{ color: '#000', fontWeight: '500px' }}
                                  >
                                    Sin registro en la fecha seleccionada{' '}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                            )}
                      </Table>
                    </TableContainer>

                    <TablePagination
                      rowsPerPageOptions={[10, 20, 50, 100]}
                      component='div'
                      count={filteredData.length}
                      rowsPerPage={data.rowsPerPage}
                      page={data.page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Grid>
                  )
                : (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '450px' }}>
                      <CircularProgress />
                    </div>
                  </Grid>
                  )
              }

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus variant='contained' onClick={handleClose}>
            Cerrar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  )
}

export default TableDelayDetailed
