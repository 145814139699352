
import { Box, Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import React from 'react'
import { DBOARD_FORMATEND, DBOARD_FORMATSTART, DBOARD_MINDATE } from '../../../constants/dashboard.constants'
import { sharingInformationService } from '../../../utils'
import { useDashboardContext } from '../context/dashboard.context'

const GeneralFilter = () => {
  const { ctx, setCtx } = useDashboardContext()

  const handleClick = () => {
    sharingInformationService.setSubject(ctx)
  }

  return (
    <Card>
      <CardContent>
        <Box sx={{ p: 1, pb: 0 }}>
          <Typography
            variant='h5'
            color='textSecondary'
            sx={{ color: '#000', fontWeight: '500px', fontSize: '1rem' }}
          >
            Filtro <b>General</b>
          </Typography>
          <Box my={2.5}>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={12} md={12} lg={4} xl={4}>
                <FormControl fullWidth>
                  <InputLabel id='demo-simple-select-label'>Seller</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={ctx.idSeller}
                    label='Seller'
                    onChange={(e) => setCtx({ ...ctx, idSeller: parseInt(e.target.value) })}
                    size='small'
                  >

                    {ctx.dataListSeller && ctx.dataListSeller.map((item: any, idx: number) => (<MenuItem value={item.idSeller} key={idx}> {item.nameSeller} </MenuItem>))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} lg={3} xl={3}>
                <DatePicker
                  label='Fecha Desde'
                  disableFuture
                  minDate={DBOARD_MINDATE}
                  format='dd-MM-yyyy'
                  value={moment(ctx.dateStart).toDate()}
                  onChange={(newValue) => {
                    setCtx({ ...ctx, dateStart: moment(newValue).format(DBOARD_FORMATSTART) })
                  }}
                  slotProps={{
                    textField: {size: "small", fullWidth: true}
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={3} xl={3}>
                <DatePicker
                  label='Fecha Hasta'
                  disableFuture
                  minDate={moment(ctx.dateStart).toDate()}
                  format='dd-MM-yyyy'
                  value={moment(ctx.dateEnd).toDate()}
                  onChange={(newValue) => {
                    setCtx({ ...ctx, dateEnd: moment(newValue).format(DBOARD_FORMATEND) })
                  }}
                  slotProps={{
                    textField: {size: "small", fullWidth: true}
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={2} xl={2}>
                <Box>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleClick}
                    disabled={!moment(ctx.dateEnd).isAfter(ctx.dateStart)}
                  >
                    Filtrar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default GeneralFilter
