import { DatePicker } from '@mui/x-date-pickers'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'

import { LoadingButton } from '@mui/lab'
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from '@mui/material'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { DBOARD_FORMATEND, DBOARD_FORMATSTART, DBOARD_MINDATE } from '../../../constants/dashboard.constants'
import { DboardApi } from '../../../services'
import { sharingInformationService } from '../../../utils'
import { useDashboardContext } from '../context/dashboard.context'

const GraficoVentaSbuLinear = () => {
  const { ctx } = useDashboardContext()
  const [data, setData] = useState <{ dataSbu: any[], dateStart: string, dateEnd: string, loading: boolean }>({ dataSbu: [], dateStart: ctx.dateStart, dateEnd: ctx.dateEnd, loading: false })

  const subscription$ = sharingInformationService.getSubject()
  const options = {
    credits: {
      enabled: false
    },
    chart: {
      height: 350,
      backgroundColor: 'rgba(0,0,0,0)'
    },
    title: false,
    exporting: {
      enabled: false
    },
    legend: {
      verticalAlign: 'top',
      itemStyle: {
        color: '#000000'
      },
      itemHoverStyle: {
        color: '#020202'
      }
    },
    yAxis: {
      title: false,
      labels: {
        style: {
          color: '#000000'
        }
      }
    },
    xAxis: {
      type: 'datetime',
      labels: {
        overflow: 'justify',
        style: {
          color: '#000000'
        }
      }
    },
    annotations: {
      visible: false
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true,
          symbol: 'circle'
        },
        pointInterval: 3600000 * 24, // every day
        pointStart: Date.UTC(
          new Date(data.dateStart).getFullYear(),
          new Date(data.dateStart).getMonth(),
          new Date(data.dateStart).getDate(),
          0,
          0,
          0
        )
      }
    },
    series: data.dataSbu && data.dataSbu.length > 0 ? data.dataSbu : []
  }

  console.log('data.dataSbu => ', data.dataSbu)

  const loadData = (_dateStart: string, _dateEnd: string) => {
    setData({ ...data, loading: true })
    DboardApi.getSalesBySBU(_dateStart, _dateEnd).then((response) => {
      setData({ ...data, dataSbu: response.items, dateStart: _dateStart, dateEnd: _dateEnd, loading: false })
    }).catch((error) => {
      console.log('TotalVentas loadData error:', error)
      setData({ ...data, loading: false })
    })
  }

  useMemo(() => {
    loadData(moment(data.dateStart).format(DBOARD_FORMATSTART), moment(data.dateEnd).format(DBOARD_FORMATEND))
    subscription$.subscribe((info: any) => {
      loadData(moment(info.dateStart).format(DBOARD_FORMATSTART), moment(info.dateEnd).format(DBOARD_FORMATEND))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card>
      <CardContent>
        <Box sx={{ p: 1, pb: 0 }}>
          <Typography
            variant='h5'
            color='textSecondary'
            sx={{ color: '#000', fontWeight: '500px', fontSize: '1rem' }}
          >
            Ventas por unidad de negocio <b>(SBU)</b>
          </Typography>
          <Grid container direction='row' spacing={2} my={1}>
            <Grid item xs={12} md={4} lg={3} xl={3}>
              <DatePicker
                label='Fecha Desde'
                disableFuture
                minDate={DBOARD_MINDATE}
                format='dd-MM-yyyy'
                value={moment(data.dateStart).toDate()}
                onChange={(newValue) => {
                  setData({ ...data, dateStart: moment(newValue).format(DBOARD_FORMATSTART) })
                }}
                slotProps={{
                  textField: {size: "small", fullWidth: true}
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3} xl={3}>
              <DatePicker
                label='Fecha Hasta'
                disableFuture
                minDate={moment(data.dateStart).toDate()}
                format='dd-MM-yyyy'
                value={moment(data.dateEnd).toDate()}
                onChange={(newValue) => {
                  setData({ ...data, dateEnd: moment(newValue).format(DBOARD_FORMATEND) })
                }}
                slotProps={{
                  textField: {size: "small", fullWidth: true}
                }}
              />
            </Grid>
            <Grid item xs={2} md={2} lg={2} xl={2}>
              <Box>
                <LoadingButton
                  variant='contained'
                  color='primary'
                  onClick={() => loadData(data.dateStart, data.dateEnd)}
                  disabled={!moment(data.dateEnd).isAfter(data.dateStart)}
                  loading={data.loading}
                >
                  Filtrar
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {data.dataSbu && data.dataSbu.length > 0
          ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              width='100%'
            />
            )
          : (
            <Box sx={{ p: 1, pb: 0 }}>
              <Typography
                variant='h5'
                color='textSecondary'
                sx={{ color: '#000', fontWeight: '500px' }}
              >
                Sin registro en la <b>fecha seleccionada</b>
              </Typography>
            </Box>
            )}
      </CardContent>
    </Card>
  )
}

export default GraficoVentaSbuLinear
