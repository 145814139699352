import ReturnsSbuUnit from './components/ReturnsSbuUnit'
import SalesSbuUnit from './components/SalesSbuUnit'

function PieCharts () {
  return (
    <>
      <SalesSbuUnit />
      <ReturnsSbuUnit />
    </>
  )
}

export default PieCharts
