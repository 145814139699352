import TableInsurances from './components/TableInsurances/TableInsurances'

const PoliciesPage = () => {
  return (
    <>
      <TableInsurances />
    </>
  )
}
export default PoliciesPage
