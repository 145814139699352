import AverageChannel from './components/AverageChannel'
import SalesByChannel from './components/SalesByChannel'
import UnitsByChannel from './components/UnitsByChannel'

function ChannelCharts () {
  return (
    <>
      <SalesByChannel />
      <UnitsByChannel />
      <AverageChannel />
    </>
  )
}

export default ChannelCharts
