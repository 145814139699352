import Typography from '@mui/material/Typography'
import TablePreventa from './components/Tables/TablePreventa'

const PreventaPage = () => {
  return (

    <div>
      <Typography
        variant='h1'
        color='textSecondary'
        className='mb-4'
        sx={{ color: '#000', fontWeight: '500px', fontSize: '1rem' }}
      >
        <b>Visualizacion productos de Preventa</b>
      </Typography>
      <TablePreventa />
    </div>

  )
}

export default PreventaPage
