export const remplaceCaracteresRaro = (cadena : string) => {
  // Los eliminamos todos
  // for (var i = 0; i < specialChars.length; i++) {
  // cadena= cadena.replace(new RegExp("\\" + specialChars[i], 'gi'), '');
  // }

  // Lo queremos devolver limpio en minusculas
  // cadena = cadena.toLowerCase();

  // Quitamos espacios y los sustituimos por _ porque nos gusta mas asi
  // cadena = cadena.replace(/ /g,"_");

  // Quitamos acentos y "ñ". Fijate en que va sin comillas el primer parametro

  cadena = cadena.replace(/á/gi, 'a')
  cadena = cadena.replace(/Á/gi, 'a')
  cadena = cadena.replace(/é/gi, 'e')
  cadena = cadena.replace(/É/gi, 'e')
  cadena = cadena.replace(/í/gi, 'i')
  cadena = cadena.replace(/Í/gi, 'i')
  cadena = cadena.replace(/ó/gi, 'o')
  cadena = cadena.replace(/Ó/gi, 'o')
  cadena = cadena.replace(/ú/gi, 'u')
  cadena = cadena.replace(/Ú/gi, 'u')
  cadena = cadena.replace(/ñ/gi, 'n')
  cadena = cadena.replace(/Ñ/gi, 'n')
  cadena = cadena.replace(/N°/gi, 'n')
  cadena = cadena.replace(/2°/gi, '2')
  cadena = cadena.replace(/°/gi, '')
  cadena = cadena.replace(/#/gi, '')
  return cadena
}

export function exportTableToExcel (
  tableID : string,
  nameReporter : string,
  filename = `${nameReporter}`
) {
  const BOM = '\uFEFF'
  const dataType = 'application/vnd.ms-excel'
  const tableSelect = document.getElementById(tableID)
  const tableHTML = (tableSelect) ? tableSelect.outerHTML : ''

  const base64Table = btoa(unescape(encodeURIComponent(BOM + tableHTML)))

  // Specify file name
  filename = filename ? filename + '.xls' : 'excel_data.xls'

  // Create download link element
  const downloadLink = document.createElement('a')

  document.body.appendChild(downloadLink)

  downloadLink.href = 'data:' + dataType + ';base64,' + base64Table

  // Setting the file name
  downloadLink.download = filename

  // triggering the function
  downloadLink.click()
}

export function ExportarExcel (tableID : string, nameReporter : string) {
  const uri = 'data:application/vnd.ms-excel;base64,'
  const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
  const base64 = function (s : any) {
    return window.btoa(unescape(encodeURIComponent(s)))
  }
  const format = function (s: any, c: any) {
    return s.replace(/{(\w+)}/g, function (m : any, p : any) {
      return c[p]
    })
  }

  const name = nameReporter
  const table = document.getElementById(tableID)
  const ctx = { worksheet: name || 'Worksheet', table: (table) ? table.innerHTML : '' }
  window.location.href = uri + base64(format(template, ctx))
}
