import React from 'react'
import { Card, CardHeader, Typography } from '@mui/material'
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  Label,
  LabelList
} from 'recharts'
import { DashboardBsaleContextType, useDashboardBsaleContext } from '../../../context/dashboardBsale.context'
import { CurrencyFormat } from '@utils/Currency.utility'
import { average, formatNumber, month } from '@utils/dashboardBsale'

function AverageSales () {
  const { ctx } = useDashboardBsaleContext() as DashboardBsaleContextType

  const orderSale = (sales: any) => {
    if (sales.some((ele: any) => ele.total_ventas_2024 === '0')) {
      const year2023:any = sales.filter((ele: any) => ele.total_ventas_2024 === '0')
      const year2024:any = sales.filter((ele: any) => ele.total_ventas_2024 !== '0')
      const newData = [...year2023, ...year2024].map((ele: any) => {
        return {
          ...ele,
          month: month(ele.month),
          total_ventas_2024: ele.total_ventas_2024 === '0' ? null : ele.total_ventas_2024,
          total_devoluciones_2024: ele.total_devoluciones_2024 === '0' ? null : ele.total_devoluciones_2024
        }
      })
      return newData
    } else {
      const newData = sales.map((ele: any) => {
        return {
          ...ele,
          month: month(ele.month)
        }
      })
      return newData
    }
  }

  const orderUnit = (sales: any) => {
    if (sales.some((ele: any) => ele.unidades_vendidas_2024 === 0)) {
      const year2023:any = sales.filter((ele: any) => ele.unidades_vendidas_2024 === 0)
      const year2024:any = sales.filter((ele: any) => ele.unidades_vendidas_2024 !== 0)
      const newData = [...year2023, ...year2024].map((ele: any) => {
        return {
          ...ele,
          month: month(ele.month),
          unidades_vendidas_2024: ele.unidades_vendidas_2024 === 0 ? null : ele.unidades_vendidas_2024,
          unidades_devueltas_2024: ele.unidades_devueltas_2024 === 0 ? null : ele.unidades_devueltas_2024
        }
      })

      return newData
    } else {
      const newData = sales.map((ele: any) => {
        return {
          ...ele,
          month: month(ele.month)
        }
      })
      return newData
    }
  }

  const dataSale = orderSale(ctx.totalSales)
  const dataUnit = orderUnit(ctx.unitSales)

  const years = ctx.totalSales.map(ele => {
    const asd = Object.keys(ele).filter(ele => ele.includes('ventas'))
    return asd
  }).pop()?.flatMap(ele => ele.split('_').slice(-1)).sort((a: any, b: any) => a - b)

  const colors: any = {
    2022: '#0473c8',
    2023: '#ff7300',
    2024: '#413ea0'
  }

  function generateData (dataSale: any, dataUnit: any) {
    const data = []
    for (let i = 0; i < dataSale.length; i++) {
      const monthData: any = {
        month: dataSale[i].month
      }

      const years = Object.keys(dataSale[i]).filter(key => key.includes('_20'))
      for (const year of years) {
        const yearValue = year.split('_')[2]
        const totalNetoKey = `total_neto_${yearValue}`
        const unidadesVendidasKey = `total_${yearValue}`

        if (dataSale[i][totalNetoKey] !== undefined && dataUnit[i][unidadesVendidasKey] !== undefined) {
          const value = parseInt(dataSale[i][totalNetoKey]) / parseInt(dataUnit[i][unidadesVendidasKey])
          monthData[yearValue] = isNaN(value) ? 0 : Math.floor(value)
        } else {
          monthData[yearValue] = 0
        }
      }
      data.push(monthData)
    }
    return data
  }

  const result = generateData(dataSale, dataUnit)

  const salesAverage = average(result, '2024')

  return (
    <Card sx={{ height: 400, marginTop: 3 }}>
      <CardHeader title={<Typography variant='h6'>Ticket promedio</Typography>} />
      <ResponsiveContainer width='100%' height='80%'>
        <ComposedChart
          width={500}
          height={400}
          data={result}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 50
          }}
        >
          <CartesianGrid stroke='#f5f5f5' />
          <XAxis dataKey='month' />
          <YAxis
            tickFormatter={formatNumber}
          />
          <Tooltip
            formatter={(value: any, name: any, props: any) => CurrencyFormat(value)}
          />
          <Legend />
          {salesAverage !== null && (
            <ReferenceLine y={salesAverage} stroke='#cccccc' strokeDasharray='3 3'>
              <Label value={`Avg: ${formatNumber(salesAverage)}`} position='insideLeft' />
            </ReferenceLine>
          )}
          {years && years.map(year => (
            <React.Fragment key={year}>
              <Bar dataKey={`${year}`} name={`Promedio ${year}`} fill={colors[year]} barSize={30} legendType='circle'>
                <LabelList dataKey={`${year}`} position='top' formatter={(value: any) => formatNumber(value)} fontSize={12} />
              </Bar>
            </React.Fragment>
          ))}

        </ComposedChart>
      </ResponsiveContainer>
    </Card>
  )
}

export default AverageSales
