import { Backdrop, Box, Card, CardContent, CircularProgress, Typography } from '@mui/material'
import GoalsTable from './components/GoalsTable'
import { DboardApi } from '@services/index'
import { useEffect, useState } from 'react'
import { state, originalState } from '@models/goalsBsale'
import { useSnackbar } from 'notistack'

const GoalsBsale = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [state, setState] = useState<state>(
    {
      weeks: [],
      yearGoal: 0,
      days: 364,
      monthsGoal: [],
      loading: false,
      saving: false,
      totalUsd: 0,
      currency: 'USD',
      canSave: false
    })

  const [originalState, setOriginalState] = useState<originalState>(
    {
      yearGoal: 0,
      weeks: [],
      monthsGoal: []
    })

  // console.log('state => ', state)
  // console.log('originalState => ', originalState)

  useEffect(() => {
    const getData = async () => {
      setState({ ...state, loading: true })
      try {
        const goals = await DboardApi.getGoals().then(res => res.data)
        // console.log('goals => ', goals)

        const organizedWeeks = goals.days.reduce((acc: any, ele: any) => {
          const obj = acc.find((ob: any) => ob.week === ele.week)
          if (obj) {
            obj.days.push(ele)
          } else {
            const weekGoal = goals.weeks.find((el: any) => el.week === ele.week)
            const newWeek: any = {
              week: ele.week,
              days: [ele]
            }
            if (weekGoal !== undefined) {
              newWeek.weeklyGoal = weekGoal.goal
            }
            acc.push(newWeek)
          }
          return acc
        }, [])

        const originalStateCopy = JSON.parse(JSON.stringify({
          yearGoal: goals.year.length === 0 ? 0 : goals.year[0].total,
          monthsGoal: goals.months,
          weeks: organizedWeeks
        }))

        setOriginalState(originalStateCopy)

        setState(prevState => ({
          ...prevState,
          yearGoal: goals.year.length === 0 ? 0 : goals.year[0].total,
          monthsGoal: goals.months,
          weeks: organizedWeeks,
          loading: false
        }))
      } catch (error) {
        console.log(error)
        setState(prevState => ({
          ...prevState,
          loading: false
        }))
        enqueueSnackbar('Hubo un error al cargar la información, por favor, vuelva a intentar.', { variant: 'error' })
      }
    }
    getData()
  }, []) // eslint-disable-line

  return (
    <>
      {state.loading
        ? (
          <Card>
            <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </CardContent>
          </Card>
          )
        : (
          <>
            <Typography variant='h1' color='textSecondary' className='mb-4' sx={{ color: '#000', fontWeight: '500px', fontSize: '1rem' }}>
              <b>Metas Bsale</b>
            </Typography>
            <Box my={2}>
              <Card>
                <CardContent>

                  <GoalsTable state={state} setState={setState} originalState={originalState} setOriginalState={setOriginalState} />

                </CardContent>
              </Card>
            </Box>

            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 2 }}
              open={state.saving}
            >
              <CircularProgress color='inherit' />
            </Backdrop>
          </>
          )}

    </>
  )
}

export default GoalsBsale
