
import { Outlet } from 'react-router-dom'

interface IOwnProps {
  permissions: boolean
  PublicValidationFragment: any
  }

const PrivateValidationFragment = <Outlet />

export const PermissionsGuard = ({ permissions, PublicValidationFragment }: IOwnProps) => {
  return permissions ? PrivateValidationFragment : PublicValidationFragment
}

export default PermissionsGuard
