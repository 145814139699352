import { DatePicker } from '@mui/x-date-pickers'
import { useMemo, useState } from 'react'

import { LoadingButton } from '@mui/lab'
import {
  Box, Button, Card,
  CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Table, TableBody, TableCell,
  TableContainer,
  TableHead,
  TableRow, Typography
} from '@mui/material'
import moment from 'moment'
import { DBOARD_FORMATEND, DBOARD_FORMATSMALL, DBOARD_FORMATSTART, DBOARD_MINDATE } from '../../../constants/dashboard.constants'
import { DboardApi } from '../../../services'
import { sharingInformationService } from '../../../utils'
import { exportTableToExcel } from '../../../utils/Excel.utility'
import { useDashboardContext } from '../context/dashboard.context'
import { ReactComponent as Excel } from '@assets/svg/excel.svg'

const TablePedidosPorRegiones = () => {
  const { ctx } = useDashboardContext()
  const [data, setData] = useState <{ dataOrdersZone: any[], idSeller: number, dateStart: string, dateEnd: string, loading: boolean }>({ dataOrdersZone: [], idSeller: ctx.idSeller, dateStart: ctx.dateStart, dateEnd: ctx.dateEnd, loading: false })

  const subscription$ = sharingInformationService.getSubject()

  const loadData = (_dateStart: string, _dateEnd: string, _idSeller: number) => {
    setData({ ...data, loading: true })
    DboardApi.getOrdersZone(_dateStart, _dateEnd, _idSeller).then((response) => {
      setData({ ...data, dataOrdersZone: response.items, dateStart: _dateStart, dateEnd: _dateEnd, idSeller: _idSeller, loading: false })
    }).catch((error) => {
      console.log('TotalVentas loadData error:', error)
      setData({ ...data, loading: false })
    })
  }

  useMemo(() => {
    loadData(moment(data.dateStart).format(DBOARD_FORMATSTART), moment(data.dateEnd).format(DBOARD_FORMATEND), ctx.idSeller)
    subscription$.subscribe((info: any) => {
      loadData(moment(info.dateStart).format(DBOARD_FORMATSTART), moment(info.dateEnd).format(DBOARD_FORMATEND), info.idSeller)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card>
      <CardContent>
        <Box sx={{ p: 1, pb: 0 }}>
          <Typography
            variant='h5'
            color='textSecondary'
            sx={{ color: '#000', fontWeight: '500px', fontSize: '1rem' }}
          >
            Unidades vendidas por <b>Zona</b>
          </Typography>
          {data.dataOrdersZone && data.dataOrdersZone.length > 0
            ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'right',
                  justifyContent: 'right'
                }}
              >
                <Button
                  variant='contained'
                  color='success'
                  size='small'
                  onClick={() =>
                    exportTableToExcel('idPedidosRegiones', 'Orders por zona')}
                >
                  <Excel className='me-2' /> Exportar
                </Button>
              </Box>
              )
            : (
                ''
              )}

          <Grid container direction='row' spacing={2} my={1}>
            <Grid item xs={12} md={4} lg={4} xl={4}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>Seller</InputLabel>
                <Select
                  value={data.idSeller}
                  label='Seller'
                  onChange={(e) => setData({ ...data, idSeller: parseInt(e.target.value.toString()) })}
                  size='small'
                >

                  {ctx.dataListSeller &&
                        ctx.dataListSeller.map((row : any, i : number) => (
                          <MenuItem value={row.idSeller} key={i + 1}>
                            {row.nameSeller}
                          </MenuItem>
                        ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4} lg={3} xl={3}>
              <DatePicker
                label='Fecha Desde'
                disableFuture
                minDate={DBOARD_MINDATE}
                format='dd-MM-yyyy'
                value={moment(data.dateStart).toDate()}
                onChange={(newValue) => {
                  setData({ ...data, dateStart: moment(newValue).format(DBOARD_FORMATSTART) })
                }}
                slotProps={{
                  textField: {size: "small", fullWidth: true}
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3} xl={3}>
              <DatePicker
                label='Fecha Hasta'
                disableFuture
                minDate={moment(data.dateStart).toDate()}
                format='dd-MM-yyyy'
                value={moment(data.dateEnd).toDate()}
                onChange={(newValue) => {
                  setData({ ...data, dateEnd: moment(newValue).format(DBOARD_FORMATEND) })
                }}
                slotProps={{
                  textField: {size: "small", fullWidth: true}
                }}
              />
            </Grid>
            <Grid item xs={2} md={2} lg={2} xl={2}>
              <Box>
                <LoadingButton
                  variant='contained'
                  color='primary'
                  onClick={() => loadData(data.dateStart, data.dateEnd, data.idSeller)}
                  disabled={!moment(data.dateEnd).isAfter(data.dateStart)}
                  loading={data.loading}
                >
                  Filtrar
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box my={2}>
          <TableContainer sx={{ maxHeight: '500px' }}>
            <Table className='table table-border' size='small'>
              <TableHead>
                <TableRow>
                  <TableCell align='left' width='250px'>
                    Seller
                  </TableCell>
                  <TableCell
                    align='center'
                    width='250px'
                    style={{ background: 'linear-gradient(to left top, #4676b9, #7c3c91)', color: '#ffff' }}
                  >
                    Región Metropolitana
                  </TableCell>
                  <TableCell
                    align='center'
                    width='250px'
                    style={{ backgroundColor: '#636363', color: '#ffff' }}
                  >
                    Regiones
                  </TableCell>
                  <TableCell
                    align='center'
                    className='p-3 mb-2'
                    width='250px'
                  >
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              {data.dataOrdersZone && data.dataOrdersZone.length > 0
                ? (
                  <TableBody>
                    {data.dataOrdersZone &&
                      data.dataOrdersZone.map((row, i) => (
                        <TableRow hover key={i + 1}>
                          <TableCell align='left' width='250px'>
                            {row.nameSeller}
                          </TableCell>
                          <TableCell align='center' width='250px'>
                            {row.santiago > 0 ? row.santiago : 0}
                          </TableCell>
                          <TableCell align='center' width='250px'>
                            {row.regiones > 0 ? row.regiones : 0}
                          </TableCell>
                          <TableCell align='center' width='250px'>
                            {row.cont > 0 ? row.cont : 0}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  )
                : (
                  <TableBody>
                    <TableRow hover>
                      <TableCell align='left' colSpan={4}>
                        <Typography
                          variant='h5'
                          color='textSecondary'
                          sx={{ color: '#000', fontWeight: '500px' }}
                        >
                          Sin pedidos aún para zonas{' '}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  )}
            </Table>
          </TableContainer>

          {/** excel  */}

          <Table
            className='table table-border'
            size='small'
            id='idPedidosRegiones'
            border={1}
            hidden
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    color: '#000',
                    padding: 10,
                    textAlign: 'left'
                  }}
                >
                  Fecha
                </TableCell>
                <TableCell colSpan={3}>
                  {moment(data.dateStart).format(DBOARD_FORMATSMALL)} - {' '}
                  {moment(data.dateEnd).format(DBOARD_FORMATSMALL)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                <TableCell align='left' width='250px'>
                  Seller
                </TableCell>
                <TableCell
                  align='center'
                  className='p-3 mb-2 bg-primary text-white'
                  width='250px'
                >
                  Región Metropolitana
                </TableCell>
                <TableCell
                  align='center'
                  className='p-3 mb-2 bg-secondary text-white'
                  width='250px'
                >
                  Regiones
                </TableCell>
                <TableCell align='center' className='p-3 mb-2' width='250px'>
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.dataOrdersZone &&
                  data.dataOrdersZone.map((row, i) => (
                    <TableRow hover key={i + 1}>
                      <TableCell align='left' width='250px'>
                        {row.nameSeller}
                      </TableCell>
                      <TableCell align='center' width='250px'>
                        {row.santiago > 0 ? row.santiago : 0}
                      </TableCell>
                      <TableCell align='center' width='250px'>
                        {row.regiones > 0 ? row.regiones : 0}
                      </TableCell>
                      <TableCell align='center' width='250px'>
                        {row.cont > 0 ? row.cont : 0}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </Box>
      </CardContent>
    </Card>
  )
}

export default TablePedidosPorRegiones
