import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer, TablePagination,
  TableRow,
  Typography
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { DBOARD_FORMATEND, DBOARD_FORMATSTART, DBOARD_MINDATE } from '../../../../constants/dashboard.constants'
import { DboardApi } from '../../../../services'
import { exportTableToExcel, sharingInformationService } from '../../../../utils'
import { useDashboardContext } from '../../context/dashboard.context'
import EnhancedTableHead from './EnhancedTableHead'
import EnhancedTableHeadExcel from './EnhancedTableHeadExcel'
import { ReactComponent as Excel } from '@assets/svg/excel.svg'

const TableRefunds = () => {
  const { ctx } = useDashboardContext()
  const [data, setData] = useState <{ dataGetRefundNote: any[], reason: string, nameSeller: any, totalMonto: number, totalMontoFinal: number, totalDevoluciones: number, totalMontoDevoluciones: number, totalPedidos: number, page: number, rowsPerPage: number, order: string, orderBy: string, idSeller: number, dateStart: string, dateEnd: string, loading: boolean }>({ dataGetRefundNote: [], reason: 'Todos', nameSeller: '', totalMontoFinal: 0, totalMonto: 0, totalDevoluciones: 0, totalMontoDevoluciones: 0, totalPedidos: 0, page: 0, rowsPerPage: 10, order: 'asc', orderBy: '', idSeller: ctx.idSeller, dateStart: ctx.dateStart, dateEnd: ctx.dateEnd, loading: false })
  const subscription$ = sharingInformationService.getSubject()

  const handleChangePage = (event: any, newPage : number) => {
    setData({ ...data, page: newPage })
  }

  const handleChangeRowsPerPage = (event: any) => {
    setData({ ...data, rowsPerPage: parseInt(event.target.value, 10), page: 0 })
  }

  const handleRequestSort = (event: any, property : any) => {
    const isAsc = data.orderBy === property && data.order === 'asc'
    setData({ ...data, order: isAsc ? 'desc' : 'asc', orderBy: property })
  }

  const stableSort = (array: any[], comparator: any) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  const getComparator = (order: string, orderBy : string) => {
    return order === 'desc'
      ? (a: any, b: any) => descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy)
  }

  const descendingComparator = (a: any, b: any, orderBy : string) => {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const loadData = (_dateStart: string, _dateEnd: string, _idSeller: number) => {
    setData({ ...data, loading: true })
    const datos = {
      date_init: _dateStart,
      date_end: _dateEnd,
      id_seller: _idSeller,
      note: data.reason
    }
    DboardApi.getRefundNote(datos).then((response) => {
      console.log('response =>', response)
      setData({ ...data, dataGetRefundNote: response.items, dateStart: _dateStart, dateEnd: _dateEnd, loading: false })
    }).catch((error) => {
      console.log('TotalVentas loadData error:', error)
      setData({ ...data, loading: false })
    })
  }

  const handleExportExcel = (IdTable: string) => {
    exportTableToExcel(IdTable, 'Tabla Reembolsos')
  }

  useMemo(() => {
    loadData(moment(data.dateStart).format(DBOARD_FORMATSTART), moment(data.dateEnd).format(DBOARD_FORMATEND), data.idSeller)
    subscription$.subscribe((info: any) => {
      loadData(moment(info.dateStart).format(DBOARD_FORMATSTART), moment(info.dateEnd).format(DBOARD_FORMATEND), info.idSeller)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  console.log('data.reason', data.reason)
  return (
    <Card>
      <CardContent>
        <Box sx={{ p: 1, pb: 0 }}>
          <Typography
            variant='h5'
            color='textSecondary'
            sx={{ color: '#000', fontWeight: '500px', fontSize: '1rem' }}
          >
            Causa de devolución por <b>Seller</b>
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'right',
              justifyContent: 'right'
            }}
          >
            <Button
              variant='contained'
              color='success'
              size='small'
              onClick={() => handleExportExcel('refunds')}
            >
              <Excel className='me-2' /> Exportar
            </Button>
          </Box>
          <Grid container direction='row' spacing={2} my={1}>
            <Grid item xs={12} md={2} lg={2} xl={2}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>Seller</InputLabel>
                <Select
                  value={data.idSeller}
                  label='Seller'
                  onChange={(e) => setData({ ...data, idSeller: parseInt(e.target.value.toString()), nameSeller: e.target.name })}
                  size='small'
                >

                  {ctx.dataListSeller &&
                        ctx.dataListSeller.map((row : any, i : number) => (
                          <MenuItem value={row.idSeller} key={i + 1}>
                            {row.nameSeller}
                          </MenuItem>
                        ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3} lg={3} xl={3}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>Causas</InputLabel>
                <Select
                  value={data.reason}
                  label='Causas'
                  onChange={(e) => setData({ ...data, reason: String(e.target.value) })}
                  size='small'
                >
                  <MenuItem value='Todos'>
                    Todos
                  </MenuItem>
                  <MenuItem value='Producto incompleto.'>
                    Producto incompleto.
                  </MenuItem>
                  <MenuItem value='Producto presenta falla técnica.'>
                    Producto presenta falla técnica.
                  </MenuItem>
                  <MenuItem value='Producto sin stock.'>
                    Producto sin stock.
                  </MenuItem>
                  <MenuItem value='Cambio de equipo por uno de menor valor.'>
                    Cambio de equipo por uno de menor valor.
                  </MenuItem>
                  <MenuItem value='El cliente solicita el reembolso dentro de los 30 días de desistimiento.'>
                    El cliente solicita el reembolso dentro de los 30 días de desistimiento.
                  </MenuItem>
                  <MenuItem value='El cliente solicita cancelar la orden (previo al despacho).'>
                    El cliente solicita cancelar la orden (previo al despacho).
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4} lg={3} xl={3}>
              <DatePicker
                label='Fecha Desde'
                disableFuture
                minDate={DBOARD_MINDATE}
                format='dd-MM-yyyy'
                value={moment(data.dateStart).toDate()}
                onChange={(newValue) => {
                  setData({ ...data, dateStart: moment(newValue).format(DBOARD_FORMATSTART) })
                }}
                slotProps={{
                  textField: {size: "small"}
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3} xl={3}>
              <DatePicker
                label='Fecha Hasta'
                disableFuture
                minDate={moment(data.dateStart).toDate()}
                format='dd-MM-yyyy'
                value={moment(data.dateEnd).toDate()}
                onChange={(newValue) => {
                  setData({ ...data, dateEnd: moment(newValue).format(DBOARD_FORMATEND) })
                }}
                slotProps={{
                  textField: {size: "small"}
                }}
              />
            </Grid>
            <Grid item xs={2} md={2} lg={1} xl={1}>
              <Box>
                <LoadingButton
                  variant='contained'
                  color='primary'
                  onClick={() => loadData(data.dateStart, data.dateEnd, data.idSeller)}
                  disabled={!moment(data.dateEnd).isAfter(data.dateStart)}
                  loading={data.loading}
                >
                  Filtrar
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container rowSpacing={2.5} columnSpacing={2.75}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TableContainer sx={{ maxHeight: '450px' }}>
                <Table size='small' border={1} stickyHeader>
                  <EnhancedTableHead
                    numSelected={0}
                    order={data.order}
                    orderBy={data.orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={data.dataGetRefundNote.length}
                  />
                  {data.dataGetRefundNote &&
                data.dataGetRefundNote.length !== 0
                    ? (
                      <TableBody>
                        {stableSort(
                          data.dataGetRefundNote,
                          getComparator(data.order, data.orderBy)
                        )
                          .slice(
                            data.page * data.rowsPerPage,
                            data.page * data.rowsPerPage + data.rowsPerPage
                          )
                          .filter(e => e.status !== 'voided')
                          .map((row, index) => {
                            return (
                              <TableRow
                                hover
                                role='checkbox'
                                tabIndex={-1}
                                key={index + 1}
                                selected={false}
                              >
                                <TableCell align='center' width='50px'>
                                  {row.seller}
                                </TableCell>
                                <TableCell align='center' width='50px'>
                                  {row.id_order}
                                </TableCell>
                                <TableCell align='center' width='50px' style={{ textAlign: 'left' }}>
                                  {row.note_description}
                                </TableCell>
                              </TableRow>
                            )
                          })}
                      </TableBody>
                      )
                    : (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={6}>
                            <Typography
                              variant='h5'
                              color='textSecondary'
                              sx={{ color: '#000', fontWeight: '500px' }}
                            >
                              Sin registro en la fecha seleccionada{' '}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                      )}
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[10, 20, 50, 100]}
                component='div'
                count={data.dataGetRefundNote.length}
                rowsPerPage={data.rowsPerPage}
                page={data.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>

        </Box>
        {/**  table excel **/
          <Box id='refunds' hidden>
            <p style={{ fontSize: '20px' }}>Tabla <strong>Reembolsos</strong></p>

            <Table size='small' border={1}>
              <EnhancedTableHeadExcel
                numSelected={0}
                order={data.order}
                orderBy={data.orderBy}
                onRequestSort={handleRequestSort}
                rowCount={data.dataGetRefundNote.length}
              />
              {data.dataGetRefundNote && data.dataGetRefundNote.length > 0
                ? (
                  <TableBody>
                    {data.dataGetRefundNote.map((row, index) => {
                      const evenStyle = { background: '' }
                      if (index % 2 === 0) {
                        evenStyle.background = '#f7f7ff'
                      }
                      return (
                        <TableRow
                          hover
                          role='checkbox'
                          tabIndex={-1}
                          key={index + 1}
                          selected={false}
                        >
                          <TableCell align='center' width='50px'>
                            {row.seller}
                          </TableCell>
                          <TableCell align='center' width='50px'>
                            {row.id_order}
                          </TableCell>
                          <TableCell align='center' width='50px' style={{ textAlign: 'left' }}>
                            {row.note_description}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                  )
                : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Typography
                          variant='h5'
                          color='textSecondary'
                          sx={{ color: '#000', fontWeight: '500px' }}
                        >
                          Sin registro en la fecha seleccionada
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  )}
            </Table>
          </Box>
        }
      </CardContent>
    </Card>
  )
}

export default TableRefunds
