import TotalOfReturns from './components/TotalOfReturns'
import TypeOfReturns from './components/TypeOfReturns'

function ReturnsCharts () {
  return (
    <>
      <TotalOfReturns />
      <TypeOfReturns />
    </>
  )
}

export default ReturnsCharts
