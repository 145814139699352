import { Card, CardHeader, Typography, Grid } from '@mui/material'
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  ComposedChart,
  ReferenceLine,
  Label,
  LabelList
} from 'recharts'
import moment from 'moment'
import { CurrencyFormat } from '@utils/Currency.utility'
import { average, month } from '@utils/dashboardBsale'
import { DashboardBsaleContextType, useDashboardBsaleContext } from '@pages/DashboardBsale/context/dashboardBsale.context'

const currentYear = moment(new Date()).format('YYYY')

function Sales () {
  const { ctx } = useDashboardBsaleContext() as DashboardBsaleContextType
  const salesAverage = average(ctx.salesQuarter, `total_neto_${currentYear}`)

  const order = (sales: any) => {
    const newData = sales.map((ele: any) => {
      return ({
        ...ele,
        month: month(ele.fecha)
      })
    })
    return newData
  }

  const lastDays = (sales: any) => {
    const result = sales.map((ele: any) => {
      return ({
        ...ele,
        total_devoluciones_current: ele.total_devoluciones_current === null ? 0 : ele.total_devoluciones_current,
        total_devoluciones_last_year: ele.total_devoluciones_last_year === null ? 0 : ele.total_devoluciones_last_year,
        fecha: ele.fecha_current.split('T')[0].split('-')[2]
      })
    })
    return result
  }

  function formatNumber (value:any) {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(0) + 'M'
    } else if (value <= 1000000) {
      return (value / 1000).toFixed(0) + 'K'
    }
    return value
  }

  const maxSales = Math.max(
    ...ctx?.salesQuarter.map((sale: any) => Math.max(parseFloat(sale.total_neto_2023), parseFloat(sale.total_neto_2024)))
  )

  const maxSalesLastDays = Math.max(
    ...ctx?.salesLastDays.map((sale: any) => Math.max(parseFloat(sale.total_neto_2023), parseFloat(sale.total_neto_2024)))
  )

  const unitAverage = average(ctx.salesLastDays, `total_neto_${currentYear}`)

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item md={12} lg={6}>
        <Card sx={{ height: 400 }}>
          <CardHeader title={<Typography variant='h6'>Venta - Trimestre actual (últimos 3 meses)</Typography>} />
          <ResponsiveContainer width='100%' height='80%'>
            <ComposedChart
              width={500}
              height={300}
              data={ctx && order(ctx.salesQuarter)}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid stroke='#f5f5f5' />
              <XAxis dataKey='month' />
              <YAxis
                domain={[0, maxSales + (maxSales * 0.1)]}
                tickFormatter={formatNumber}
              />
              <Tooltip
                formatter={(value: any, name: any, props: any) => CurrencyFormat(value)}
              />
              <Legend
                payload={[
                  { value: `Total ${Number(currentYear) - 1}`, type: 'circle', id: `total ${Number(currentYear) - 1}`, color: '#ff7300' },
                  { value: `Devoluciones ${Number(currentYear) - 1}`, type: 'line', id: `devoluciones ${Number(currentYear) - 1}`, color: '#ff7300' },
                  { value: `Total ${currentYear}`, type: 'circle', id: `total ${currentYear}`, color: '#413ea0' },
                  { value: `Devoluciones ${currentYear}`, type: 'line', id: `devoluciones ${currentYear}`, color: '#413ea0' }
                ]}
              />
              {salesAverage !== null && (
                <ReferenceLine y={salesAverage} stroke='#cccccc' strokeDasharray='3 3'>
                  <Label value={`Avg: ${formatNumber(salesAverage)}`} position='insideLeft' />
                </ReferenceLine>
              )}

              <Bar dataKey='total_vendido_last_year' name={`Ventas ${Number(currentYear) - 1}`} fill='#ff7300' barSize={-4} legendType='none' display='none' />
              <Line type='monotone' dataKey='total_devoluciones_last_year' name={`Devoluciones ${Number(currentYear) - 1}`} stroke='#ff7300' />
              <Bar dataKey={`total_neto_${Number(currentYear) - 1}`} name={`Total ${Number(currentYear) - 1}`} fill='#ff7300' barSize={30} legendType='circle'>
                <LabelList dataKey={`total_neto_${Number(currentYear) - 1}`} position='top' formatter={(value: any) => formatNumber(value)} fontSize={12} />
              </Bar>

              <Bar dataKey='total_vendido_current' name={`Ventas ${currentYear}`} fill='#413ea0' barSize={-4} legendType='none' display='none' />
              <Line type='monotone' dataKey='total_devoluciones_current' name={`Devoluciones ${currentYear}`} stroke='#413ea0' />
              <Bar dataKey={`total_neto_${currentYear}`} name={`Total ${currentYear}`} fill='#413ea0' barSize={30} legendType='circle'>
                <LabelList dataKey={`total_neto_${currentYear}`} position='top' formatter={(value: any) => formatNumber(value)} fontSize={12} />
              </Bar>
            </ComposedChart>
          </ResponsiveContainer>
        </Card>
      </Grid>
      <Grid item md={12} lg={6}>
        <Card sx={{ height: 400 }}>
          <CardHeader title={<Typography variant='h6'>Venta - Últimos 14 dias</Typography>} />
          <ResponsiveContainer width='100%' height='80%'>
            <ComposedChart
              width={500}
              height={300}
              data={lastDays(ctx.salesLastDays)}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid stroke='#f5f5f5' />
              <XAxis dataKey='fecha' />
              <YAxis
                domain={[0, maxSalesLastDays]}
                tickFormatter={formatNumber}
              />
              <Tooltip
                formatter={(value: any, name: any, props: any) => CurrencyFormat(value)}
              />
              <Legend
                payload={[
                  { value: `Total ${Number(currentYear) - 1}`, type: 'circle', id: `total ${Number(currentYear) - 1}`, color: '#ff7300' },
                  { value: `Devoluciones ${Number(currentYear) - 1}`, type: 'line', id: `devoluciones ${Number(currentYear) - 1}`, color: '#ff7300' },
                  { value: `Total ${currentYear}`, type: 'circle', id: `total ${currentYear}`, color: '#413ea0' },
                  { value: `Devoluciones ${currentYear}`, type: 'line', id: `devoluciones ${currentYear}`, color: '#413ea0' }
                ]}
              />
              {unitAverage !== null && (
                <ReferenceLine y={unitAverage} stroke='#cccccc' strokeDasharray='3 3'>
                  <Label value={`Avg: ${formatNumber(unitAverage)}`} position='left' fontSize={14} />
                </ReferenceLine>
              )}

              <Bar dataKey='total_vendido_last_year' name={`Ventas ${Number(currentYear) - 1}`} fill='#ff7300' barSize={-4} legendType='none' display='none' />
              <Line type='monotone' dataKey='total_devoluciones_last_year' name={`Devoluciones ${Number(currentYear) - 1}`} stroke='#ff7300' />
              <Bar dataKey={`total_neto_${Number(currentYear) - 1}`} name={`Total ${Number(currentYear) - 1}`} fill='#ff7300' barSize={10} legendType='circle' />

              <Bar dataKey='total_vendido_current' name={`Ventas ${currentYear}`} fill='#413ea0' barSize={-4} legendType='none' display='none' />
              <Line type='monotone' dataKey='total_devoluciones_current' name={`Devoluciones ${currentYear}`} stroke='#413ea0' />
              <Bar dataKey={`total_neto_${currentYear}`} name={`Total ${currentYear}`} fill='#413ea0' barSize={10} legendType='circle' />
            </ComposedChart>
          </ResponsiveContainer>
        </Card>
      </Grid>
    </Grid>
  )
}

export default Sales
