import Box from '@mui/material/Box/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { TableContainer, Table, TableHead, TableRow, TableBody, Paper, Pagination, Grid, Button } from '@mui/material'

import React, { useEffect, useState } from 'react'
import { DboardApi } from '@services/index'
import moment from 'moment'
import { exportTableToExcel } from '@utils/Excel.utility'
import { ReactComponent as Excel } from '@assets/svg/excel.svg'

const ITEMS_PER_PAGE = 10

const TablePreventa = () => {
  const [data, setData] = useState<{ currentPage: number, totalPages: number, currentData: any[], loading: boolean, listPreventa: any[] }>({ listPreventa: [], currentPage: 1, totalPages: 1, currentData: [], loading: false })

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    const startIndex = (value - 1) * ITEMS_PER_PAGE
    const endIndex = startIndex + ITEMS_PER_PAGE
    const paginatedData = data.listPreventa.slice(startIndex, endIndex)
    setData({ ...data, loading: false, currentData: paginatedData, currentPage: value })
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    }
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }))

  const loadData = () => {
    setData({ ...data, loading: true })
    DboardApi.getAllPreventa().then((response) => {
      if (response.success) {
        const datosBD = response.data.reverse()
        const startIndex = (data.currentPage - 1) * ITEMS_PER_PAGE
        const endIndex = startIndex + ITEMS_PER_PAGE
        const paginatedData = datosBD.slice(startIndex, endIndex)
        setData({ ...data, loading: false, listPreventa: datosBD, currentData: paginatedData, totalPages: Math.ceil(datosBD.length / ITEMS_PER_PAGE) })
      } else {
        setData({ ...data, loading: false })
      }
    }).catch((error) => {
      console.log('loadData error:', error)
      setData({ ...data, loading: false })
    })
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box my={2}>
      <Card>
        <CardContent>
          <Grid item xs={12} md={12} lg={3} xl={3}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'right',
                justifyContent: 'right',
                marginBottom: '10px'
              }}
            >
              <Button
                variant='contained'
                color='success'
                size='small'
                onClick={() =>
                  exportTableToExcel(
                    'idTablePreventa',
                    'Clicks Preventa'
                  )}
                disabled={data.currentData.length === 0}
              >
                <Excel className='me-2' /> Exportar
              </Button>
            </Box>
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label='customized table' id='idTableTradeinSales'>
              <TableHead>
                <TableRow>
                  <StyledTableCell align='left'>id</StyledTableCell>
                  <StyledTableCell align='left'>Usuario</StyledTableCell>
                  <StyledTableCell align='left'>Email</StyledTableCell>
                  <StyledTableCell align='left'>Producto</StyledTableCell>
                  <StyledTableCell align='left'>SKU</StyledTableCell>
                  <StyledTableCell align='left'>Fue comprado</StyledTableCell>
                  <StyledTableCell align='left'>N° Orden Shopify</StyledTableCell>
                  <StyledTableCell align='left'>Fecha Click</StyledTableCell>
                  <StyledTableCell align='left'>Fecha Compra</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.currentData.map((row, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell align='left'>{row.id}</StyledTableCell>
                    <StyledTableCell align='left'>{row.usuario}</StyledTableCell>
                    <StyledTableCell align='left'>{row.email}</StyledTableCell>
                    <StyledTableCell align='left'>{row.product_name}</StyledTableCell>
                    <StyledTableCell align='left'>{row.product_sku}</StyledTableCell>
                    <StyledTableCell style={{ color: row.was_bought === 0 ? 'red' : 'green' }} align='left'>
                      {row.was_bought === 1 ? 'Comprado' : 'Sin comprar '}
                    </StyledTableCell>
                    <StyledTableCell component='th' scope='row'>
                      {row.was_bought === 1 ? ('#' + row.id_order_platform).toString() : ''}
                    </StyledTableCell>
                    <StyledTableCell align='left'>{moment(row.created_at.split('T')[0]).format('L')}</StyledTableCell>
                    <StyledTableCell align='left'>{moment(row.updated_at.split('T')[0]).format('L')}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={data.totalPages}
            page={data.currentPage}
            onChange={handlePageChange}
            color='primary'
            size='large'
            className='mt-3'
            style={{ marginTop: '1.5rem' }}
          />
        </CardContent>
      </Card>
      <TableContainer component={Paper} style={{ display: 'none' }} id='idTablePreventa'>
        <Table aria-label='hidden table'>
          <TableHead>
            <TableRow>
              <StyledTableCell align='left'>id</StyledTableCell>
              <StyledTableCell align='left'>Usuario</StyledTableCell>
              <StyledTableCell align='left'>Email</StyledTableCell>
              <StyledTableCell align='left'>Producto</StyledTableCell>
              <StyledTableCell align='left'>SKU</StyledTableCell>
              <StyledTableCell align='left'>Fue comprado</StyledTableCell>
              <StyledTableCell align='left'>N° Orden Shopify</StyledTableCell>
              <StyledTableCell align='left'>Fecha Click</StyledTableCell>
              <StyledTableCell align='left'>Fecha Compra</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.listPreventa.map((row, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell align='left'>{row.id}</StyledTableCell>
                <StyledTableCell align='left'>{row.usuario}</StyledTableCell>
                <StyledTableCell align='left'>{row.email}</StyledTableCell>
                <StyledTableCell align='left'>{row.product_name}</StyledTableCell>
                <StyledTableCell align='left'>{row.product_sku}</StyledTableCell>
                <StyledTableCell style={{ color: row.was_bought === 0 ? 'red' : 'green' }} align='left'>
                  {row.was_bought === 1 ? 'Comprado' : 'Sin comprar '}
                </StyledTableCell>
                <StyledTableCell component='th' scope='row'>
                  {row.was_bought === 1 ? ('#' + row.id_order_platform).toString() : ''}
                </StyledTableCell>
                <StyledTableCell align='left'>{moment(row.created_at.split('T')[0]).format('L')}</StyledTableCell>
                <StyledTableCell align='left'>{moment(row.updated_at.split('T')[0]).format('L')}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default TablePreventa
