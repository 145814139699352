import React, { useState } from 'react'
import { Grid, Card, Typography, Alert, CardContent, Box, Button } from '@mui/material'
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts'
import { COLORS, downloadExcelPie, recortar, renderCustomizedLabel } from '@utils/dashboardBsale'
import PieModal from './PieModal'
import { ReactComponent as Excel } from '@assets/svg/excel.svg'
import { DashboardBsaleContextType, useDashboardBsaleContext } from '@pages/DashboardBsale/context/dashboardBsale.context'

function ReturnsSbuUnit () {
  const { ctx } = useDashboardBsaleContext() as DashboardBsaleContextType
  const [modal, setModal] = useState({ open: false, data: '' })

  const dataSbu = ctx.returnsSbuUnit.returnsBySbu?.map((ele: any, index: number) => {
    return {
      name: `${ele.sbu_description} (${ele.total_retornos})`,
      value: Number(ele.total_retornos)
    }
  })

  const sbu = dataSbu && dataSbu.slice(0, 8)

  if (sbu && sbu.length > 7 && dataSbu.length > 0) {
    const value = dataSbu.slice(8).reduce((acc: any, ele: any) => ele.value + acc, 0)
    sbu.push({
      name: `Otros (${value})`,
      value,
      chart: 'returnsSbu'
    })
  }

  const dataUnit = ctx.returnsSbuUnit.returnsByUnit?.map((ele: any, index: number) => {
    return {
      name: `${recortar(ele.name, 40)} (${ele.quantity_returned})`,
      value: Number(ele.quantity_returned)
    }
  })

  const units = dataUnit.slice(0, 8)

  if (units.length > 7 && dataUnit.length > 0) {
    const value = dataUnit.slice(8).reduce((acc: any, ele: any) => ele.value + acc, 0)
    units.push({
      name: `Otros (${value})`,
      value,
      chart: 'returnsUnits'
    })
  }

  function handleLegendClick (data: any) {
    data.payload.chart === 'returnsSbu' && setModal({ open: true, data: dataSbu })
    data.payload.chart === 'returnsUnits' && setModal({ open: true, data: dataUnit })
  }

  return (
    <>
      {modal.open && <PieModal modal={modal} setModal={setModal} />}
      <Grid container spacing={2}>
        {ctx?.returnsSbuUnit.returnsBySbu &&
          <Grid item md={12} lg={6}>
            <Card sx={sbu.length > 0 ? { height: 400, marginTop: 3 } : { minHeight: 50, marginTop: 3 }}>
              <Box sx={{ padding: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant='h6'>Devoluciones por SBU</Typography>
                <Button
                  variant='contained'
                  color='success'
                  sx={{ padding: 0.5 }}
                  onClick={() => downloadExcelPie(ctx.returnsSbuUnit.returnsBySbu, 'returnSbu')}
                >
                  <Excel />
                </Button>
              </Box>
              {sbu.length > 0
                ? (
                  <ResponsiveContainer width='100%' height='80%'>
                    <PieChart>
                      <Pie
                        data={sbu}
                        cx='50%'
                        cy='50%'
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={150}
                        fill='#8884d8'
                        dataKey='value'
                      >
                        {sbu?.map((entry :any, index: number) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Legend
                        align='right' verticalAlign='middle' layout='vertical' iconType='circle'
                        onClick={handleLegendClick}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                  )
                : (
                  <CardContent sx={{ paddingTop: 0 }}>
                    <Alert severity='info'>No hay devoluciones.</Alert>
                  </CardContent>
                  )}
            </Card>
          </Grid>}
        <Grid item md={12} lg={ctx?.returnsSbuUnit.returnsBySbu ? 6 : 12}>
          <Card sx={units.length > 0 ? { height: 400, marginTop: 3 } : { minHeight: 50, marginTop: 3 }}>
            <Box sx={{ padding: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant='h6'>Devoluciones por Unidades</Typography>
              <Button
                variant='contained'
                color='success'
                sx={{ padding: 0.5 }}
                onClick={() => downloadExcelPie(ctx.returnsSbuUnit.returnsByUnit, 'returnUnits')}
              >
                <Excel />
              </Button>
            </Box>
            {units.length > 0
              ? (
                <ResponsiveContainer width='100%' height='80%'>
                  <PieChart>
                    <Pie
                      data={units}
                      cx='50%'
                      cy='50%'
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={150}
                      fill='#8884d8'
                      dataKey='value'
                    >
                      {units?.map((entry :any, index: number) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Legend
                      align='right' verticalAlign='middle' layout='vertical' iconType='circle'
                      onClick={handleLegendClick}
                    />
                  </PieChart>
                </ResponsiveContainer>
                )
              : (
                <CardContent sx={{ paddingTop: 0 }}>
                  <Alert severity='info'>No hay devoluciones.</Alert>
                </CardContent>
                )}
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default ReturnsSbuUnit
