import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { state, originalState } from '@models/goalsBsale' // Ajusta la ruta según tu estructura de archivos

interface HeaderState {
  lastClickedButton: {
    booleanValue: boolean;
    stateCopy: state;
    originalState: originalState | undefined;
  } | null;
}

const initialState: HeaderState = {
  lastClickedButton: null
}

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    clickedHeaderButton: (state, action: PayloadAction<{ booleanValue: boolean; stateCopy: state; originalState: originalState | undefined}>) => {
      state.lastClickedButton = action.payload
    },
    resetLastClickedButton: (state) => {
      state.lastClickedButton = null
    }
  }
})

export const { clickedHeaderButton, resetLastClickedButton } = headerSlice.actions

export default headerSlice.reducer
