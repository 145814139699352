import { Card, CardHeader, Typography } from '@mui/material'
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart, ReferenceLine, Label, LabelList } from 'recharts'
import moment from 'moment'
import { CurrencyFormat } from '@utils/Currency.utility'
import { average, formatNumber } from '@utils/dashboardBsale'
import { DashboardBsaleContextType, useDashboardBsaleContext } from '@pages/DashboardBsale/context/dashboardBsale.context'

function AverageQuarter () {
  const { ctx } = useDashboardBsaleContext() as DashboardBsaleContextType
  const currentYear = moment(new Date()).format('YYYY')

  function avgSales (sales: any, unit: any): any {
    const result = sales.map((sale: any) => {
      const find = unit.find((unit: any) => unit.week === sale.week)
      return ({
        week: sale.week,
        avgCurrent: Number(sale.total_2024) && Number(find.total_2024) ? (sale.total_2024 / find?.total_2024) : 0,
        avgLastYear: Number(sale.total_2023) && Number(find.total_2023) ? (sale.total_2023 / find?.total_2023) : 0
      })
    })
    return result
  }

  const saleData = avgSales(ctx.salesPerWeek, ctx.unitsPerWeek)
  
  const salesAverage = average(saleData, 'avgCurrent')

  const maxSales = Math.max(
    ...saleData.map((sale: any) => Math.max(parseFloat(sale.avgLastYear), parseFloat(sale.avgCurrent)))
  )

  return (
    <Card sx={{ height: 400, marginTop: 3 }}>
      <CardHeader title={<Typography variant='h6'>Ticket promedio</Typography>} />
      <ResponsiveContainer width='100%' height='80%'>
        <ComposedChart
          width={500}
          height={300}
          data={saleData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid stroke='#f5f5f5' />
          <XAxis dataKey='week' />
          <YAxis
            domain={[0, maxSales + (maxSales * 0.1)]}
            tickFormatter={formatNumber}
          />
          <Tooltip
            formatter={(value: any, name: any, props: any) => CurrencyFormat(value)}
          />
          <Legend />
          {salesAverage !== null && (
            <ReferenceLine y={salesAverage} stroke='#cccccc' strokeDasharray='3 3'>
              <Label value={`Avg: ${formatNumber(salesAverage)}`} position='insideLeft' fontSize={10} />
            </ReferenceLine>
          )}
          <Bar dataKey='avgLastYear' name={`Promedio ${Number(currentYear) - 1}`} fill='#ff7300' barSize={15} legendType='circle'>
            <LabelList dataKey='avgLastYear' position='top' formatter={(value: any) => formatNumber(value)} fontSize={12} />
          </Bar>
          <Bar dataKey='avgCurrent' name={`Promedio ${currentYear}`} fill='#413ea0' barSize={15} legendType='circle'>
            <LabelList dataKey='avgCurrent' position='top' formatter={(value: any) => formatNumber(value)} fontSize={12} />
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </Card>
  )
}

export default AverageQuarter
