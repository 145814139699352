import { Box, Card, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { CurrencyFormat } from '@utils/Currency.utility'
import React, { useState } from 'react'
import { Bar, CartesianGrid, ComposedChart, Label, LabelList, Legend, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { DashboardBsaleContextType, useDashboardBsaleContext } from '../../../context/dashboardBsale.context'
import { average, colors, formatNumber } from '@utils/dashboardBsale'
import { DboardApi } from '@services/index'

function SalesPerWeek () {
  const { ctx, saveCtx } = useDashboardBsaleContext() as DashboardBsaleContextType
  const [hide, setHide] = useState<any>([])
  const years = ['2023', '2024']
  const salesAverage = average(ctx.salesPerWeek, 'total_2024')

  const handleFilter = async (e: any) => {
    const parameters = {
      from: ctx.filter.from,
      to: ctx.filter.to,
      channelId: ctx.filter.selectedChannel?.id === 0 ? null : ctx.filter.selectedChannel?.id,
      sbuId: ctx.filter.selectedSbu?.id === 0 ? null : ctx.filter.selectedSbu?.id,
      switch: e.target.checked != null ? e.target.checked : false,
      weekInitial: ctx.filter.weekInitial,
      weekEnd: e.target.value
    }
    const res = await DboardApi.getTotalSales(parameters).then(res => res.data)
    saveCtx({ ...res, filter: { ...ctx.filter, switch: e.target.checked != null ? !ctx.filter.switch : false, weekEnd: e.target.value } })
  }

  const maxSales = Math.max(
    ...ctx?.salesPerWeek.map((sale: any) => Math.max(parseFloat(sale.ventas_2023), parseFloat(sale.ventas_2024)))
  )

  const handleLenged = (e: any) => {
    if (hide.includes(e.id)) {
      const data = hide.filter((ele: any) => ele !== e.id)
      setHide(data)
    } else {
      setHide([...hide, e.id])
    }
  }

  const from = () => {
    const menuItems = []
    for (let i = 1; i <= 52; i++) {
      menuItems.push(<MenuItem value={i} key={i}>{i}</MenuItem>)
    }
    return menuItems
  }

  const to = () => {
    const totalWeek = ctx.filter.weekInitial >= 41 ? 52 - ctx.filter.weekInitial : 11
    const menuItems = []
    for (let i = ctx.filter.weekInitial; i <= ctx.filter.weekInitial + totalWeek; i++) {
      menuItems.push(<MenuItem value={i} key={i}>{i}</MenuItem>)
    }
    return menuItems
  }

  return (
    <Card sx={{ height: 400 }}>
      <Box sx={{ padding: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h6'>Venta por semana</Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Box sx={{ display: 'flex', width: 170, alignItems: 'center', gap: 1 }}>
            <Typography>Desde:</Typography>
            <FormControl fullWidth size='small'>
              <InputLabel>Semana</InputLabel>
              <Select
                size='small'
                value={ctx.filter.weekInitial || ''}
                label='Semana'
                onChange={(e: any) => saveCtx({ ...ctx, filter: { ...ctx.filter, weekInitial: e.target.value, weekEnd: 0 } })}
              >
                {from()}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', width: 170, alignItems: 'center', gap: 1 }}>
            <Typography>Hasta:</Typography>
            <FormControl fullWidth size='small'>
              <InputLabel>Semana</InputLabel>
              <Select
                disabled={ctx.filter.weekInitial === 0}
                size='small'
                value={ctx.filter.weekEnd || ''}
                label='Semana'
                onChange={(e: any) => handleFilter(e)}
              >
                {to()}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <ResponsiveContainer width='100%' height='80%'>
        <ComposedChart
          width={500}
          height={400}
          data={ctx.salesPerWeek}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20
          }}
        >
          <CartesianGrid stroke='#f5f5f5' />
          <XAxis dataKey='week' />
          <YAxis
            domain={!years?.every(year => hide.includes(`total_${year}`)) ? [0, maxSales] : undefined}
            tickFormatter={formatNumber}
          />
          <Tooltip
            formatter={(value: any, name: any, props: any) => CurrencyFormat(value)}
          />
          <Legend
            wrapperStyle={{ cursor: 'pointer' }}
            onClick={handleLenged}
            payload={years?.flatMap(year => ([
              { value: `Total ${year}`, type: 'circle', id: `total_${year}`, color: hide.includes(`total_${year}`) ? 'grey' : colors[year] },
              { value: `Devolución ${year}`, type: 'line', id: `devoluciones_${year}`, color: hide.includes(`devoluciones_${year}`) ? 'grey' : colors[year] }
            ]))}
          />
          {salesAverage !== null && (
            <ReferenceLine y={salesAverage} stroke='#cccccc' strokeDasharray='3 3'>
              <Label value={`Avg: ${formatNumber(salesAverage)}`} position='insideLeft' fontSize={10} />
            </ReferenceLine>
          )}
          {years.map(year => (
            <React.Fragment key={year}>
              <Bar dataKey={`ventas_${year}`} name={`Ventas ${year}`} fill={colors[year]} barSize={-4} legendType='none' display='none' hide={hide.includes(`total_${year}`)} />
              <Line type='monotone' dataKey={`devoluciones_${year}`} name={`Devolución ${year}`} stroke={colors[year]} hide={hide.includes(`devoluciones_${year}`)} />
              <Bar dataKey={`total_${year}`} name={`Total ${year}`} fill={colors[year]} barSize={15} legendType='circle' hide={hide.includes(`total_${year}`)}>
                <LabelList dataKey={`total_${year}`} position='top' formatter={(value: any) => formatNumber(value)} fontSize={12} />
              </Bar>
            </React.Fragment>
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </Card>
  )
}

export default SalesPerWeek
