import { LoadingButton } from '@mui/lab'
import { Box, Button, Modal, Typography } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { DboardApi } from '@services/index'
import { resetLastClickedButton } from 'src/store/states/header'
import { useNavigate } from 'react-router-dom'

type modalGoals = { open: boolean, path: string, loading: boolean }

interface ModalProps {
  modalGoals: modalGoals;
  setModalGoals: Dispatch<SetStateAction<modalGoals>>;
  unsavedChanges: any
  userState: any
}

function ModalSaveChanges ({ modalGoals, setModalGoals, unsavedChanges, userState }: ModalProps) {
  console.log('modalGoals => ', modalGoals)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()

  const handleSave = async () => {
    try {
      setModalGoals({ ...modalGoals, loading: true })
      const path = modalGoals.path
      await DboardApi.updateGoals({ ...unsavedChanges.stateCopy.state, userId: userState.id })

      enqueueSnackbar('Información guardada correctamente.', { variant: 'success' })
      setModalGoals({ open: false, path: '', loading: false })
      dispatch(resetLastClickedButton())
      navigate(path)
    } catch (error) {
      console.log(error)
      setModalGoals({ ...modalGoals, loading: false })
      enqueueSnackbar('Hubo un error al guarda, por favor, vuelva a intentar.', { variant: 'error' })
    }
  }

  const handleDiscard = () => {
    const path = modalGoals.path
    setModalGoals({ ...modalGoals, open: false, path: '' })
    navigate(path)
  }
  return (
    <Modal
      open={modalGoals.open}
      aria-labelledby='unsaved-changes-modal-title'
      aria-describedby='unsaved-changes-modal-description'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 1
        }}
      >
        <Typography id='unsaved-changes-modal-title' variant='h6' component='h2'>
          ¡Cambios sin guardar!
        </Typography>
        <Typography id='unsaved-changes-modal-description' sx={{ mt: 2 }}>
          Hay cambios sin guardar en tus metas. ¿Deseas guardar los cambios antes de continuar?
        </Typography>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
          <LoadingButton variant='contained' color='primary' size='small' onClick={handleSave} loading={modalGoals.loading}>
            Guardar
          </LoadingButton>
          <Button variant='contained' color='secondary' size='small' onClick={handleDiscard} disabled={modalGoals.loading}>
            Descartar cambios
          </Button>
          <Button variant='contained' color='inherit' size='small' onClick={() => setModalGoals({ ...modalGoals, open: false })} disabled={modalGoals.loading}>
            Cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default ModalSaveChanges
