import { Pagination } from '@mui/material'
import Box from '@mui/material/Box/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { TIContextType, useTIContext } from '@pages/TradeinTraffic/context/traffic.context'

import moment from 'moment'
import React, { useEffect, useState } from 'react'

const ITEMS_PER_PAGE = 10

const TableTradeInTraffic = () => {
  const { tiCtx } = useTIContext() as TIContextType
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [currentData, setCurrentData] = useState<any[]>([])

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value)
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    }
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }))

  const fetchTradeinData = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
    const endIndex = startIndex + ITEMS_PER_PAGE
    const paginatedData = tiCtx.listHistoryLog.slice(startIndex, endIndex)

    setTotalPages(Math.ceil(tiCtx.listHistoryLog.length / ITEMS_PER_PAGE))
    setCurrentData(paginatedData)
  }

  useEffect(() => {
    fetchTradeinData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, tiCtx.listHistoryLog])

  return (
    <Box my={2}>
      <Card>
        <CardContent>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label='customized table' id='idTableTradeinTraffic'>
              <TableHead>
                <TableRow>
                  <StyledTableCell align='left'>N°</StyledTableCell>
                  <StyledTableCell align='left'>Acción</StyledTableCell>
                  <StyledTableCell align='left'>Email</StyledTableCell>
                  <StyledTableCell align='left'>Nombre</StyledTableCell>
                  <StyledTableCell align='left'>Equipo intercambio</StyledTableCell>
                  <StyledTableCell align='left'>SKU producto</StyledTableCell>
                  <StyledTableCell align='left'>Fecha registro</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentData.map((row, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell component='th' scope='row'>
                      #{row.id}
                    </StyledTableCell>
                    <StyledTableCell align='left'>{
                    row.id_action === 1
                      ? 'Click en el modal'
                      : row.id_action === 2
                        ? 'Click en "Finalizar" del modal'
                        : 'Eliminó el Trade-in del carrito'
                    }
                    </StyledTableCell>
                    <StyledTableCell align='left'>{row.user_email === null ? 'Sin registro' : row.user_email}</StyledTableCell>
                    <StyledTableCell align='left'>{row.user_fullname === null ? 'Sin registro' : row.user_fullname}</StyledTableCell>
                    <StyledTableCell align='left'>{row.trade_model === null ? 'Sin registro' : row.trade_model}</StyledTableCell>
                    <StyledTableCell align='left'>{row.product_sku}</StyledTableCell>
                    <StyledTableCell align='left'>{moment(row.created_at.split('T')[0]).format('L')}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color='primary'
            size='large'
            className='mt-3'
          />
        </CardContent>
      </Card>
      <TableContainer component={Paper} style={{ display: 'none' }} id='idTableTradeinTrafficAll'>
        <Table aria-label='hidden table'>
          <TableHead>
            <TableRow>
              <StyledTableCell align='left'>N°</StyledTableCell>
              <StyledTableCell align='left'>Acción</StyledTableCell>
              <StyledTableCell align='left'>Email</StyledTableCell>
              <StyledTableCell align='left'>Nombre</StyledTableCell>
              <StyledTableCell align='left'>Equipo intercambio</StyledTableCell>
              <StyledTableCell align='left'>SKU producto</StyledTableCell>
              <StyledTableCell align='left'>Fecha registro</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tiCtx.listHistoryLog.map((row, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell component='th' scope='row'>
                  #{row.id}
                </StyledTableCell>
                <StyledTableCell align='left'>{
             row.id_action === 1
               ? 'Click en el modal'
               : row.id_action === 2
                 ? 'Click en "Finalizar" del modal'
                 : 'Eliminó el Trade-in del carrito'
             }
                </StyledTableCell>
                <StyledTableCell align='left'>{row.user_email === null ? 'Sin registro' : row.user_email}</StyledTableCell>
                <StyledTableCell align='left'>{row.user_fullname === null ? 'Sin registro' : row.user_fullname}</StyledTableCell>
                <StyledTableCell align='left'>{row.trade_model === null ? 'Sin registro' : row.trade_model}</StyledTableCell>
                <StyledTableCell align='left'>{row.product_sku}</StyledTableCell>
                <StyledTableCell align='left'>{moment(row.created_at.split('T')[0]).format('L')}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default TableTradeInTraffic
