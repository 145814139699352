import { Box, Card, CardContent, Grid, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material'
import NorthIcon from '@mui/icons-material/North'
import SouthIcon from '@mui/icons-material/South'
import { DashboardBsaleContextType, useDashboardBsaleContext } from '../context/dashboardBsale.context'
import { CurrencyFormat } from '@utils/Currency.utility'

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[10],
    fontSize: 30
  }
}))

function Cards () {
  const { ctx } = useDashboardBsaleContext() as DashboardBsaleContextType

  return (
    <Card>
      <CardContent>
        <Grid container direction='row' spacing={2} justifyContent='space-between'>
          <Grid item xs={12} md={12} lg={2} xl={2}>
            <Card sx={{
              background: 'linear-gradient(to left top, #4676b9, #7c3c91)',
              color: '#ffffff',
              height: 150,
              padding: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}
            >
              <Typography textAlign='center'>
                Unidades vendidas
              </Typography>
              <Box sx={{ borderBottom: 1, borderBottomColor: 'white', width: '80%', display: 'flex', justifyContent: 'center' }}>
                <Typography variant='h4'>
                  {ctx.cards.cantidad_ventas ? ctx.cards.cantidad_ventas : 0}
                </Typography>
              </Box>
              {ctx.cards.lastYear && ctx.cards.lastYear.comparativa_unidades_vendidas
                ? (
                    ctx.cards.lastYear.comparativa_unidades_vendidas?.includes('Mayor')
                      ? (
                        <Typography sx={{ display: 'flex', alignItems: 'center', color: 'greenyellow' }}>
                          <NorthIcon sx={{ width: 20 }} />
                          {ctx.cards.lastYear.comparativa_unidades_vendidas.match(/\(([^)]+)\)/)[1]}
                        </Typography>
                        )
                      : (
                        <Typography sx={{ display: 'flex', alignItems: 'center', color: '#b40707' }}>
                          <SouthIcon sx={{ width: 20 }} />
                          {ctx.cards.lastYear.comparativa_unidades_vendidas.match(/\(([^)]+)\)/)[1]}
                        </Typography>
                        )

                  )
                : (
                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    -
                  </Typography>)}
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={2} xl={2}>
            <Card sx={{
              background: 'linear-gradient(to left top, #4676b9, #7c3c91)',
              color: '#ffffff',
              height: 150,
              padding: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}
            >
              <Typography textAlign='center'>
                Unidades retornadas
              </Typography>
              <Box sx={{ borderBottom: 1, borderBottomColor: 'white', width: '80%', display: 'flex', justifyContent: 'center' }}>
                <Typography variant='h4'>
                  {ctx.cards.cantidad_devoluciones ? ctx.cards.cantidad_devoluciones : 0}
                </Typography>
              </Box>
              {ctx.cards.lastYear && ctx.cards.lastYear.comparativa_unidades_devueltas
                ? (
                    ctx.cards.lastYear.comparativa_unidades_devueltas?.includes('Mayor')
                      ? (
                        <Typography sx={{ display: 'flex', alignItems: 'center', color: '#b40707' }}>
                          <NorthIcon sx={{ width: 20 }} />
                          {ctx.cards.lastYear.comparativa_unidades_devueltas.match(/\(([^)]+)\)/)[1]}
                        </Typography>
                        )
                      : (
                        <Typography sx={{ display: 'flex', alignItems: 'center', color: 'greenyellow' }}>
                          <SouthIcon sx={{ width: 20 }} />
                          {ctx.cards.lastYear.comparativa_unidades_devueltas.match(/\(([^)]+)\)/)[1]}
                        </Typography>
                        )
                  )
                : (
                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    -
                  </Typography>)}
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={2} xl={2}>
            <Card sx={{
              background: 'linear-gradient(to left top, #4676b9, #7c3c91)',
              color: '#ffffff',
              height: 150,
              padding: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}
            >
              <Typography textAlign='center'>
                Total unidades
              </Typography>
              <Typography textAlign='center'>
                después de devolución
              </Typography>
              <Box sx={{ borderBottom: 1, borderBottomColor: 'white', width: '80%', display: 'flex', justifyContent: 'center' }}>
                <Typography variant='h4'>
                  {ctx.cards.total_unidades ? ctx.cards.total_unidades : 0}
                </Typography>
              </Box>
              {ctx.cards.lastYear && ctx.cards.lastYear.comparativa_unidades_menos_devoluciones
                ? (
                    ctx.cards.lastYear.comparativa_unidades_menos_devoluciones?.includes('Mayor')
                      ? (
                        <Typography sx={{ display: 'flex', alignItems: 'center', color: 'greenyellow' }}>
                          <NorthIcon sx={{ width: 20 }} />
                          {ctx.cards.lastYear.comparativa_unidades_menos_devoluciones.match(/\(([^)]+)\)/)[1]}
                        </Typography>
                        )
                      : (
                        <Typography sx={{ display: 'flex', alignItems: 'center', color: '#b40707' }}>
                          <SouthIcon sx={{ width: 20 }} />
                          {ctx.cards.lastYear.comparativa_unidades_menos_devoluciones.match(/\(([^)]+)\)/)[1]}
                        </Typography>
                        )

                  )
                : (
                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    -
                  </Typography>)}
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={2} xl={2}>
            <Card sx={{
              background: 'linear-gradient(to left top, #4676b9, #7c3c91)',
              color: '#ffffff',
              height: 150,
              padding: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}
            >
              <Typography textAlign='center'>
                Total ventas
              </Typography>
              <Box sx={{ borderBottom: 1, borderBottomColor: 'white', width: '80%', display: 'flex', justifyContent: 'center' }}>
                <LightTooltip title={CurrencyFormat(ctx.cards.total_ventas)} disableHoverListener={ctx.cards.total_ventas?.length < 9}>
                  <Typography variant='h4'>
                    {ctx.cards.total_ventas
                      ? ctx.cards.total_ventas.length > 8
                        ? CurrencyFormat(ctx.cards.total_ventas.slice(0, -6)) + 'M'
                        : CurrencyFormat(ctx.cards.total_ventas)
                      : 0}
                  </Typography>
                </LightTooltip>
              </Box>
              {ctx.cards.lastYear && ctx.cards.lastYear.comparativa_total_ventas
                ? (
                    ctx.cards.lastYear.comparativa_total_ventas?.includes('Mayor')
                      ? (
                        <Typography sx={{ display: 'flex', alignItems: 'center', color: 'greenyellow' }}>
                          <NorthIcon sx={{ width: 20 }} />
                          {ctx.cards.lastYear.comparativa_total_ventas.match(/\(([^)]+)\)/)[1]}
                        </Typography>
                        )
                      : (
                        <Typography sx={{ display: 'flex', alignItems: 'center', color: '#b40707' }}>
                          <SouthIcon sx={{ width: 20 }} />
                          {ctx.cards.lastYear.comparativa_total_ventas.match(/\(([^)]+)\)/)[1]}
                        </Typography>
                        )
                  )
                : (
                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    -
                  </Typography>)}
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={2} xl={2}>
            <Card sx={{
              background: 'linear-gradient(to left top, #4676b9, #7c3c91)',
              color: '#ffffff',
              height: 150,
              padding: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}
            >
              <Typography textAlign='center'>
                Total devoluciones
              </Typography>
              <Box sx={{ borderBottom: 1, borderBottomColor: 'white', width: '80%', display: 'flex', justifyContent: 'center' }}>
                <LightTooltip title={CurrencyFormat(ctx.cards.total_devoluciones)} disableHoverListener={ctx.cards.total_devoluciones?.length < 9}>
                  <Typography variant='h4'>
                    {ctx.cards.total_devoluciones
                      ? ctx.cards.total_devoluciones.length > 8
                        ? CurrencyFormat(ctx.cards.total_devoluciones.slice(0, -6)) + 'M'
                        : CurrencyFormat(ctx.cards.total_devoluciones)
                      : 0}
                  </Typography>
                </LightTooltip>
              </Box>
              {ctx.cards.lastYear && ctx.cards.lastYear.comparativa_total_devoluciones
                ? (
                    ctx.cards.lastYear.comparativa_total_devoluciones?.includes('Mayor')
                      ? (
                        <Typography sx={{ display: 'flex', alignItems: 'center', color: '#b40707' }}>
                          <NorthIcon sx={{ width: 20 }} />
                          {ctx.cards.lastYear.comparativa_total_devoluciones.match(/\(([^)]+)\)/)[1]}
                        </Typography>
                        )
                      : (
                        <Typography sx={{ display: 'flex', alignItems: 'center', color: 'greenyellow' }}>
                          <SouthIcon sx={{ width: 20 }} />
                          {ctx.cards.lastYear.comparativa_total_devoluciones.match(/\(([^)]+)\)/)[1]}
                        </Typography>
                        )
                  )
                : (
                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    -
                  </Typography>)}
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={2} xl={2}>
            <Card sx={{
              background: 'linear-gradient(to left top, #4676b9, #7c3c91)',
              color: '#ffffff',
              height: 150,
              padding: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}
            >
              <Typography textAlign='center'>
                Total venta
              </Typography>
              <Typography textAlign='center'>
                después de devolución
              </Typography>
              <Box sx={{ borderBottom: 1, borderBottomColor: 'white', width: '80%', display: 'flex', justifyContent: 'center' }}>
                <LightTooltip title={CurrencyFormat(ctx?.cards.total_neto)} disableHoverListener={ctx.cards.total_neto?.length < 9}>
                  <Typography variant='h4'>
                    {ctx.cards.total_neto
                      ? ctx.cards.total_neto.length > 8
                        ? CurrencyFormat(ctx.cards.total_neto.slice(0, -6)) + 'M'
                        : CurrencyFormat(ctx.cards.total_neto)
                      : 0}
                  </Typography>
                </LightTooltip>
              </Box>
              {ctx.cards.lastYear && ctx.cards.lastYear.comparativa_ventas_menos_devoluciones
                ? (
                    ctx.cards.lastYear.comparativa_ventas_menos_devoluciones?.includes('Mayor')
                      ? (
                        <Typography sx={{ display: 'flex', alignItems: 'center', color: 'greenyellow' }}>
                          <NorthIcon sx={{ width: 20 }} />
                          {ctx.cards.lastYear.comparativa_ventas_menos_devoluciones.match(/\(([^)]+)\)/)[1]}
                        </Typography>
                        )
                      : (
                        <Typography sx={{ display: 'flex', alignItems: 'center', color: '#b40707' }}>
                          <SouthIcon sx={{ width: 20 }} />
                          {ctx.cards.lastYear.comparativa_ventas_menos_devoluciones.match(/\(([^)]+)\)/)[1]}
                        </Typography>
                        )

                  )
                : (
                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    -
                  </Typography>)}
            </Card>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Cards
