export const CurrencyFormat = (monto : number) => {
  if (monto === 0) {
    return 0
  } else {
    return new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
      minimumFractionDigits: 0
    }).format(monto)
  }
}

export const CurrencyFormatUSD = (monto: number, currency: string) => {
  const numberFormat = currency === 'USD' ? 'en-US' : 'es-CL'
  const finalCurrency = currency === 'USD' ? 'USD' : 'CLP'
  if (monto === 0) {
    return 0
  } else {
    return new Intl.NumberFormat(`${numberFormat}`, {
      style: 'currency',
      currency: finalCurrency,
      minimumFractionDigits: 0
    }).format(monto)
  }
}
