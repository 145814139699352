import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux'
import { UserItem } from '../models/user.model'
import { userSlice } from './states/user'
import { headerSlice } from './states/header'

export interface AppStore {
  user: UserItem;
  header: any
}

export const store = configureStore<AppStore>({
  reducer: {
    user: userSlice.reducer,
    header: headerSlice.reducer
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
