import { Card, CardHeader, Switch, Typography } from '@mui/material'
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Rectangle,
  ComposedChart,
  ReferenceLine,
  Label,
  LabelList
} from 'recharts'
import { DashboardBsaleContextType, useDashboardBsaleContext } from '../../../context/dashboardBsale.context'
import { CurrencyFormat } from '@utils/Currency.utility'
import { useState } from 'react'
import { average, formatNumber } from '@utils/dashboardBsale'

function AverageChannel () {
  const { ctx } = useDashboardBsaleContext() as DashboardBsaleContextType
  const [hideREUSE, setHideREUSE] = useState(false)

  function handleChange () {
    setHideREUSE(!hideREUSE)
  }

  const filteredData = hideREUSE
    ? avgSales(ctx.salesByChannel.filter(item => item.name !== ctx.channel), ctx.unitsByChannel)
    : avgSales(ctx.salesByChannel, ctx.unitsByChannel)

  function avgSales (sales: any, unit: any): any {
    const result = sales.map((sale: any) => {
      const find = unit.find((unit: any) => unit.name === sale.name)
      return ({
        name: find.name,
        avgSale: Number(find?.total) !== 0 ? sale.total / find?.total : 0
      })
    })
    return result
  }

  const salesAverage = average(filteredData, 'avgSale')

  return (
    <Card sx={{ height: 400, marginTop: 3 }}>
      <CardHeader title={
        <Typography variant='h6'>Ticket promedio
          <Switch checked={hideREUSE} onChange={handleChange} />
        </Typography>
}
      />
      <ResponsiveContainer width='100%' height='80%'>
        <ComposedChart
          width={500}
          height={400}
          data={filteredData}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 50
          }}
        >
          <CartesianGrid stroke='#f5f5f5' />
          <XAxis dataKey='name' />
          <YAxis
            tickFormatter={formatNumber}
          />
          <Tooltip
            formatter={(value: any, name: any, props: any) => CurrencyFormat(value)}
          />
          <Legend />
          {salesAverage !== null && (
            <ReferenceLine y={salesAverage} stroke='#cccccc' strokeDasharray='3 3'>
              <Label value={`Avg: ${formatNumber(salesAverage)}`} position='insideLeft' />
            </ReferenceLine>
          )}
          <Bar dataKey='avgSale' name='Promedio' fill='#413ea0' activeBar={<Rectangle fill='#7b76f3' stroke='blue' />} barSize={30} legendType='circle'>
            <LabelList dataKey='avgSale' position='top' formatter={(value: any) => formatNumber(value)} fontSize={12} />
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </Card>
  )
}

export default AverageChannel
