import { Card, CardHeader, Typography, Grid } from '@mui/material'
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Line,
  ReferenceLine,
  Label,
  LabelList
} from 'recharts'
import { DashboardBsaleContextType, useDashboardBsaleContext } from '../../../context/dashboardBsale.context'
import moment from 'moment'
import { average, month } from '@utils/dashboardBsale'

const currentYear = moment(new Date()).format('YYYY')

function Units () {
  const { ctx } = useDashboardBsaleContext() as DashboardBsaleContextType
  const salesAverage = average(ctx.unitQuarter, 'total_current')
  const order = (sales: any) => {
    const newData = sales.map((ele: any) => {
      return ({
        ...ele,
        month: month(ele.mes)
      })
    })
    return newData
  }

  const lastDays = (sales: any) => {
    const result = sales.map((ele: any) => {
      return ({
        ...ele,
        fecha: ele.fecha.split('T')[0].split('-')[2]
      })
    })
    return result
  }

  const unitAverage = average(ctx.unitLastDays, 'total_current').toFixed(0)

  const maxUnits = Math.max(
    ...ctx.unitQuarter.map((unit: any) => Math.max(parseFloat(unit.total_current), parseFloat(unit.total_last_year)))
  )

  const maxUnitsLastDays = Math.max(
    ...ctx.unitLastDays.map((unit: any) => Math.max(parseFloat(unit.total_current), parseFloat(unit.total_last_year)))
  )

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item md={12} lg={6}>
        <Card sx={{ height: 400, marginTop: 3 }}>
          <CardHeader title={<Typography variant='h6'>Unidades - Trimestre actual (últimos 3 meses)</Typography>} />
          <ResponsiveContainer width='100%' height='80%'>
            <ComposedChart
              width={500}
              height={300}
              data={ctx && order(ctx.unitQuarter)}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid stroke='#f5f5f5' />
              <XAxis dataKey='month' />
              <YAxis
                domain={[0, Math.round(maxUnits + (maxUnits * 0.1))]}
              />
              <Tooltip />
              <Legend
                payload={[
                  { value: `Total ${Number(currentYear) - 1}`, type: 'circle', id: `total ${Number(currentYear) - 1}`, color: '#ff7300' },
                  { value: `Devoluciones ${Number(currentYear) - 1}`, type: 'line', id: `devoluciones ${Number(currentYear) - 1}`, color: '#ff7300' },
                  { value: `Total ${currentYear}`, type: 'circle', id: `total ${currentYear}`, color: '#413ea0' },
                  { value: `Devoluciones ${currentYear}`, type: 'line', id: `devoluciones ${currentYear}`, color: '#413ea0' }
                ]}
              />
              {salesAverage !== null && (
                <ReferenceLine y={salesAverage} stroke='#cccccc' strokeDasharray='3 3'>
                  <Label value={`Avg: ${Math.floor(salesAverage)}`} position='insideLeft' />
                </ReferenceLine>
              )}

              <Bar dataKey='unidades_vendidas_last_year' name={`Unidades ${Number(currentYear) - 1}`} fill='#ff7300' barSize={-4} legendType='none' display='none' />
              <Line type='monotone' dataKey='unidades_retornadas_last_year' name={`Devoluciones ${Number(currentYear) - 1}`} stroke='#ff7300' />
              <Bar dataKey='total_last_year' name={`Total ${Number(currentYear) - 1}`} fill='#ff7300' barSize={30} legendType='circle'>
                <LabelList dataKey='total_last_year' position='top' formatter={(value: any) => value} fontSize={12} />
              </Bar>

              <Bar dataKey='unidades_vendidas_current' name={`Unidades ${currentYear}`} fill='#413ea0' barSize={-4} legendType='none' display='none' />
              <Line type='monotone' dataKey='unidades_retornadas_current' name={`Devoluciones ${currentYear}`} stroke='#413ea0' />
              <Bar dataKey='total_current' name={`Total ${currentYear}`} fill='#413ea0' barSize={30} legendType='circle'>
                <LabelList dataKey='total_current' position='top' formatter={(value: any) => value} fontSize={12} />
              </Bar>
            </ComposedChart>
          </ResponsiveContainer>
        </Card>
      </Grid>
      <Grid item md={12} lg={6}>
        <Card sx={{ height: 400, marginTop: 3 }}>
          <CardHeader title={<Typography variant='h6'>Unidades - Últimos 14 dias</Typography>} />
          <ResponsiveContainer width='100%' height='80%'>
            <ComposedChart
              width={500}
              height={300}
              data={lastDays(ctx.unitLastDays)}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid stroke='#f5f5f5' />
              <XAxis dataKey='fecha' />
              <YAxis
                domain={[0, Math.round(maxUnitsLastDays + (maxUnitsLastDays * 0.1))]}
              />
              <Tooltip />
              <Legend
                payload={[
                  { value: `Total ${Number(currentYear) - 1}`, type: 'circle', id: `total ${Number(currentYear) - 1}`, color: '#ff7300' },
                  { value: `Devoluciones ${Number(currentYear) - 1}`, type: 'line', id: `devoluciones ${Number(currentYear) - 1}`, color: '#ff7300' },
                  { value: `Total ${currentYear}`, type: 'circle', id: `total ${currentYear}`, color: '#413ea0' },
                  { value: `Devoluciones ${currentYear}`, type: 'line', id: `devoluciones ${currentYear}`, color: '#413ea0' }
                ]}
              />
              {unitAverage !== null && (
                <ReferenceLine y={unitAverage} stroke='#cccccc' strokeDasharray='3 3'>
                  <Label value={`Avg:${unitAverage}`} position='left' fontSize={14} />
                </ReferenceLine>
              )}

              <Bar dataKey='unidades_vendidas_last_year' name={`Unidades ${Number(currentYear) - 1}`} fill='#ff7300' barSize={-4} legendType='none' display='none' />
              <Line type='monotone' dataKey='unidades_retornadas_last_year' name={`Devoluciones ${Number(currentYear) - 1}`} stroke='#ff7300' />
              <Bar dataKey='total_last_year' name={`Total ${Number(currentYear) - 1}`} fill='#ff7300' barSize={10} legendType='circle' />

              <Bar dataKey='unidades_vendidas_current' name={`Unidades ${currentYear}`} fill='#413ea0' barSize={-4} legendType='none' display='none' />
              <Line type='monotone' dataKey='unidades_retornadas_current' name={`Devoluciones ${currentYear}`} stroke='#413ea0' />
              <Bar dataKey='total_current' name={`Total ${currentYear}`} fill='#413ea0' barSize={10} legendType='circle' />
            </ComposedChart>
          </ResponsiveContainer>
        </Card>
      </Grid>
    </Grid>
  )
}

export default Units
