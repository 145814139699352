import { Badge, Box, Button, Card, CardContent, Grid, Paper, Tab, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tabs, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { DboardApi } from '@services/index'
import { CurrencyFormat } from '@utils/Currency.utility'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import PolicyIcon from '@mui/icons-material/Policy'
import TourIcon from '@mui/icons-material/Tour'
import { ReactComponent as Excel } from '@assets/svg/excel.svg'
import * as XLSX from 'xlsx'

interface IPolicy {
  created_at: string;
  id: string;
  id_order_shopify: number;
  id_order_platform: string;
  product_name: string;
  sku: string;
  price: number;
  json_policy: string;
  token: string;
  voided: number;
}
interface ILog{
  updated_at: string;
  id: number;
  user_email: string;
  user_fullname: string;
  product_sku: string;
  insurance_selected: string;
  was_bought: number;
  token: string;
}

interface ILogNew{
  updated_at: string;
  id: number;
  user_email: string;
  user_fullname: string;
  product_sku: string;
  insurance_selected: string;
  amount: string;
  was_bought: number;
  token: string;
}
interface ICoticed{
  product_sku: string;
  name: string;
  insurance_selected: string;
  count: number;
}
interface ICoticedInsurances{
  insurance_selected: string;
  count: number;
}
interface IOwnProps {
  listPolicies: IPolicy[];
  listLog: ILog[];
  newListLog: ILogNew[];
  listCoticed: ICoticed[];
  listCoticedInsurances: ICoticedInsurances[];
  loading: boolean;
  currentTab: number;
  selectedToken: any;
  selectedLogIndex: any;
  page: number;
  pageCoticed: number;
  rowsPerPage: number;
  rowsPerPageCoticed: number;
  showTopTen: boolean;
}
const StyledTab = styled(Tab)(({ theme }) => ({
  fontWeight: 'bold'
}))

const TableInsurances = () => {
  const [data, setData] = useState<IOwnProps>({
    listPolicies: [],
    loading: false,
    currentTab: 0,
    listLog: [],
    newListLog: [],
    listCoticed: [],
    listCoticedInsurances: [],
    selectedToken: null,
    selectedLogIndex: null,
    page: 0,
    pageCoticed: 0,
    rowsPerPage: 20,
    rowsPerPageCoticed: 5,
    showTopTen: true
  })

  const loadData = () => {
    setData({ ...data, loading: true })
    Promise.all([
      DboardApi.getListPolicies(),
      DboardApi.getListLog(),
      DboardApi.getAllCoticed(),
      DboardApi.getCoticedInsurances()
    ])
      .then(([policiesResponse, logResponse, allCoticed, allCoticedInsurances]) => {
        const fixedListLog = logResponse.data.data.map((entry: any) => {
          if (entry.insurance_selected.includes('$')) {
            const dollarIndex = entry.insurance_selected.indexOf('$')

            const insurance = entry.insurance_selected.substring(0, dollarIndex).trim()
            const amount = entry.insurance_selected.substring(dollarIndex).trim()
            console.log('amount ==>', amount)

            return {
              ...entry,
              insurance_selected: insurance,
              amount
            }
          }
          return entry
        })
        setData({
          ...data,
          listPolicies: policiesResponse.data.data,
          listLog: logResponse.data.data,
          listCoticed: allCoticed.data.data,
          listCoticedInsurances: allCoticedInsurances.data.data,
          newListLog: fixedListLog,
          loading: false
        })
      })
      .catch((error) => {
        console.log('Error loading data:', error)
        setData({ ...data, loading: false })
      })
  }

  const topCoticed = data.listCoticed.slice(0, 10)
  const allCoticed = data.listCoticed

  const chartData = data.showTopTen ? topCoticed : allCoticed

  const options = {
    credits: {
      enabled: false
    },
    chart: {
      height: 350,
      backgroundColor: 'rgba(0,0,0,0)',
      type: 'column'
    },
    title: false,
    exporting: {
      enabled: false
    },
    legend: {
      verticalAlign: 'top',
      itemStyle: {
        color: '#000000'
      },
      itemHoverStyle: {
        color: '#020202'
      }
    },
    yAxis: {
      title: false,
      labels: {
        style: {
          color: '#000000'
        }
      }
    },
    xAxis: {
      categories: chartData.map(item => item.name),
      labels: {
        style: {
          color: '#000000'
        }
      }
    },
    annotations: {
      visible: false
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true,
          symbol: 'circle'
        }
      }
    },
    series: [{
      name: 'Cantidad de cotizaciones',
      data: chartData.map((item) => item.count)
    }]
  }

  const options2 = {
    credits: {
      enabled: false
    },
    chart: {
      height: 350,
      backgroundColor: 'rgba(0,0,0,0)',
      type: 'area'
    },
    title: false,
    exporting: {
      enabled: false
    },
    legend: {
      verticalAlign: 'top',
      itemStyle: {
        color: '#000000'
      },
      itemHoverStyle: {
        color: '#020202'
      }
    },
    yAxis: {
      title: false,
      labels: {
        style: {
          color: '#000000'
        }
      }
    },
    xAxis: {
      categories: data.listCoticedInsurances.map(item => item.insurance_selected),
      labels: {
        style: {
          color: '#000000'
        }
      }
    },
    annotations: {
      visible: false
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true,
          symbol: 'circle'
        }
      }
    },
    series: [{
      name: 'Cantidad de cotizaciones',
      data: data.listCoticedInsurances.map((item) => item.count)
    }]
  }
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    }
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }))

  const StyledSelectedTableRow = styled(TableRow)<{ isSelected: boolean }>(({ isSelected }) => ({
    '&': {
      border: isSelected ? '2px solid #1976d2' : 'none'
    }
  }))

  const ExportToExcelButton: any = ({ data, stage }: any) => {
    const handleExport = () => {
      let reorderedData
      console.log('stage ==>', stage)
      if (stage === 'registro_eventos') {
        const desiredOrder = ['id', 'user_email', 'user_fullname', 'product_sku', 'insurance_selected', 'amount', 'additional_info', 'created_at']
        const headerMapping: { [key: string]: string } = {
          id: 'id',
          user_email: 'Email cliente',
          user_fullname: 'Nombre y apellido',
          product_sku: 'SKU',
          insurance_selected: 'Seguro',
          amount: 'Precio',
          additional_info: 'Info adicional',
          created_at: 'Fecha creación'
        }
        reorderedData = data.map((item: any) => {
          const reorderedItem: any = {}
          desiredOrder.forEach((key: string) => {
            if (key === 'created_at') {
              // Formatea la fecha usando moment.js
              reorderedItem[headerMapping[key]] = moment(item[key]).format('YYYY-MM-DD HH:mm:ss')
            } else {
              reorderedItem[headerMapping[key]] = item[key]
            }
          })
          return reorderedItem
        })
      } else {
        reorderedData = data
      }

      const ws = XLSX.utils.json_to_sheet(reorderedData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Modelos Cotizados')
      XLSX.writeFile(wb, stage + '_' + process.env.REACT_APP_COUNTRY + '.xlsx')
    }

    return (
      <Button
        variant='contained'
        color='success'
        size='small' onClick={handleExport}
        sx={{ fontSize: '0.8rem', minWidth: 'fit-content', py: 0, px: 1 }}
      >
        <Excel className='me-2' /> Exportar
      </Button>
    )
  }

  const handleChangePage = (event: any, newPage: number) => {
    setData({ ...data, page: newPage })
  }

  const handleChangePageCoticed = (event: any, newPage: number) => {
    setData({ ...data, pageCoticed: newPage })
  }

  const handleChangeRowsPerPage = (event: any) => {
    setData({ ...data, rowsPerPage: parseInt(event.target.value, 10), page: 0 })
  }

  const handleChangeRowsPerPageCoticed = (event: any) => {
    setData({ ...data, rowsPerPageCoticed: parseInt(event.target.value, 5), pageCoticed: 0 })
  }

  const toggleData = () => {
    setData((prevData) => ({
      ...prevData,
      showTopTen: !prevData.showTopTen
    }))
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setData((prevData) => ({
      ...prevData,
      currentTab: newValue,
      selectedLogIndex: null
    }))
  }

  const handleViewPolicyClick = (token: string) => {
    const index = data.listPolicies.findIndex((e) => e.token === token)

    if (index !== -1) {
      setData((prevData) => ({
        ...prevData,
        selectedToken: token,
        currentTab: 0,
        selectedLogIndex: index
      }))
    }
  }

  const paginatedListLog = data.listLog.slice(data.page * data.rowsPerPage, data.page * data.rowsPerPage + data.rowsPerPage)
  const paginatedListCoticed = data.listCoticed.slice(data.pageCoticed * data.rowsPerPageCoticed, data.pageCoticed * data.rowsPerPageCoticed + data.rowsPerPageCoticed)

  return (
    <>
      <Grid container spacing={3} display='flex' alignItems='center' justifyContent='center'>
        <Grid item xs={12} sm={2}>
          <Card style={{ backgroundColor: '#e3f2fd' }}>
            <CardContent>
              <Box display='flex' alignItems='center'>
                <PolicyIcon style={{ fontSize: 40, color: '#1976d2', marginRight: '10px' }} />
                <Box>
                  <Typography variant='h6' component='div' color='#1976d2'>
                    Polizas vendidas
                  </Typography>
                  <Typography variant='h4' component='div'>
                    {data.listPolicies.length}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Card style={{ backgroundColor: '#fff3e0' }}>
            <CardContent>
              <Box display='flex' alignItems='center'>
                <TourIcon style={{ fontSize: 40, color: '#f57c00', marginRight: '10px' }} />
                <Box>
                  <Typography variant='h6' component='div' color='#f57c00'>
                    Cotizaciones
                  </Typography>
                  <Typography variant='h4' component='div'>
                    {data.listCoticed.reduce((acc, curr) => acc + curr.count, 0)}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box my={2}>
        <Card>
          <CardContent>
            <Tabs value={data.currentTab} onChange={handleTabChange} aria-label='simple tabs example' style={{ marginBottom: '15px' }}>
              <StyledTab label='Polizas contratadas' />
              <StyledTab label='Registro de eventos' />
              <StyledTab label='Dispositivos cotizados' />
            </Tabs>
            {data.currentTab === 0 && (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label='customized table'>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Id</StyledTableCell>
                      <StyledTableCell align='right'>Fecha de contratación</StyledTableCell>
                      <StyledTableCell align='right'>N° de orden</StyledTableCell>
                      <StyledTableCell align='right'>Producto</StyledTableCell>
                      <StyledTableCell align='right'>Sku</StyledTableCell>
                      <StyledTableCell align='right'>Valor producto</StyledTableCell>
                      <StyledTableCell align='right'>N° de poliza</StyledTableCell>
                      <StyledTableCell align='right'>Seguro</StyledTableCell>
                      <StyledTableCell align='right'>Valor seguro</StyledTableCell>
                      <StyledTableCell align='right'>Estado</StyledTableCell>
                      <StyledTableCell align='right'>Acciones</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.listPolicies.length > 0
                      ? (
                          data.listPolicies.map((row, index) => {
                            let parsedPolicy =
              typeof row.json_policy === 'string' ? JSON.parse(row.json_policy) : row.json_policy

                            if (typeof parsedPolicy !== 'object' || parsedPolicy === null) {
                              console.error('Error: row.json_policy is not a valid JSON object')
                              parsedPolicy = null
                            }

                            return (
                              <StyledSelectedTableRow key={index} isSelected={index === data.selectedLogIndex}>
                                <StyledTableCell align='left'>{index + 1}</StyledTableCell>
                                <StyledTableCell align='right'>
                                  {moment(row.created_at).format('DD-MM-YYYY HH:mm:ss')}
                                </StyledTableCell>
                                <StyledTableCell align='right'>{row.id_order_platform}</StyledTableCell>
                                <StyledTableCell align='right'>{row.product_name}</StyledTableCell>
                                <StyledTableCell align='right'>{row.sku}</StyledTableCell>
                                <StyledTableCell align='right'>{CurrencyFormat(row.price)}</StyledTableCell>
                                <StyledTableCell align='right'>{parsedPolicy.policy.number}</StyledTableCell>
                                <StyledTableCell align='right'>{parsedPolicy.policy.product.name}</StyledTableCell>
                                <StyledTableCell align='right'>
                                  {CurrencyFormat(parsedPolicy.policy.price_total)}
                                </StyledTableCell>
                                <StyledTableCell align='center'>
                                  <Badge badgeContent={row.voided === 1 ? 'Anulada' : 'Válida'} color={row.voided === 1 ? 'error' : 'primary'} />
                                </StyledTableCell>
                                <StyledTableCell align='right'>
                                  <a href={'https://admin.shopify.com/store/reuse-lab/orders/' + row.id_order_shopify} target='_blank' rel='noreferrer'>
                                    <Button variant='contained' color='success' size='small'>
                                      Ver en Shopify
                                    </Button>
                                  </a>
                                </StyledTableCell>
                              </StyledSelectedTableRow>
                            )
                          })
                        )
                      : (
                        <StyledSelectedTableRow key='1' isSelected={false}>
                          <StyledTableCell align='left'>Sin pólizas contratadas.</StyledTableCell>
                        </StyledSelectedTableRow>
                        )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {data.currentTab === 1 && (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', p: 1, pb: 0 }} mb={2}>
                  <ExportToExcelButton data={data.newListLog} stage='registro_eventos' />
                </Box>
                <TableContainer component={Paper}>
                  <TablePagination
                    rowsPerPageOptions={[20, 50, 80, 100]}
                    component='div'
                    count={data.listLog.length}
                    rowsPerPage={data.rowsPerPage}
                    page={data.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                  <Table sx={{ minWidth: 700 }} aria-label='customized table'>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Id</StyledTableCell>
                        <StyledTableCell align='right'>Fecha</StyledTableCell>
                        <StyledTableCell align='right'>Email</StyledTableCell>
                        <StyledTableCell align='right'>Nombre</StyledTableCell>
                        <StyledTableCell align='right'>Sku</StyledTableCell>
                        <StyledTableCell align='right'>Seguro</StyledTableCell>
                        <StyledTableCell align='right'>Precio</StyledTableCell>
                        <StyledTableCell align='right'>Estado</StyledTableCell>
                        <StyledTableCell align='right'>Acciones</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedListLog.map((row, index) => {
                        const dollarIndex = row.insurance_selected.indexOf('$')
                        const insuranceName = row.insurance_selected.substring(0, dollarIndex).trim()
                        const insuranceValue = row.insurance_selected.substring(dollarIndex).trim()
                        return (
                          <StyledTableRow key={index}>
                            <StyledTableCell align='left'>{index + 1}</StyledTableCell>
                            <StyledTableCell align='right'>
                              {moment(row.updated_at).format('DD-MM-YYYY HH:mm:ss')}
                            </StyledTableCell>
                            <StyledTableCell align='right'>
                              {row.user_email === null ? 'Sin información' : row.user_email}
                            </StyledTableCell>
                            <StyledTableCell align='right'>
                              {row.user_fullname === null ? 'Sin información' : row.user_fullname}
                            </StyledTableCell>
                            <StyledTableCell align='right'>
                              {row.product_sku === null ? 'Sin información' : row.product_sku}
                            </StyledTableCell>
                            <StyledTableCell align='right'>{insuranceName}</StyledTableCell>
                            <StyledTableCell align='right'>{insuranceValue}</StyledTableCell>
                            <StyledTableCell align='right'>
                              {row.was_bought === 1
                                ? (
                                  <>
                                    <Badge badgeContent='Finalizado' color='success' />
                                  </>
                                  )
                                : (
                                  <>
                                    <Badge badgeContent='Incompleto' color='warning' />
                                    <div />
                                  </>
                                  )}
                            </StyledTableCell>
                            <StyledTableCell align='right'>
                              {row.was_bought === 1
                                ? (
                                  <>
                                    <Button variant='contained' color='primary' size='small' onClick={() => handleViewPolicyClick(row.token)}>
                                      Ver en Polizas
                                    </Button>
                                  </>
                                  )
                                : (
                                  <>
                                    <Button variant='contained' color='info' disabled size='small'>
                                      Sin acción
                                    </Button>
                                    <div />
                                  </>
                                  )}
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[20, 50, 80, 100]}
                    component='div'
                    count={data.listLog.length}
                    rowsPerPage={data.rowsPerPage}
                    page={data.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </>

            )}
            {data.currentTab === 2 && (
              <Grid container direction='row' spacing={2} my={1} alignItems='stretch'>
                <Grid item xs={12} md={6} lg={6} xl={6} style={{ display: 'flex', flexDirection: 'column' }}>
                  <Card style={{ flex: 1 }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, pb: 0 }}>
                        <Typography variant='h6' color='textSecondary'>
                          Gráfico modelos más cotizados
                        </Typography>
                        <Button
                          variant='contained' size='small' onClick={toggleData} sx={{ fontSize: '0.8rem', minWidth: 'fit-content', py: 0, px: 1 }}
                        >
                          {data.showTopTen ? 'Mostrar todos' : 'Mostrar top 10'}
                        </Button>
                      </Box>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        width='100%'
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6} style={{ display: 'flex', flexDirection: 'column' }}>
                  <Card style={{ flex: 1 }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, pb: 0 }}>
                        <Typography variant='h6' color='textSecondary'>
                          Tabla modelos más cotizados
                        </Typography>
                        <ExportToExcelButton data={data.listCoticed} stage='modelos_cotizados' />
                      </Box>
                      <Box sx={{ p: 1, pb: 0 }}>
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
                            <TableHead>
                              <TableRow>
                                <StyledTableCell align='left'>#</StyledTableCell>
                                <StyledTableCell align='left'>(SKU) Modelo</StyledTableCell>
                                <StyledTableCell align='right'>N° Veces cotizado</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {paginatedListCoticed &&
                              paginatedListCoticed.map((row, index) => {
                                return (
                                  <StyledTableRow key={index}>
                                    <StyledTableCell align='left'>{index + 1 + data.pageCoticed * data.rowsPerPageCoticed}</StyledTableCell>
                                    <StyledTableCell align='left'>({row.product_sku}) {row.name}</StyledTableCell>
                                    <StyledTableCell align='right'>{row.count}</StyledTableCell>
                                  </StyledTableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                          <TablePagination
                            rowsPerPageOptions={[20, 50, 80, 100]}
                            component='div'
                            count={data.listCoticed.length}
                            rowsPerPage={data.rowsPerPageCoticed}
                            page={data.pageCoticed}
                            onPageChange={handleChangePageCoticed}
                            onRowsPerPageChange={handleChangeRowsPerPageCoticed}
                            labelRowsPerPage='Filas por página'
                          />
                        </TableContainer>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6} style={{ display: 'flex', flexDirection: 'column' }}>
                  <Card style={{ flex: 1 }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, pb: 0 }}>
                        <Typography variant='h6' color='textSecondary'>
                          Gráfico seguros más cotizados
                        </Typography>
                      </Box>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={options2}
                        width='100%'
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6} style={{ display: 'flex', flexDirection: 'column' }}>
                  <Card style={{ flex: 1 }}>
                    <CardContent>
                      <Typography variant='h6' color='textSecondary'>
                        Tabla seguros más cotizados
                      </Typography>
                      <Box sx={{ p: 1, pb: 0 }}>
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
                            <TableHead>
                              <TableRow>
                                <StyledTableCell align='left'>#</StyledTableCell>
                                <StyledTableCell align='left'>Seguro</StyledTableCell>
                                <StyledTableCell align='right'>N° Veces cotizado</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.listCoticedInsurances &&
                              data.listCoticedInsurances.map((row, index) => {
                                return (
                                  <StyledTableRow key={index}>
                                    <StyledTableCell align='left'>{index + 1}</StyledTableCell>
                                    <StyledTableCell align='left'>{row.insurance_selected}</StyledTableCell>
                                    <StyledTableCell align='right'>{row.count}</StyledTableCell>
                                  </StyledTableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Box>
    </>
  )
}

export default TableInsurances
