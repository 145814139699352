import {
  Box, TableCell, TableHead, TableRow,
  TableSortLabel
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import PropTypes from 'prop-types'
import { TextAlign } from '../TableComissionFilter/EnhancedTableHead'

const headCells = [
  {
    id: 'id_order_platform',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Id Order',
    width: '60px'
  },

  {
    id: 'saleDate',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Fecha',
    width: '50px'
  },
  {
    id: 'discount',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Descuento',
    width: '50px'
  },
  {
    id: 'discount_code',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Código descuento',
    width: '50px'
  },
  {
    id: 'total_amount',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Total Bruto',
    width: '50px'
  },
  {
    id: 'additional_charge',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Cargo adicional',
    width: '50px'
  },
  {
    id: 'totalNeto',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Total Neto',
    width: '50px'
  },
  {
    id: 'commisionNeta',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Comisión Neta',
    width: '30px'
  },
  {
    id: 'invoiceNeto',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'A Facturar Neto',
    width: '50px'
  },
  {
    id: 'invoiceBruta',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'A Facturar Bruto',
    width: '50px'
  },
  {
    id: 'descriptiosProducts',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Nombre Producto',
    width: '100px'
  },
  {
    id: 'sku',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'SKU',
    width: '100px'
  },
  {
    id: 'nameSeller',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Seller',
    width: '150px'
  },
  {
    id: 'client_name',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Nombre Cliente',
    width: '100px'
  },
  {
    id: 'client_email',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Email Cliente',
    width: '100px'
  },
  {
    id: 'refund_note',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Nota devolución',
    width: '100px'
  }
]

const EnhancedTableHead = (props : any) => {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    onRequestSort
  } = props
  const createSortHandler = (property : any) => (event : any) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow
        sx={{
          background: 'linear-gradient(to left top, #4676b9, #7c3c91)',
          color: '#ffff',
          padding: 3,
          textAlign: 'center'
        }}
      >
        {headCells.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            width={headCell.width}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ color: '#fff', background: 'transparent' }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id
                ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                  )
                : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

export default EnhancedTableHead
