import {
  Box,
  Button,
  Card,
  CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Table,
  TableBody, TableCell,
  TableHead, TableRow,
  Typography
} from '@mui/material'
import ApexChart from 'react-apexcharts'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { DBOARD_FORMATEND, DBOARD_FORMATSMALL, DBOARD_FORMATSTART, DBOARD_MINDATE } from '../../../constants/dashboard.constants'
import { DboardApi } from '../../../services'
import { CurrencyFormat, sharingInformationService } from '../../../utils'
import { useDashboardContext } from '../context/dashboard.context'
import { LoadingButton } from '@mui/lab'
import { exportTableToExcel } from '../../../utils/Excel.utility'
import { ReactComponent as Excel } from '@assets/svg/excel.svg'
import PartiallyPaidModal from './PartiallyPaidModal'

const GraficosTotalVentas = () => {
  const { ctx } = useDashboardContext()
  const [data, setData] = useState <{ totalVentas: any[], amoutSaleReversso: any[], dataReversso: any[], idSeller: number, dateStart: string, dateEnd: string, loading: boolean, openModalPF: boolean }>({ openModalPF: false, dataReversso: [], totalVentas: [], amoutSaleReversso: [], idSeller: ctx.idSeller, dateStart: ctx.dateStart, dateEnd: ctx.dateEnd, loading: false })
  const subscription$ = sharingInformationService.getSubject()

  const dataOptions = {
    donut: {
      /*   series: [dataSeries], */
      options: {
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 400
              },
              legend: {
                position: 'top'
              }
            }
          }
        ],
        labels:
            data.totalVentas && data.totalVentas.length > 0
              ? data.totalVentas.map((item) => item.seller)
              : [],

        stroke: {
          show: false,
          width: 0
        },
        colors: ['#e53935', '#000', '#645080', '#FD5F00', '#005792']
      }
    }
  }

  const loadData = (_dateStart: string, _dateEnd: string, _idSeller: number) => {
    setData({ ...data, loading: true })
    Promise.all([
      loadToTalVentas(_dateStart, _dateEnd, _idSeller),
      loadAmoutSaleReversso(_dateStart, _dateEnd, _idSeller),
      loadReverssosBySeller(_dateStart, _dateEnd, _idSeller)])
      .then(response => {
        console.log('response[0].dataTotalSales', response[0].dataTotalSales)
        setData({ ...data, loading: false, dateStart: _dateStart, dateEnd: _dateEnd, idSeller: _idSeller, totalVentas: response[0].dataTotalSales, amoutSaleReversso: [], dataReversso: response[2] })
      }).catch((error) => {
        console.log(error)
        setData({ ...data, loading: false })
      })
  }

  const toggleModal = () => {
    setData({ ...data, openModalPF: !data.openModalPF })
  }

  const loadToTalVentas = (_dateStart: string, _dateEnd: string, _idSeller: number) => {
    return DboardApi.getTotalVentas(_dateStart, _dateEnd, _idSeller).then((response) => response.data).catch((error) => error)
  }

  const loadAmoutSaleReversso = (_dateStart: string, _dateEnd: string, _idSeller: number) => {
    return DboardApi.getAmountSaleReversso(_dateStart, _dateEnd, _idSeller).then((response) => response.items).catch((error) => error)
  }

  const loadReverssosBySeller = (_dateStart: string, _dateEnd: string, _idSeller: number) => {
    return DboardApi.getReverssoBySeller(_dateStart, _dateEnd, _idSeller).then((response) => response.items).catch((error) => error)
  }

  useMemo(() => {
    loadData(moment(data.dateStart).format(DBOARD_FORMATSTART), moment(data.dateEnd).format(DBOARD_FORMATEND), data.idSeller)
    subscription$.subscribe((info: any) => {
      loadData(moment(info.dateStart).format(DBOARD_FORMATSTART), moment(info.dateEnd).format(DBOARD_FORMATEND), info.idSeller)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card className='h-100'>
      <CardContent>
        <Box sx={{ p: 1, pb: 0 }}>
          <Typography
            variant='h5'
            color='textSecondary'
            sx={{ color: '#000', fontWeight: '500px', fontSize: '1rem' }}
          >
            Ventas por <b>Seller</b>
          </Typography>
          {
            data.totalVentas && data.totalVentas.length > 0
              ? (
                <Box sx={{ display: 'flex', alignItems: 'right', justifyContent: 'right' }}>
                  <Button variant='contained' color='success' size='small' onClick={() => exportTableToExcel('IdTableVentaGrafi', 'Ventas por seller')}>
                    <Excel className='me-2' /> Exportar
                  </Button>
                </Box>
                )
              : null
          }
          <Grid container direction='row' spacing={2} my={1}>
            <Grid item xs={12} md={12} lg={4} xl={4}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>Seller</InputLabel>
                <Select
                  value={data.idSeller}
                  label='Seller'
                  onChange={(e) => setData({ ...data, idSeller: parseInt(e.target.value.toString()) })}
                  size='small'
                >

                  {ctx.dataListSeller && ctx.dataListSeller.map((item: any, idx: number) => (<MenuItem value={item.idSeller} key={idx}> {item.nameSeller} </MenuItem>))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4} lg={3} xl={3}>
              <DatePicker
                label='Fecha Desde'
                disableFuture
                minDate={DBOARD_MINDATE}
                format='dd-MM-yyyy'
                value={moment(data.dateStart).toDate()}
                onChange={(newValue) => {
                  setData({ ...data, dateStart: moment(newValue).format(DBOARD_FORMATSTART) })
                }}
                slotProps={{
                  textField: {size: "small", fullWidth: true}
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3} xl={3}>
              <DatePicker
                label='Fecha Hasta'
                disableFuture
                minDate={moment(data.dateStart).toDate()}
                format='dd-MM-yyyy'
                value={moment(data.dateEnd).toDate()}
                onChange={(newValue) => {
                  setData({ ...data, dateEnd: moment(newValue).format(DBOARD_FORMATEND) })
                }}
                slotProps={{
                  textField: {size: "small", fullWidth: true}
                }}
              />
            </Grid>
            <Grid item xs={2} md={2} lg={2} xl={2}>
              <Box>
                <LoadingButton
                  variant='contained'
                  color='primary'
                  onClick={() => loadData(data.dateStart, data.dateEnd, data.idSeller)}
                  disabled={!moment(data.dateEnd).isAfter(data.dateStart)}
                  loading={data.loading}
                >
                  Filtrar
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid container rowSpacing={2} direction='row' columnSpacing={2}>
          {data.totalVentas && data.totalVentas.length > 0
            ? (
              <>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} my={2}>
                  <div>
                    <Box
                      sx={{
                        display: 'flex',
                        align: 'center',
                        justify: 'center',
                        justifyContent: 'center',
                        alignContent: 'center'
                      }}
                    >
                      <Typography
                        variant='h5'
                        color='textSecondary'
                        sx={{ color: '#000', fontWeight: '500px', fontSize: '1rem' }}
                      >
                        Monto de Ventas por <b>Seller</b>
                      </Typography>
                    </Box>
                    <ApexChart
                      className='sparkline-chart'
                      type='donut'
                      height={300}
                      series={data.totalVentas && data.totalVentas.length > 0 ? data.totalVentas.filter(e => e.monto > 0).map((item) => item.monto) : []}
                      options={dataOptions.donut.options}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} my={2}>
                  <div>
                    <Box
                      sx={{
                        display: 'flex',
                        align: 'center',
                        justify: 'center',
                        justifyContent: 'center',
                        alignContent: 'center'
                      }}
                    >
                      <Typography
                        variant='h5'
                        color='textSecondary'
                        sx={{ color: '#000', fontWeight: '500px', fontSize: '1rem' }}
                      >
                        Cantidad Unidades Vendidas por <b>Seller</b>
                      </Typography>
                    </Box>
                    <ApexChart
                      className='sparkline-chart'
                      type='donut'
                      height={300}
                      series={data.totalVentas && data.totalVentas.length > 0 ? data.totalVentas.filter(e => e.monto > 0).map((item) => Number(item.cantidad)) : []}
                      options={dataOptions.donut.options}
                    />
                  </div>
                </Grid>
              </>
              )
            : (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} my={2}>
                <Box
                  sx={{
                    display: 'flex',
                    align: 'center',
                    justify: 'center',
                    justifyContent: 'center',
                    alignContent: 'center'
                  }}
                >
                  <Typography
                    variant='h5'
                    color='textSecondary'
                    sx={{ color: '#000', fontWeight: '500px' }}
                  >
                    Sin <b>unidades vendidas</b> en la fecha seleccionada
                  </Typography>
                </Box>
              </Grid>
              )}
        </Grid>
        <br />

        {
            data.totalVentas && data.totalVentas.length > 0
              ? (
                <Table
                  size='small'
                  aria-labelledby='tableTitle'
                  className='table table-striped table-bordered table-hover'
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          background: 'linear-gradient(to left top, #4676b9, #7c3c91)',
                          color: '#ffff',
                          padding: 10,
                          textAlign: 'left'
                        }}
                      >
                        Fecha
                      </TableCell>
                      <TableCell colSpan={4}>
                        {moment(data.dateStart).format(DBOARD_FORMATSMALL)} -{' '}
                        {moment(data.dateEnd).format(DBOARD_FORMATSMALL)}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow
                      style={{
                        background: 'linear-gradient(to left top, #4676b9, #7c3c91)',
                        color: '#ffff',
                        padding: 0,
                        textAlign: 'center'
                      }}
                    >
                      <TableCell style={{ color: '#ffff', textAlign: 'center' }}>
                        Seller
                      </TableCell>
                      <TableCell style={{ color: '#ffff', textAlign: 'center' }}>
                        Monto Ventas
                      </TableCell>
                      <TableCell style={{ color: '#ffff', textAlign: 'center' }}>
                        % Ventas
                      </TableCell>
                      <TableCell style={{ color: '#ffff', textAlign: 'center' }}>
                        Unidades Vendidas
                      </TableCell>
                      <TableCell style={{ color: '#ffff', textAlign: 'center' }}>
                        % Unidades Vendidas
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.totalVentas &&
                        data.totalVentas.map((row, i) => (
                          <TableRow key={i + 1}>
                            <TableCell style={{ textAlign: 'center' }}>
                              {row.seller}
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                              {CurrencyFormat(row.monto)}
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                              {row.porcentotal} %
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {row.cantidad}
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                              {row.porcentajeUnits} %
                            </TableCell>
                          </TableRow>
                        ))}

                    <TableRow
                      style={{
                        background: 'linear-gradient(to left top, #4676b9, #7c3c91)',
                        color: '#ffff',
                        padding: 0,
                        textAlign: 'center'
                      }}
                    >
                      <TableCell style={{ color: '#ffff', textAlign: 'center' }}>
                        Seller
                      </TableCell>
                      <TableCell style={{ color: '#ffff', textAlign: 'center' }}>
                        Monto
                      </TableCell>
                      <TableCell style={{ color: '#ffff', textAlign: 'center' }}>
                        Cantidad Reversso
                      </TableCell>
                      <TableCell style={{ color: '#ffff', textAlign: 'center' }}>
                        Cantidad Devolución
                      </TableCell>
                      <TableCell
                        style={{ color: '#ffff', textAlign: 'center' }}
                        colSpan={2}
                      />
                    </TableRow>
                    {

                        data.dataReversso &&
                        data.dataReversso.filter((row) => row.amount_reversso > 0).map((rowR, index) => (
                          <TableRow key={index + 1}>
                            <TableCell style={{ textAlign: 'center' }}>
                              {
                                (rowR.seller === 'Devolución Parcial')
                                  ? (
                                    <Button
                                      variant='contained'
                                      color='info'
                                      size='small'
                                      onClick={() => toggleModal()}
                                    >
                                      Devolución Parcial
                                    </Button>
                                    )
                                  : rowR.seller
                              }
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                              -{CurrencyFormat(rowR.amount_reversso)}
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {rowR.reversso}
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {rowR.refunded}
                            </TableCell>
                          </TableRow>
                        ))
                        }
                  </TableBody>
                </Table>
                )
              : null
        }
        <PartiallyPaidModal open={data.openModalPF} onClose={toggleModal} init={data.dateStart} end={data.dateEnd} />
        <Table
          id='IdTableVentaGrafi'
          size='small'
          aria-labelledby='tableTitle'
          border={1}
          hidden
        >
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  padding: '4px',
                  textAlign: 'left',
                  background: '#4676b9',
                  color: '#fff'
                }}
              >
                Fecha
              </TableCell>
              <TableCell
                colSpan={4} style={{
                  padding: '4px',
                  textAlign: 'center',
                  color: '#000'
                }}
              >
                {moment(data.dateStart).format(DBOARD_FORMATSMALL)} -{' '}
                {moment(data.dateEnd).format(DBOARD_FORMATSMALL)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow
              style={{
                padding: 0,
                textAlign: 'center'
              }}
            >
              <TableCell style={{ textAlign: 'center', background: '#4676b9', color: '#fff', padding: '4px' }}>
                Seller
              </TableCell>
              <TableCell style={{ textAlign: 'center', background: '#4676b9', color: '#fff', padding: '4px' }}>
                Monto Ventas
              </TableCell>
              <TableCell style={{ textAlign: 'center', background: '#4676b9', color: '#fff', padding: '4px' }}>
                % Ventas
              </TableCell>
              <TableCell style={{ textAlign: 'center', background: '#4676b9', color: '#fff', padding: '4px' }}>
                Unidades Vendidas
              </TableCell>
              <TableCell style={{ textAlign: 'center', background: '#4676b9', color: '#fff', padding: '4px' }}>
                % Unidades Vendidas
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
            data.totalVentas &&
            data.totalVentas.map((row: any, i: number) => (
              <TableRow key={i}>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.seller}
                </TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  {CurrencyFormat(row.monto)}
                </TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  {row.porcentotal} %
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.cantidad}
                </TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  {row.porcentajeUnits} %
                </TableCell>
              </TableRow>
            ))
                }

            <TableRow
              style={{
                padding: 0,
                textAlign: 'center'
              }}
            >
              <TableCell style={{ textAlign: 'center', background: '#4676b9', color: '#fff', padding: '4px' }}>
                Seller
              </TableCell>
              <TableCell style={{ textAlign: 'center', background: '#4676b9', color: '#fff', padding: '4px' }}>
                Monto
              </TableCell>
              <TableCell style={{ textAlign: 'center', background: '#4676b9', color: '#fff', padding: '4px' }}>
                Cantidad Reversso
              </TableCell>
              <TableCell style={{ textAlign: 'center', background: '#4676b9', color: '#fff', padding: '4px' }}>
                Cantidad Devolución
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', background: '#4676b9', color: '#fff', padding: '4px' }}
              />
            </TableRow>
            {
            data.dataReversso &&
            data.dataReversso.map((rowR, index) => (
              <TableRow key={index + 1}>
                <TableCell style={{ textAlign: 'center' }}>
                  {rowR.seller}
                </TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  -{CurrencyFormat(rowR.amount_reversso)}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {rowR.reversso}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {rowR.refunded}
                </TableCell>

              </TableRow>
            ))
}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default GraficosTotalVentas
