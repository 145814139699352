import React, { useState } from 'react'
import { Card, CardHeader, Typography } from '@mui/material'
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  Label,
  LabelList
} from 'recharts'
import { DashboardBsaleContextType, useDashboardBsaleContext } from '../../../context/dashboardBsale.context'
import { average, month } from '@utils/dashboardBsale'

function UnitSale () {
  const { ctx } = useDashboardBsaleContext() as DashboardBsaleContextType
  const salesAverage = average(ctx.unitSales, 'total_2024')
  const [hide, setHide] = useState<any>([])

  const order = (sales: any) => {
    if (sales.some((ele: any) => ele.unidades_vendidas_2024 === 0)) {
      const year2023:any = sales.filter((ele: any) => ele.unidades_vendidas_2024 === 0)
      const year2024:any = sales.filter((ele: any) => ele.unidades_vendidas_2024 !== 0)
      const newData = [...year2023, ...year2024].map((ele: any) => {
        return {
          ...ele,
          month: month(ele.month),
          unidades_vendidas_2024: ele.unidades_vendidas_2024 === 0 ? null : ele.unidades_vendidas_2024,
          unidades_devueltas_2024: ele.unidades_devueltas_2024 === 0 ? null : ele.unidades_devueltas_2024
        }
      })

      return newData
    } else {
      const newData = sales.map((ele: any) => {
        return {
          ...ele,
          month: month(ele.month)
        }
      })
      return newData
    }
  }

  const years = ctx.unitSales.map((ele: any) => {
    const asd = Object.keys(ele).filter(ele => ele.includes('vendidas'))
    return asd
  }).pop()?.flatMap((ele: any) => ele.split('_').slice(-1)).sort((a: any, b: any) => a - b)
  // console.log(years)

  const colors: any = {
    2022: '#0473c8',
    2023: '#ff7300',
    2024: '#413ea0'

  }

  const maxUnits = Math.max(...ctx?.unitSales.flatMap(ele => Object.values(ele)))

  const handleLenged = (e: any) => {
    if (hide.includes(e.id)) {
      const data = hide.filter((ele: any) => ele !== e.id)
      setHide(data)
    } else {
      setHide([...hide, e.id])
    }
  }

  return (
    <Card sx={{ height: 400, marginTop: 3 }}>
      <CardHeader title={<Typography variant='h6'>Unidades vendidas</Typography>} />
      <ResponsiveContainer width='100%' height='80%'>
        <ComposedChart
          width={500}
          height={400}
          data={ctx && order(ctx?.unitSales)}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 50
          }}
        >
          <CartesianGrid stroke='#f5f5f5' />
          <XAxis dataKey='month' />
          <YAxis
            domain={!years?.every(year => hide.includes(`total_${year}`)) ? [0, maxUnits] : undefined}
          />
          <Tooltip />
          <Legend
            wrapperStyle={{ cursor: 'pointer' }}
            onClick={handleLenged}
            payload={years?.flatMap(year => ([
              { value: `Total ${year}`, type: 'circle', id: `total_${year}`, color: hide.includes(`total_${year}`) ? 'grey' : colors[year] },
              { value: `Devolución ${year}`, type: 'line', id: `unidades_devueltas_${year}`, color: hide.includes(`unidades_devueltas_${year}`) ? 'grey' : colors[year] }
            ]))}
          />
          {salesAverage !== null && (
            <ReferenceLine y={salesAverage} stroke='#cccccc' strokeDasharray='3 3'>
              <Label value={`Avg: ${Math.floor(salesAverage)}`} position='insideLeft' />
            </ReferenceLine>
          )}
          {years && years.map(year => (
            <React.Fragment key={year}>
              <Bar dataKey={`unidades_vendidas_${year}`} name={`Ventas ${year}`} fill={colors[year]} barSize={-4} legendType='none' display='none' hide={hide.includes(`total_${year}`)} />
              <Line type='monotone' dataKey={`unidades_devueltas_${year}`} name={`Devoluciones ${year}`} stroke={colors[year]} hide={hide.includes(`unidades_devueltas_${year}`)} />
              <Bar dataKey={`total_${year}`} name={`Total ${year}`} fill={colors[year]} barSize={30} legendType='circle' hide={hide.includes(`total_${year}`)}>
                <LabelList dataKey={`total_${year}`} position='top' formatter={(value: any) => value} fontSize={12} />
              </Bar>
            </React.Fragment>
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </Card>
  )
}

export default UnitSale
