import { Card, CardHeader, Typography, Grid } from '@mui/material'
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  ReferenceLine,
  Label,
  LabelList
} from 'recharts'
import moment from 'moment'
import { CurrencyFormat } from '@utils/Currency.utility'
import { average, formatNumber, month } from '@utils/dashboardBsale'
import { DashboardBsaleContextType, salesLastDays, salesQuarter, unitLastDays, unitQuarter, useDashboardBsaleContext } from '@pages/DashboardBsale/context/dashboardBsale.context'

function AverageQuarter () {
  const { ctx } = useDashboardBsaleContext() as DashboardBsaleContextType
  const currentYear = moment(new Date()).format('YYYY')

  function avgSales (sales: salesQuarter[], unit: unitQuarter[]): any {
    const result = sales.map(sale => {
      const find = unit.find(unit => unit.mes === sale.fecha)
      const total_neto_2024 = Number(sale.total_neto_2024)
      const total_current = Number(find?.total_current)
      const total_neto_2023 = Number(sale.total_neto_2023)
      const total_last_year = Number(find?.total_last_year)
      return ({
        month: month(find?.mes),
        avgCurrent: total_neto_2024 && total_current ? total_neto_2024 / total_current : 0,
        avgLastYear: total_neto_2023 && total_last_year? (total_neto_2023 / total_last_year) : 0
      })
    })
    return result
  }

  function avgUnits (sales: salesLastDays[], unit: unitLastDays[]): any {
    const result = sales.map(sale => {
      const find = unit.find(unit => unit.fecha === sale.fecha_current)
      const total_neto_2024 = Number(sale.total_neto_2024)
      const total_current = Number(find?.total_current)
      const total_neto_2023 = Number(sale.total_neto_2023)
      const total_last_year = Number(find?.total_last_year)
      return ({
        date: sale.fecha_current.split('T')[0].split('-')[2],
        avgCurrent: total_neto_2024 && total_current ? total_neto_2024 / total_current : 0,
        avgLastYear: total_neto_2023 && total_last_year ? total_neto_2023 / total_last_year : 0
      })
    })
    return result
  }

  const saleData = avgSales(ctx.salesQuarter, ctx.unitQuarter)

  const salesAverage = average(saleData, 'avgCurrent')
  const unitsData = avgUnits(ctx.salesLastDays, ctx.unitLastDays)
  const unitsAverage = average(unitsData, 'avgCurrent')
  console.log("unitsData => ", unitsData);
  

  const maxSales = Math.max(
    ...saleData.map((sale: any) => Math.max(parseFloat(sale.avgLastYear), parseFloat(sale.avgCurrent)))
  )

  const maxUnits = Math.max(
    ...unitsData.map((unit: any) => Math.max(parseFloat(unit.avgLastYear), parseFloat(unit.avgCurrent)))
  )

  console.log("maxUnits => ", maxUnits);
  

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item md={12} lg={6}>
        <Card sx={{ height: 400, marginTop: 3 }}>
          <CardHeader title={<Typography variant='h6'>Ticket promedio - Trimestre actual (últimos 3 meses)</Typography>} />
          <ResponsiveContainer width='100%' height='80%'>
            <ComposedChart
              width={500}
              height={300}
              data={saleData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid stroke='#f5f5f5' />
              <XAxis dataKey='month' />
              <YAxis
                domain={[0, maxSales + (maxSales * 0.1)]}
                tickFormatter={formatNumber}
              />
              <Tooltip
                formatter={(value: any, name: any, props: any) => CurrencyFormat(value)}
              />
              <Legend />
              {salesAverage !== null && (
                <ReferenceLine y={salesAverage} stroke='#cccccc' strokeDasharray='3 3'>
                  <Label value={`Avg: ${formatNumber(salesAverage)}`} position='insideLeft' />
                </ReferenceLine>
              )}
              <Bar dataKey='avgLastYear' name={`Promedio ${Number(currentYear) - 1}`} fill='#ff7300' barSize={30} legendType='circle'>
                <LabelList dataKey='avgLastYear' position='top' formatter={(value: any) => formatNumber(value)} fontSize={12} />
              </Bar>
              <Bar dataKey='avgCurrent' name={`Promedio ${currentYear}`} fill='#413ea0' barSize={30} legendType='circle'>
                <LabelList dataKey='avgCurrent' position='top' formatter={(value: any) => formatNumber(value)} fontSize={12} />
              </Bar>
            </ComposedChart>
          </ResponsiveContainer>
        </Card>
      </Grid>
      <Grid item md={12} lg={6}>
        <Card sx={{ height: 400, marginTop: 3 }}>
          <CardHeader title={<Typography variant='h6'>Promedio de venta x unidad - Últimos 14 dias</Typography>} />
          <ResponsiveContainer width='100%' height='80%'>
            <ComposedChart
              width={500}
              height={300}
              data={unitsData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid stroke='#f5f5f5' />
              <XAxis dataKey='date' />
              <YAxis
                domain={[0, maxUnits]}
                tickFormatter={formatNumber}
              />
              <Tooltip
                formatter={(value: any, name: any, props: any) => CurrencyFormat(value)}
              />
              <Legend />
              {unitsAverage !== null && (
                <ReferenceLine y={unitsAverage} stroke='#cccccc' strokeDasharray='3 3'>
                  <Label value={`Avg: ${formatNumber(unitsAverage)}`} position='left' fontSize={14} />
                </ReferenceLine>
              )}
              <Bar dataKey='avgLastYear' name={`Promedio ${Number(currentYear) - 1}`} fill='#ff7300' barSize={10} legendType='circle' />
              <Bar dataKey='avgCurrent' name={`Promedio ${currentYear}`} fill='#413ea0' barSize={10} legendType='circle' />
            </ComposedChart>
          </ResponsiveContainer>
        </Card>
      </Grid>
    </Grid>
  )
}

export default AverageQuarter
