import {
  Box, TableBody,
  TableCell, TableRow,
  TableSortLabel
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import PropTypes from 'prop-types'
import { TextAlign } from '../TableComissionFilter/EnhancedTableHead'

const headCellsExcel = [
  {
    id: 'id_order_platform',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Id Order',
    width: '60px'
  },
  {
    id: 'tipo',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Tipo',
    width: '50px'
  },
  {
    id: 'saleDate',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Fecha',
    width: '50px'
  },
  {
    id: 'discount',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Descuento',
    width: '50px'
  },
  {
    id: 'discount_code',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Código descuento',
    width: '50px'
  },
  {
    id: 'total_amount',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Total Bruto',
    width: '50px'
  },
  {
    id: 'additional_charge',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Cargo adicional',
    width: '50px'
  },
  {
    id: 'totalNeto',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Total Neto',
    width: '50px'
  },
  {
    id: 'commisio',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Comisión',
    width: '50px'
  },
  {
    id: 'commisionNeta',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Comisión Neta',
    width: '30px'
  },
  {
    id: 'invoiceNeto',
    align: 'right' as TextAlign,
    disablePadding: false,
    label: 'A Facturar Neto',
    width: '50px'
  },
  {
    id: 'invoiceBruta',
    align: 'right' as TextAlign,
    disablePadding: false,
    label: 'A Facturar Bruto',
    width: '50px'
  },
  {
    id: 'descriptiosProducts',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Nombre Producto',
    width: '100px'
  },
  {
    id: 'sku',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'SKU',
    width: '100px'
  },
  {
    id: 'client_name',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Nombre Cliente',
    width: '100px'
  },
  {
    id: 'client_email',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Email Cliente',
    width: '100px'
  },
  {
    id: 'refund_note',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Nota devolución',
    width: '100px'
  }
]

const EnhancedTableHeadExcel = (props : any) => {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    onRequestSort
  } = props
  const createSortHandler = (property : any) => (event: any) => {
    onRequestSort(event, property)
  }

  return (
    <TableBody>
      <TableRow
        style={{
          padding: 1,
          textAlign: 'center'
        }}
      >
        {headCellsExcel.map((headCell) => (
          <TableCell
            style={{ backgroundColor: '#202123', color: '#fff' }}
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            width={headCell.width}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id
                ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                  )
                : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableBody>
  )
}

EnhancedTableHeadExcel.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  /*  onSelectAllClick: PropTypes.func.isRequired, */
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

export default EnhancedTableHeadExcel
