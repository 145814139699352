import { Header } from '@components/Header'
import { Box, Toolbar } from '@mui/material'
import { Outlet } from 'react-router-dom'

const Layout = () => {
  return (
    <Box sx={{ bgcolor: '#f7f7ff', p: 2 }}>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Header />{/* <Drawer open={open} handleDrawerToggle={handleDrawerToggle} /> */}
        <Box component='main' sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
}

export default Layout
