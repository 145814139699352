import { translations, withAuthenticator } from '@aws-amplify/ui-react'
import { Amplify } from 'aws-amplify'
import { I18n } from 'aws-amplify/utils'
import './theme/App.scss'

import '@aws-amplify/ui-react/styles.css'
import { BrowserRouter, Navigate, Route } from 'react-router-dom'
import awsExports from './aws-exports'
import { useEffect } from 'react'
import { getProfile } from './store/states/user'
import { useAppDispatch, useAppSelector } from './store/store'
import { PrivateRoutes } from '@constants/routes.constants'
import { RoutesWithNotFound } from '@components/RoutesWithNotFound'
import { Layout } from '@components/Layout'
import PermissionsGuard from './guards/permision.guard'
import { MENU_DASHBOARD, MENU_DASHBOARDBSALE, MENU_PREVENTA, MENU_SEGUROS, MENU_TRADEIN } from '@constants/permission.constants'
import { DashboardBsale } from '@pages/DashboardBsale'
import { DashboardBsaleProvider } from '@pages/DashboardBsale/context/dashboardBsale.context'
import { DashboardPage } from '@pages/Dashboard'
import { TradeinTraffic } from '@pages/TradeinTraffic'
import { TradeinSales } from '@pages/TradeinSales'
import { PreventaPage } from '@pages/Preventa'
import { GoalsBsale } from '@pages/GoalsBsale'
import { SalesBsale } from '@pages/SalesBsale'
import PoliciesPage from '@pages/Insurance/InsurancePage'
// import { ConversionIndicators } from '@pages/ConversionIndicators'

Amplify.configure(awsExports)
I18n.putVocabularies(translations)
I18n.setLanguage('es')

function App ({ user } : any) {
  const dispatch = useAppDispatch()
  const userProfile = useAppSelector((state) => state.user)
  
  useEffect(() => {
    dispatch(getProfile(user.signInDetails.loginId, String(process.env.REACT_APP_COGNITO_POOL_ID)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (userProfile.id === 0) return <>Cargando...</>

  return (
    <BrowserRouter>
      <RoutesWithNotFound>
        <Route path='/' element={<Navigate to={PrivateRoutes.DASHBOARD} />} />
        <Route element={<Layout />}>
          <Route element={<PermissionsGuard permissions={userProfile.permisos.some((el:any) => el.function === MENU_DASHBOARD)} PublicValidationFragment={<Navigate replace to={PrivateRoutes.DASHBOARD} />} />}>
            <Route path={PrivateRoutes.DASHBOARD} element={<DashboardPage />} />
            <Route path={PrivateRoutes.PREVENTA} element={<PreventaPage />} />
          </Route>
          <Route element={<PermissionsGuard permissions={userProfile.permisos.some((el:any) => el.function === MENU_PREVENTA)} PublicValidationFragment={<Navigate replace to={PrivateRoutes.DASHBOARD} />} />}>
            <Route path={PrivateRoutes.PREVENTA} element={<PreventaPage />} />
          </Route>
          <Route element={<PermissionsGuard permissions={userProfile.permisos.some((el:any) => el.function === MENU_SEGUROS)} PublicValidationFragment={<Navigate replace to={PrivateRoutes.DASHBOARD} />} />}>
            <Route path={PrivateRoutes.INSURANCE} element={<PoliciesPage />} />
          </Route>
          
          {/* <Route element={<PermissionsGuard permissions={userProfile.permisos.some((el:any) => el.function === MENU_INDICADORES)} PublicValidationFragment={<Navigate replace to={PrivateRoutes.TRADEIN_SALES} />} />}>
            <Route path={PrivateRoutes.CONVERSION_INDICATORS} element={<ConversionIndicators />} />
          </Route> */}
          <Route element={<PermissionsGuard permissions={userProfile.permisos.some((el:any) => el.function === MENU_TRADEIN)} PublicValidationFragment={<Navigate replace to={PrivateRoutes.PHONECHECK} />} />}>
            <Route path={PrivateRoutes.TRADEIN_SALES} element={<TradeinSales />} />
            <Route path={PrivateRoutes.TRADEIN_TRAFFIC} element={<TradeinTraffic />} />
          </Route>
          <Route element={<PermissionsGuard permissions={userProfile.permisos.some((el:any) => el.function === MENU_DASHBOARDBSALE)} PublicValidationFragment={<Navigate replace to={PrivateRoutes.DASHBOARD} />} />}>
            <Route path={PrivateRoutes.DASHBOARDBSALE} element={<DashboardBsaleProvider><DashboardBsale /> </DashboardBsaleProvider>} />
            <Route path={PrivateRoutes.GOALSBSALE} element={<GoalsBsale />} />
            <Route path={PrivateRoutes.SALESBSALE} element={<SalesBsale />} />
          </Route>
        </Route>
      </RoutesWithNotFound>
    </BrowserRouter>
  )
}

export default withAuthenticator(App, {
  hideSignUp: true
})
