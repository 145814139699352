import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { Provider as ReduxProvider } from 'react-redux'
import { store } from './store'
import { ThemeProvider as AmplifyProvider } from '@aws-amplify/ui-react'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { es } from 'date-fns/locale/es';
import { createTheme, ThemeProvider } from '@mui/material'
import { SnackbarProvider } from 'notistack'

const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <AmplifyProvider>
    <ReduxProvider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={5} autoHideDuration={4000}>
            <App />
          </SnackbarProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </ReduxProvider>
  </AmplifyProvider>
)
