import { Box, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'
import PropTypes from 'prop-types'
import { visuallyHidden } from '@mui/utils'

export type TextAlign = 'left' | 'center' | 'inherit' | 'right' | 'justify' | undefined

export const headCellsNoSeller = [
  {
    id: 'seller',
    align: 'left' as TextAlign,
    disablePadding: false,
    label: 'Seller',
    width: '1500px'
  },
  {
    id: 'fixedCost',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Costo Fijo',
    width: '150px'
  },
  {
    id: 'amountSale',
    align: 'center' as TextAlign,
    disablePadding: true,
    label: 'Monto Venta Bruta',
    width: '80px'
  },
  {
    id: 'amountAdditionalCharge',
    align: 'center' as TextAlign,
    disablePadding: true,
    label: 'Monto cargo adicional',
    width: '80px'
  },
  {
    id: 'amountSaleLessAdditionalCharge',
    align: 'center' as TextAlign,
    disablePadding: true,
    label: 'Monto Venta Bruta menos Cargo adicional',
    width: '80px'
  },
  {
    id: 'amountSaleNeto',
    align: 'center' as TextAlign,
    disablePadding: true,
    label: 'Monto Venta Neta',
    width: '80px'
  },
  {
    id: 'commision',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Comisión por Venta Neta',
    width: '50px'
  },
  {
    id: 'commisionStorage',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Comisión por Bodegaje Neta ',
    width: '50px'
  },
  {
    id: 'totalCommision',
    align: 'center' as TextAlign,
    disablePadding: false,
    label: 'Suma Total Comisión',
    width: '50px'
  }
]

const EnhancedTableHeadNoSeller = (props : any) => {
  const {
    order,
    orderBy,
    onRequestSort
  } = props
  const createSortHandler = (property : any) => (event : any) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow
        style={{
          background: 'linear-gradient(to left top, #4676b9, #7c3c91)',
          color: '#ffff',
          padding: 3,
          textAlign: 'center'
        }}
      >
        {headCellsNoSeller.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ color: '#fff', border: 1 }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id
                ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                  )
                : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHeadNoSeller.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

export default EnhancedTableHeadNoSeller
