import { DashboardBsaleContextType, useDashboardBsaleContext } from './context/dashboardBsale.context'
import { Alert, Card, CardContent, CircularProgress, Grid } from '@mui/material'
import Cards from './components/Cards'
import Filter from './components/Filter'
import TotalSaleCharts from './components/TotalSaleCharts/TotalSaleCharts'
import ReturnsCharts from './components/ReturnsCharts/ReturnsCharts'
import SalesPerWeekCharts from './components/SalesPerWeekCharts/SalesPerWeekCharts'
import ChannelCharts from './components/ChannelCharts/ChannelCharts'
import QuarterCharts from './components/QuarterCharts/QuarterCharts'
import PieCharts from './components/PieCharts/PieCharts'

const DashboardBsale = () => {
  const { ctx } = useDashboardBsaleContext() as DashboardBsaleContextType
  console.log('ctx => ', ctx)

  return (
    <Grid container rowSpacing={2.5} columnSpacing={2.75}>

      {ctx.loading
        ? (
          <Grid item xs={12}>
            <Card>
              <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </CardContent>
            </Card>

          </Grid>
          )
        : (
          <>

            <Grid item xs={12} style={{ position: 'sticky', top: 45, zIndex: 10 }}>
              <Filter />
            </Grid>

            {ctx.cards.total_neto
              ? (
                <>
                  <Grid item xs={12}>
                    <Cards />
                  </Grid>

                  <Grid item xs={12}>
                    <TotalSaleCharts />
                  </Grid>

                  <Grid item xs={12}>
                    <ReturnsCharts />
                  </Grid>

                  <Grid item xs={12}>
                    <ChannelCharts />
                  </Grid>

                  <Grid item xs={12}>
                    <QuarterCharts />
                  </Grid>

                  <Grid item xs={12}>
                    <SalesPerWeekCharts />
                  </Grid>

                  <Grid item xs={12}>
                    <PieCharts />
                  </Grid>

                </>
                )
              : (

                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Alert severity='info'>No hay información para mostrar.</Alert>
                    </CardContent>
                  </Card>
                </Grid>
                )}

          </>)}
    </Grid>
  )
}

export default DashboardBsale
