import { Subject } from 'rxjs'

class SubjectManager {
  subject$ = new Subject()

  getSubject () {
    return this.subject$.asObservable()
  }

  setSubject (value: any) {
    this.subject$.next(value)
  }
}

export const sharingInformationService = new SubjectManager()
