import {
  Box, TableCell, TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material'
import PropTypes from 'prop-types'
import { headCells } from './EnhancedTableHead'
import { visuallyHidden } from '@mui/utils'
import moment from 'moment'
import { DBOARD_FORMATSMALL } from '../../../../constants/dashboard.constants'
const EnhancedTableHeadExcel = (props : any) => {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    onRequestSort,
    data
  } = props
  const createSortHandler = (property : any) => (event: any) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={{
            padding: 10,
            textAlign: 'left'
          }}
        >
          Fecha
        </TableCell>
        <TableCell colSpan={6}>
          {moment(data.dateStart).format(DBOARD_FORMATSMALL)} -  {moment(data.dateEnd).format(DBOARD_FORMATSMALL)}
        </TableCell>
      </TableRow>
      <TableRow
        style={{
          padding: 3,
          textAlign: 'center'
        }}
      >
        {headCells.map((headCell) => (
          <TableCell
            style={{ backgroundColor: '#202123', color: '#fff' }}
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            width={headCell.width}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id
                ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                  )
                : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHeadExcel.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  /*  onSelectAllClick: PropTypes.func.isRequired, */
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired
}

export default EnhancedTableHeadExcel
